import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Themes } from '@desquare/enums';
import { Maybe } from '@designage/gql';
import { localStorageKeys } from '@desquare/constants';
import { themeFactory } from '@desquare/factories';
import { environment } from '@desquare/environments';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: 'root',
})
export class StyleService {
  private subs = new SubSink();

  currentTheme: Maybe<Themes>;

  constructor(private localStorageService: LocalStorageService) {
    this.initVariables();
    this.initSubscriptions();
  }

  initVariables() {
    this.currentTheme =
      this.localStorageService.retrieve(localStorageKeys.THEME) ?? Themes.DARK;
    this.setTheme(this.currentTheme);
  }

  initSubscriptions() {
    this.subs.sink = this.localStorageService
      .observe(localStorageKeys.THEME)
      .subscribe((theme: Maybe<Themes>) => {
        this.currentTheme = theme;
        this.setTheme(this.currentTheme);
      });
  }

  /**
   * Set the stylesheet with the specified key.
   */
  setStyle(key: string, href: string) {
    this.getLinkElementForKey(key).setAttribute('href', href);
  }

  /**
   * Remove the stylesheet with the specified key.
   */
  removeStyle(key: string) {
    const existingLinkElement = this.getExistingLinkElementByKey(key);
    if (existingLinkElement) {
      document.head.removeChild(existingLinkElement);
    }
  }

  getLinkElementForKey(key: string) {
    return (
      this.getExistingLinkElementByKey(key) ||
      this.createLinkElementWithKey(key)
    );
  }

  getExistingLinkElementByKey(key: string) {
    return document.head.querySelector(
      `link[rel="stylesheet"].${this.getClassNameForKey(key)}`
    );
  }

  createLinkElementWithKey(key: string) {
    const linkEl = document.createElement('link');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.classList.add(this.getClassNameForKey(key));
    document.head.appendChild(linkEl);
    return linkEl;
  }

  getClassNameForKey(key: string) {
    return `app-${key}`;
  }

  setTheme(themeToSet: Maybe<Themes>) {
    const selectedTheme =
      themeFactory(themeToSet)?.path ?? environment.assets.darkTheme;
    this.setStyle('theme', selectedTheme);
    this.localStorageService.store(localStorageKeys.THEME, themeToSet);
  }

  setBodyClassTheme(themeId: string) {
    /*
    this.renderer.removeClass(document.body, 'theme-'+this.currentTheme);
    this.currentTheme = type;
    this.renderer.addClass(document.body, 'theme-'+this.currentTheme);
    this.document.getElementById('theme').href = '/assets/css/theme_'+type+'_bootstrap.min.css';
    */
    document.body.className = themeId;
  }
}
