import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IUpdateFolderForm } from '@desquare/interfaces';

@Component({
  selector: 'update-folder-dialog',
  templateUrl: './update-folder-dialog.component.html',
  styleUrls: ['./update-folder-dialog.component.scss'],
})
export class UpdateFolderDialogComponent implements OnInit {
  // current folder states
  @Input() currentName!: string;
  @Input() currentIsPublic!: string;

  // @Input() parentFolderId?: string

  get updateFolderInput(): IUpdateFolderForm {
    return this.folderForm.value;
  }

  folderForm!: FormGroup;
  get isFormValid() {
    return this.folderForm.valid;
  }
  get isFormPristine() {
    return this.folderForm.pristine;
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  // TODO: logic to check if nothing was updated (currentName === submittedName)
  // then nothing should happen (close dialog)

  initForm() {
    this.folderForm = this.formBuilder.group({
      name: [this.currentName, Validators.required], // cannot be an empty string ('') or the same name
      isPublic: this.currentIsPublic,
      // parentFolderId: this.parentFolderId // moving folder into folder will be done through drag and drop
    });
  }
}
