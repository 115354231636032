import { ITheme } from '@desquare/interfaces';
import { Themes } from '@desquare/enums';
import { environment } from '@desquare/environments';
import { Maybe } from '@designage/gql';

const themes: ITheme[] = [
  { name: Themes.DARK, path: environment.assets.darkTheme },
  { name: Themes.LIGHT, path: environment.assets.lightTheme },
];

export const themeFactory = (theme: Maybe<Themes>) => {
  return themes.find((x) => x.name === theme);
};
