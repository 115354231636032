import { Component, Input } from '@angular/core';
import { SORT_OPTIONS_VALUES } from '@desquare/enums';
import { Maybe } from '@designage/gql';
import { DeviceData } from '@desquare/types';
import { IDeviceFilters } from '@desquare/interfaces';
import * as _ from 'lodash';
import { ResponsiveUiService } from '@desquare/services';

@Component({
  selector: 'app-wt-device-grid',
  templateUrl: './device-grid.component.html',
  styleUrls: ['./device-grid.component.scss'],
})
export class DeviceGridComponent {
  @Input() devices!: DeviceData[];
  @Input() loading!: boolean;
  @Input() channelId?: Maybe<string>;
  @Input() zoom!: string;

  loaderMessage = 'Loading devices...';
  onErrorMessage!: string;

  isOneDevicePerChannelAccount = true;
  sortOptions = SORT_OPTIONS_VALUES;
  statusFilter!: IDeviceFilters;

  skeletonCards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  constructor(public responsiveUiService: ResponsiveUiService) {}
  trackByDeviceId(index: number, device: DeviceData) {
    return device.id;
  }
}
