import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { SharedModule } from '@watchtower/app/shared/shared.module';
import { ThemePickerComponent } from './themepicker/themepicker.component';
import { ProfileModule } from '../profile/profile.module';
import { DesquareCommonModule } from '@desquare/components/common/src/desquare-common.module';

@NgModule({
  declarations: [MainComponent, TopbarComponent, ThemePickerComponent],
  imports: [
    DesquareCommonModule,
    CommonModule,
    MainRoutingModule,
    SharedModule,
    ProfileModule,
  ],
  exports: [MainComponent],
  bootstrap: [MainComponent],
})
export class MainModule {}
