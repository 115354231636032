import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(duration: number | string = 0): string {
    let seconds;
    let minute;
    let hour;
    if (typeof duration === 'number') {
      if (duration === 0) {
        return `${duration}`;
      }
      seconds = Math.floor(duration / 1000);
      minute = Math.floor(seconds / 60);
      hour = Math.floor(minute / 60);
      seconds = seconds % 60;
      minute = minute % 60;
      hour = hour % 24;
      duration = duration % 1000;
    } else {
      const timeElements = duration.split(':');
      seconds = parseInt(timeElements[2], 10);
      minute = parseInt(timeElements[1], 10);
      hour = parseInt(timeElements[0], 10);
      seconds = seconds % 60;
      minute = minute % 60;
      hour = hour % 24;
    }

    return `${hour ? hour + 'h' : ''} ${minute ? minute + 'm' : ''} ${
      seconds ? seconds + 's' : ''
    }`;
  }
}
