import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'ngx-webstorage';
import { IAppState } from '@desquare/store/states';
import { fromApp } from '@desquare/store/selectors';
import { Maybe } from '@designage/gql';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  constructor(
    private localStorageService: LocalStorageService,
    private store: Store<IAppState>
  ) {}
}
