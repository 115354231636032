<div class="modal-container">
  <div class="modal-header p-2">
    <h3 class="d-inline">{{ headerText | translate }}</h3>
    <button class="btn btn-outline-secondary float-end" (click)="close()">
      X
    </button>
  </div>
  <div class="modal-body">
    <div #bodyWrapper>
      <ng-content></ng-content>
    </div>
  </div>
</div>
