<div class="m-3 d-flex align-items-center">
  <ng-container *ngIf="device">
    <span
      class="status-indicator"
      [style.background-color]="device?.status?.Color"
      ngbTooltip="{{ device.status?.StatusLabel | translate }}"
    ></span>
    <div class="ms-2">{{ device.name }}</div>
    <div class="ms-2 p-1 pe-2 btn btn-sm" (click)="deviceDetailsToggle(device)">
      <i
        [ngClass]="
          isSelected
            ? 'ri-information-fill border border-white rounded-circle'
            : 'ri-information-line'
        "
      ></i>
    </div>
  </ng-container>
</div>
<button class="btn btn-outline-primary mx-3" (click)="resetPosition()">
  Reset position
</button>
