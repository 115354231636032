<div
  class="d-flex justify-content-center"
  #loading
  *ngIf="!device; else detailedCard"
>
  <span class="visually-hidden">Loading...</span>
</div>
<ng-template #detailedCard>
  <div
    class="bg-online d-flex align-items-center mx-1 p-1 rounded-2 position-relative"
    [class.bg-not-installed]="isNotInstalled"
    [ngClass]="getStatusColor"
  >
    <h5
      class="text-truncate w-100 card-title m-0"
      [title]="device?.name || ('NO_DATA' | translate)"
    >
      {{ device?.name || 'NO_DATA' | translate | uppercase }}
    </h5>
    <button
      type="button"
      aria-label="Close"
      class="float-end btn-close btn-close-white position-absolute end-0"
      (click)="removeCard()"
    ></button>
  </div>
  <div class="card shadowed-box overflow-auto">
    <div class="px-1 py-2">
      <img
        class="card-img-top rounded-2"
        [src]="screenshotUri"
        alt="{{ 'RELOAD_SCREENSHOT' | translate }}"
        (error)="screenshotError()"
      />
    </div>
    <div class="card-body wt-card">
      <div class="position-relative">
        <img
          *ngIf="platformLogo"
          class="platform-logo"
          [src]="platformLogo"
          ngbTooltip="{{ device?.deviceInfo.applicationType | titlecase }}"
          alt="{{ device?.deviceInfo.applicationType | titlecase }}"
        />
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">{{ 'CONTROL_DEVICE' | translate }}</th>
            </tr>
          </thead>
          <tbody class="mt-2 me-4 d-flex justify-content-evenly">
            <tr
              *ngIf="isOffline; else statusOnlineBtn"
              class="h6"
              ngbTooltip="{{ 'CONTROLS_UNAVAIL_TOOLTIP' | translate }}"
            >
              {{
                'CONTROLS_UNAVAIL' | translate | uppercase
              }}
            </tr>
            <ng-template #statusOnlineBtn>
              <div
                class="d-flex justify-content-evenly w-100"
                style="max-width: 20rem"
              >
                <div
                  class="fs-4 pointer"
                  ngbTooltip="{{ 'RELOAD_SCREENSHOT' | translate }}"
                  (click)="updateScreenshot()"
                >
                  <i
                    [ngClass]="
                      loader
                        ? 'spinner-border spinner-border-sm mx-2'
                        : 'ri-camera-switch-fill'
                    "
                  ></i>
                </div>
                <div
                  class="fs-4 pointer"
                  [ngbPopover]="popDeviceCommands"
                  animated="true"
                  container="body"
                  [autoClose]="'outer'"
                  placement="bottom"
                >
                  <i class="ri-settings-3-fill"></i>
                </div>
                <div
                  *ngIf="showConnectedLinks()"
                  class="fs-5 pointer"
                  (shown)="getConnectedLinks()"
                  [ngbTooltip]="'CONNECTED_CHANNEL' | translate"
                  [ngbPopover]="popConnectedChannel"
                  animated="true"
                  container="body"
                  [autoClose]="'outer'"
                  placement="end bottom auto"
                >
                  <i class="ri-tv-line"></i>
                </div>
                <div
                  *ngIf="showConnectedLinks()"
                  class="fs-5 pointer"
                  (shown)="getConnectedLinks()"
                  [ngbTooltip]="'CONNECTED_PLAYLISTS' | translate"
                  [ngbPopover]="popConnectedPlaylists"
                  animated="true"
                  container="body"
                  [autoClose]="'outer'"
                  placement="end bottom auto"
                >
                  <i class="ri-play-list-2-fill"></i>
                </div>
              </div>
            </ng-template>

            <ng-template #popDeviceCommands>
              <app-device-commands
                [device]="device"
                layout="expanded"
                [options]="deviceCommandsOptions()"
                [channelId]="device?.channelId"
                [screenStatus]="device?.status"
              ></app-device-commands>
            </ng-template>

            <ng-template #popConnectedChannel>
              <app-device-connected-links
                [links]="connectedLinks()"
                [connection]="'channel'"
              ></app-device-connected-links>
            </ng-template>
            <ng-template #popConnectedPlaylists>
              <app-device-connected-links
                [connection]="'playlists'"
                [links]="connectedLinks()"
              ></app-device-connected-links>
            </ng-template>
          </tbody>
        </table>
        <device-details-table
          [device]="device"
          [timestamp]="timestamp"
        ></device-details-table>
      </div>
    </div>
  </div>
</ng-template>
