import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent implements OnInit {
  @ViewChild('bodyWrapper', {
    static: true,
  })
  bodyWrapper?: ElementRef;

  @Input() headerText = 'FORM_DIALOG_HEADER';
  @Input() dismissOverride?: () => void;

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}

  dismiss() {
    this.modal.dismiss();
  }
}
