import { AfterViewInit, Component, Input } from '@angular/core';
import { Media, Maybe, MediaForMediaListFragment } from '@designage/gql';

@Component({
  selector: 'app-delete-media-dialog',
  templateUrl: './delete-media-dialog.component.html',
  styleUrls: ['./delete-media-dialog.component.scss'],
})
export class DeleteMediaDialogComponent {
  media?: Maybe<Media>;
  isDeleteAllSelected?: boolean;
  selectedMList?: MediaForMediaListFragment[];
  isPermanentDelete: boolean = true;
}
