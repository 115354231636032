import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DeviceStatusInfo } from '@desquare/models';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import _ from 'lodash';

@Component({
  selector: 'table-status-indicator',
  templateUrl: './table-status-indicator.component.html',
  styleUrls: ['./table-status-indicator.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbTooltipModule, TranslateModule],
})
export class TableStatusIndicatorComponent {
  @Input() status: Maybe<DeviceStatusInfo>;
}
