import { Component, Input, OnInit } from '@angular/core';
import { Device, UpdateDeviceGQL } from '@designage/gql';
import { ScreenStatus } from '@desquare/enums';
import {
  ChannelService,
  DeviceDataService,
  ToasterService,
} from '@desquare/services';
import { lastValueFrom } from 'rxjs';

interface IDeviceCommandItem extends IButtonVisibility {
  label: string;
  iconClassName: string;
  callback: () => any;
}

interface IButtonVisibility {
  show?: boolean;
  disable?: boolean;
}

interface IDeviceCommandsControls {
  reboot?: IButtonVisibility;
  reloadApplet?: IButtonVisibility;
  updateApplet?: IButtonVisibility;
  republishContent?: IButtonVisibility;
  turnOnScreen?: IButtonVisibility;
  turnOffScreen?: IButtonVisibility;
  clearCache?: IButtonVisibility;
}

export interface IDeviceCommandsComponentOptions {
  controls: IDeviceCommandsControls;
}

@Component({
  selector: 'app-device-commands',
  templateUrl: './device-commands.component.html',
  styleUrls: ['./device-commands.component.scss'],
})
export class DeviceCommandsComponent implements OnInit {
  deviceCommands: IDeviceCommandItem[] = [];

  @Input() layout?: 'compact' | 'expanded';

  @Input() options!: IDeviceCommandsComponentOptions;
  @Input() device?: Device;
  @Input() channelId?: string;
  @Input() screenStatus?: ScreenStatus;

  get isExpandedLayout() {
    return this.layout === 'expanded';
  }

  get isCompactLayout() {
    return this.layout === 'compact';
  }

  get toggleScreenCommand() {
    return this.createCommandItemToggleScreen();
  }

  constructor(
    private deviceDataService: DeviceDataService,
    private channelService: ChannelService,
    private updateDeviceGQL: UpdateDeviceGQL,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.initLayout();
    this.initOptions();
    this.initDeviceCommands();
  }

  initLayout() {
    if (!this.layout) this.layout = 'compact';
  }

  switchLayout() {
    this.layout = this.isExpandedLayout ? 'compact' : 'expanded';
  }

  initOptions() {
    if (!this.options) {
      this.options = {
        controls: {},
      };
    }
  }

  initDeviceCommands() {
    this.deviceCommands = [
      this.createCommandItemReloadApplet(),
      this.createCommandItemRepublishContent(),
      this.createCommandItemReboot(),
      this.createCommandItemTurnOnScreen(),
      this.createCommandItemTurnOffScreen(),
      this.createCommandItemUpdateApplet(),
      this.createCommandItemClearCache(),
      // this.createCommandItemTakeScreenshot(),
    ];
  }

  private createCommandItemReboot(): IDeviceCommandItem {
    const options = this.options;

    return {
      label: 'REBOOT',
      iconClassName: 'ri-restart-line',
      get show() {
        return options.controls.reboot?.show ?? true;
      },
      get disable() {
        return options.controls.reboot?.disable;
      },
      callback: () => {
        if (!this.device) return;

        // Reboot Device
        this.deviceDataService.rebootDevice(this.device.id);
      },
    };
  }

  private createCommandItemReloadApplet(): IDeviceCommandItem {
    const options = this.options;

    return {
      label: 'APPLET_RELOAD',
      iconClassName: 'ri-tv-2-line',
      get show() {
        return options.controls.reloadApplet?.show ?? true;
      },
      get disable() {
        return options.controls.reloadApplet?.disable;
      },
      callback: () => {
        if (!this.device) return;

        // Reload Applet
        this.deviceDataService.reloadDeviceApp(this.device.id);
      },
    };
  }

  private createCommandItemUpdateApplet(): IDeviceCommandItem {
    const options = this.options;

    return {
      label: 'APPLET_UPDATE_VERSION',
      iconClassName: 'ri-list-ordered',
      get show() {
        return options.controls.updateApplet?.show ?? true;
      },
      get disable() {
        return options.controls.updateApplet?.disable;
      },
      callback: async () => {
        if (!this.device?.id || !this.channelId) return;

        // Update Screen Applet Version ?
        // TODO: the command is located in channel-list.component
        const deviceId = this.device.id;
        const channelId = this.channelId; // TODO: console.log this later, it should not be undefined
        const name = this.device.name;
        const appletVersion =
          this.device.signageOSDeviceTiming?.activeAppletVersion;

        const result = await lastValueFrom(
          this.updateDeviceGQL.mutate(
            {
              input: {
                id: deviceId,
                name,
                channelId,
                appletVersion,
              },
            },
            {
              errorPolicy: 'ignore',
            }
          )
        );

        if (result.data?.updateDevice.isSuccessful) {
          this.toasterService.success('UPDATE_DEVICE_SUCCESSFUL');
        } else {
          this.toasterService.error('UPDATE_DEVICE_ERROR');
        }
      },
    };
  }

  private createCommandItemRepublishContent(): IDeviceCommandItem {
    const options = this.options;

    return {
      label: 'REPUBLISH_PLAYLISTS',
      iconClassName: 'ri-play-list-line',
      get show() {
        return options.controls.republishContent?.show ?? true;
      },
      get disable() {
        return options.controls.republishContent?.disable;
      },
      callback: () => {
        if (!this.device) return;

        // Republish ?Playlists || Content?
        // this.channelDataService.republishChannelContent(this.device.id);
        if (this.channelId)
          this.channelService.republishChannelContent([this.channelId]);
      },
    };
  }

  private createCommandItemToggleScreen(): IDeviceCommandItem {
    if (this.screenStatus === ScreenStatus.DISPLAY_ON) {
      return this.createCommandItemTurnOffScreen();
    } else if (this.screenStatus === ScreenStatus.DISPLAY_OFF) {
      return this.createCommandItemTurnOnScreen();
    } else {
      // If the device status isn't online or offline,
      // do not show this command item
      const dummyCommandItem = {
        show: false,
      } as IDeviceCommandItem;
      return dummyCommandItem;
    }
  }

  private createCommandItemTurnOnScreen(): IDeviceCommandItem {
    const options = this.options;

    return {
      label: 'DISPLAY_ON',
      iconClassName: 'ri-shut-down-fill',
      get show() {
        return options.controls.turnOnScreen?.show ?? false;
      },
      get disable() {
        return options.controls.turnOnScreen?.disable;
      },
      callback: () => {
        if (!this.device) return;
        // Turn On ?Device || Screen?
        this.deviceDataService.turnScreenOn(this.device.id);
      },
    };
  }

  private createCommandItemTurnOffScreen(): IDeviceCommandItem {
    const options = this.options;

    return {
      label: 'DISPLAY_OFF',
      iconClassName: 'ri-shut-down-line',
      get show() {
        return options.controls.turnOffScreen?.show ?? false;
      },
      get disable() {
        return options.controls.turnOffScreen?.disable;
      },
      callback: () => {
        if (!this.device) return;

        // Turn Off ?Device || Screen?
        this.deviceDataService.turnScreenOff(this.device.id);
      },
    };
  }

  private createCommandItemClearCache(): IDeviceCommandItem {
    const options = this.options;

    return {
      label: 'CLEAR_DEVICE_CACHE',
      iconClassName: 'ri-brush-3-line',
      get show() {
        return options.controls.clearCache?.show ?? true;
      },
      get disable() {
        return options.controls.clearCache?.disable;
      },
      callback: () => {
        if (!this.device) return;

        // Clear Device Cache
        this.deviceDataService.forceRefreshDeviceContent(this.device.id);
      },
    };
  }
}
