import { Injectable } from '@angular/core';
import { SortPropDir } from '@boring.devs/ngx-datatable';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import { IDatatableSortArgs } from '@desquare/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DatatableSettingsService {
  constructor(private localStorageService: LocalStorageService) {}

  private replacer(key: any, value: any) {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
    }
  }

  private reviver(key: any, value: any) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }

  private replaceMap(map: Map<any, any>): string {
    return JSON.stringify(map, this.replacer);
  }

  private reviveMap(map: string): Map<any, any> {
    return JSON.parse(map, this.reviver);
  }

  saveSortSettings(event: IDatatableSortArgs, key: string | undefined): void {
    if (!key) return;

    const datatableSortSettings: Map<string, SortPropDir[]> =
      this.reviveMap(
        this.localStorageService.retrieve(
          localStorageKeys.DATA_TABLES_SORT_SETTINGS
        )
      ) || new Map<string, SortPropDir[]>();

    datatableSortSettings.set(key, event.sorts);

    this.localStorageService.store(
      localStorageKeys.DATA_TABLES_SORT_SETTINGS,
      this.replaceMap(datatableSortSettings)
    );
  }

  loadSortSettings(key: string | undefined): SortPropDir[] | undefined {
    if (
      !this.localStorageService.retrieve(
        localStorageKeys.DATA_TABLES_SORT_SETTINGS
      ) ||
      !key
    )
      return;

    const datatableSortSettings: Map<string, SortPropDir[]> = this.reviveMap(
      this.localStorageService.retrieve(
        localStorageKeys.DATA_TABLES_SORT_SETTINGS
      )
    );

    if (datatableSortSettings.has(key)) {
      return datatableSortSettings.get(key);
    }
  }
}
