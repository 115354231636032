import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import {
  BulkActionDevicesGQL,
  ChannelBulkAction,
  ChannelLocationForMapFragment,
  DeviceInfo,
  GetProfileDevicesForMapGQL,
  GetSingleDeviceForMapGQL,
  DevicePowerAction,
  SetDevicePowerActionGQL,
  Maybe,
  GetDeviceMonitorHistoryGQL,
  DeleteDeviceGQL,
  DeleteDeprovisionedDeviceGQL,
} from '@designage/gql';
import {
  IChannelFilter,
  ISortOrderDirection,
  IScreenshotUrlArray,
  IDeviceLog,
} from '@desquare/interfaces';
import { DeviceStatusCode, DeviceStatusInfo } from '@desquare/models';
import {
  DeviceData,
  DeviceMonitorData,
  DeviceStatusChangeEventArg,
  DeviceStatusData,
} from '@desquare/types';
import { debounce, getScreenshotUrl } from '@desquare/utils';
import { ApolloError } from '@apollo/client/errors';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { SubSink } from 'subsink';
import { ToasterService } from '../toaster/toaster.service';
import { UiDataService } from '../ui-data/ui-data.service';
import { TopicLogic } from './mqtt.topic.logic';
import { QoS } from 'mqtt-packet/types';
import { FilterService } from '../filter/filter.service';
import { orderBy } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  concatMap,
  filter,
  lastValueFrom,
  map,
  merge,
  mergeAll,
  scan,
  switchMap,
  tap,
} from 'rxjs';
// import * as momentTz from 'moment-timezone';
import { DateTime } from 'ts-luxon';
import { getTimeZones, rawTimeZones, timeZonesNames } from '@vvo/tzdb';
import moment from 'moment';
import { SessionService } from '../session/session.service';
import { deviceInfoState, deviceStatusState } from '../session/session';
import { WritableSignal, signal } from '@angular/core';
import { audioWav } from '@cloudinary/url-gen/qualifiers/format';
import { CurrentUserService } from '../current-user/current-user.service';
import { ChannelService } from '../channel/channel.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceDataService implements OnDestroy {
  private subs = new SubSink();
  private mqttSubs = new SubSink();
  private SubShot = new SubSink();

  devices: DeviceData[] = [];
  devicesSignal = signal<DeviceData[]>([]);

  get notInstalledChannelCount() {
    return 0;
  }

  location: ChannelLocationForMapFragment | null = null;
  screenshotArray!: IScreenshotUrlArray;

  /** to be emitted when visibilityFilter changes */
  @Output() deviceDataChanged = new EventEmitter();
  /** possibly fired very often, use only in monitor components on single device */
  @Output() deviceInfoChanged = new EventEmitter<DeviceInfo>();
  /** possibly fired very often, use only in monitor components on single device */
  @Output() deviceStatusChanged =
    new EventEmitter<DeviceStatusChangeEventArg>();

  loading = signal<boolean>(true);

  constructor(
    private sessionService: SessionService,
    public currentUserService: CurrentUserService,
    public channelService: ChannelService,
    private toasterService: ToasterService,
    private bulkActionDevicesGQL: BulkActionDevicesGQL,
    private getProfileDevicesGQL: GetProfileDevicesForMapGQL,
    private getDeviceMonitorHistoryGQL: GetDeviceMonitorHistoryGQL,
    private getSingleDeviceGQL: GetSingleDeviceForMapGQL,
    private setDevicePowerActionGQL: SetDevicePowerActionGQL,
    private _mqttService: MqttService,
    private uiDataService: UiDataService,
    private removeDevice: DeleteDeviceGQL,
    private removeDeprovisionedDevice: DeleteDeprovisionedDeviceGQL,
    private filterService: FilterService
  ) {
    this.uiDataService.currentStatusFilter$.subscribe(() => {
      this.filterVisibleDevices();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.mqttSubs.unsubscribe();
    this.SubShot.unsubscribe();
  }

  /**
   * too many status change events could occur in a short time (many autonomous devices)
   * use this function to group in a single event
   */
  private fireDebouncedChangeEvent = debounce(this.doFireChangeEvent, 250, {
    isImmediate: false,
  });

  doFireChangeEvent() {
    this.filterVisibleDevices();
    this.deviceDataChanged.emit();
  }

  /**
   * reset coordinates of a single channel to its location's
   *
   * @param deviceId
   * @returns
   */
  async resetDevicePosition(deviceId: string) {
    return await this.resetDevicesPosition([deviceId]);
  }
  /**
   * reset coordinates of a channels to relative locations'
   *
   * @param channelIds
   */
  async resetDevicesPosition(channelIds: string[]) {
    this.subs.sink = this.bulkActionDevicesGQL
      .mutate({
        ids: channelIds,
        action: ChannelBulkAction.ResetPosition,
      })
      .subscribe({
        next: ({ data }) => {
          if (!(data && data.bulkActionDevices.isSuccessful)) {
            this.toasterService.error('UNKNOWN_ERROR');
          }

          const ret = data?.bulkActionDevices.isSuccessful;
          // TODO
          // WHEN server subscriptions are implemented remove this
          this.getDevices(this.lastFilter);

          return ret;
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('resetDevicePosition', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
          return false;
        },
      });
  }
  /** DANGEROUS this deletes a device from db only, make sure the device is not connected to SOS */
  async forceDeleteDevice(id: string) {
    const { data } = await lastValueFrom(
      this.removeDeprovisionedDevice.mutate({ id })
    );

    if (data?.deleteDeprovisionedDevice.isSuccessful) {
      this.refreshDevices();
      this.toasterService.success('DEVICE_DEACTIVATED');
    } else {
      this.toasterService.error('DEACTIVATE_DEVICE_ERROR');
    }
  }

  // #region deletes a device from SOS and DB, returns success, fails if device is not present in SOS
  async deprovisionDevice(id: string) {
    try {
      const { data } = await lastValueFrom(this.removeDevice.mutate({ id }));
      if (data?.deleteDevice.isSuccessful) {
        this.refreshDevices();
        this.toasterService.success('DEVICE_DEACTIVATED');
        return true;
      }
    } catch {
      // nothing
    }
    // something happened
    this.toasterService.error('DEACTIVATE_DEVICE_ERROR');
    return false;
  }

  async bulkUpdateDeviceApplet(
    deviceIds: string[],
    application: 'SMIL' | 'CLASSIC' | 'LATEST'
  ) {
    const { data } = await lastValueFrom(
      this.bulkActionDevicesGQL.mutate({
        ids: deviceIds,
        action: ChannelBulkAction.AppletUpdateVersion,
        extra: application,
      })
    );

    if (data?.bulkActionDevices.isSuccessful) {
      this.getDevices(this.lastFilter);
    }
  }

  lastFilter!: IChannelFilter;

  // #region initialize data connection and saves current filter
  initDataLinks(filter: IChannelFilter) {
    // TODO: in this if we should probably consider filter.mqttOnly /Marco
    if (
      this.lastFilter &&
      this.lastFilter.profileId === filter.profileId &&
      this.lastFilter.locationId === filter.locationId &&
      this.lastFilter.deviceId === filter.deviceId
    ) {
      return;
    }

    this.lastFilter = filter;
    if (!filter.mqttOnly) {
      this.getDevices(filter);
    }
    if (filter.profileId) {
      this.subscribeToMqttProfileMessages(filter.profileId);
      this.subscribeToMqttScreenshots(filter.profileId);
    }
  }

  // #region get device data from graphql
  private getDevices(filter: IChannelFilter) {
    if (filter.profileId && filter.deviceId) {
      this.subs.sink = this.getSingleDeviceGQL
        .watch({ id: filter.deviceId }, { fetchPolicy: 'cache-and-network' })
        .valueChanges.subscribe({
          next: ({ data, loading }) => {
            this.loading.set(loading);

            this.devices = [];
            if (data?.device) {
              const d: DeviceData = data?.device;
              d.status = DeviceStatusInfo.CtrlOffline;
              d.screenshotUrl = getScreenshotUrl(d.id) || '';
              this.devices.push(d);
              this.devicesSignal.set(this.devices);
              this.fireDebouncedChangeEvent();
            }
          },
          error: (error: ApolloError) => {
            error.graphQLErrors.forEach((err) => {
              console.error('getDevices, get Single Device', err);
              this.toasterService.error('DEVICE_UNAVAILABLE');
              this.devicesSignal.set(this.devices);
            });
          },
        });
    } else if (filter.profileId) {
      // #region get profile data from graphql
      this.subs.sink = this.getProfileDevicesGQL
        .watch({ id: filter.profileId }, { fetchPolicy: 'cache-first' })
        .valueChanges.subscribe({
          next: ({ data, loading }) => {
            this.loading.set(loading);

            if (data?.profile) {
              this.devices = JSON.parse(JSON.stringify(data?.profile?.devices));
              this.devices.forEach((d) => {
                d.status = this.getDeviceStatus(d.id);
                d.lastPing = d.deviceInfo?.currentTime?.currentDate;
                d.timezoneOffset = this.getTimezoneOffsetByName(
                  d.deviceInfo?.currentTime?.timezone
                );
                d.screenshotUrl = getScreenshotUrl(d.id) || '';
              });
              this.fireDebouncedChangeEvent();
            }
            this.devicesSignal.set(this.devices);
          },
          error: (error: ApolloError) => {
            console.error('getDevices, get All profile Devices', error);
            this.toasterService.error('DEVICE_UNAVAILABLE');
          },
        });
    }
  }

  async getDeviceMonitorData(deviceId: string) {
    const { data } = await lastValueFrom(
      this.getDeviceMonitorHistoryGQL.fetch(
        { id: deviceId },
        { fetchPolicy: 'network-only' }
      )
    );

    return {
      monitorHistory: data?.device?.monitorHistory as DeviceMonitorData[],
      statusHistory: data?.device?.statusHistory as DeviceMonitorData[],
    };
  }

  refreshDevices() {
    this.getDevices(this.lastFilter);
  }

  getTimezoneOffsetByName(tzName: Maybe<string>) {
    if (!tzName) return '';
    const timeZones = getTimeZones();
    try {
      return timeZones
        .find((x) => x.name === tzName)
        ?.currentTimeFormat.split(' ')[0];
      // return DateTime.now().setZone(tzName).toLocaleString().split('GMT')[1];
      // return momentTz.tz(tzName).toLocaleString().split('GMT')[1];
    } catch {
      return '';
    }
  }

  /**
   * status filter management
   */
  private get visibilityFilter() {
    return this.uiDataService.deviceFilter;
  }

  private get visibleStatuses() {
    const statuses: DeviceStatusCode[] = [];
    if (this.visibilityFilter.onlineChannels) {
      statuses.push(DeviceStatusCode.online);
    }
    if (this.visibilityFilter.offlineChannels) {
      statuses.push(DeviceStatusCode.offline);
    }
    if (this.visibilityFilter.noDeviceChannels) {
      statuses.push(DeviceStatusCode.ctrloffline);
    }
    return statuses;
  }
  /**
   * 2nd level (status) filtering
   * original channel list is filtered depending on user preferences
   * AFTER all channels for 1st level filter are collected
   */
  visibleDevices: DeviceData[] = [];
  visibleDevicesSignal = signal<DeviceData[]>([]);

  filterVisibleDevices() {
    const search = this.visibilityFilter.filterText?.toLocaleLowerCase() || '';

    let devices = this.devices.filter((x) => this.isDeviceVisible(x, search));

    // sort device, order by name
    if (this.visibilityFilter.sortOrderConfigMap) {
      const orderByFields: string[] = [];
      const orderByDirections: ISortOrderDirection[] = [];

      try {
        const sortEntries = this.visibilityFilter.sortOrderConfigMap.entries();

        for (let [fieldName, direction] of sortEntries) {
          orderByFields.push(fieldName);
          orderByDirections.push(direction);
        }

        devices = orderBy(devices, orderByFields, orderByDirections);
      } catch {}
    }

    this.visibleDevices = devices;
    this.visibleDevicesSignal.set(devices);
    this.fireDebouncedChangeEvent();
  }

  isDeviceVisible(d: DeviceData, searchText: string) {
    const searchMatch = d.name.toLocaleLowerCase().includes(searchText);

    const ret =
      this.visibleStatuses.includes(
        d.status?.Status || DeviceStatusCode.offline
      ) && searchMatch;
    const desc = `vis: ${ret}, match: ${searchMatch},  name: ${d.name}`;
    return ret;
  }

  /** map of screenshots timestamps */
  deviceScreenshotsTimestamps: Map<string, number> = new Map();

  /**
   * ATTENTION! Call this if the status value changes (from MQTT)
   * expensive and blind function, optimize outside
   *
   * @param deviceId ATTENTION
   * @param status
   */
  setDeviceStatus(deviceId: string, status: DeviceStatusInfo) {
    // let's keep a fast cache of the status easily accessible
    // this.deviceStatuses.set(deviceId, status);

    const s = deviceStatusState.get(deviceId);
    if (s) {
      s.set(status);
    } else {
      deviceStatusState.set(deviceId, signal(status));
    }

    const d = this.devices.find((x) => x.id === deviceId) as DeviceData;
    if (d) {
      d.status = status;
      if (status.Status !== DeviceStatusInfo.Offline.Status) {
        d.lastPing = new Date();
      }
    }
  }

  /**
   * DEPRECATED use getDeviceStatusSignal instead
   * get device status from MQTT signal based cache
   *
   * @param deviceId
   * @returns
   */
  getDeviceStatus(deviceId: string | undefined): DeviceStatusInfo {
    if (deviceId) {
      const sgn = this.getDeviceStatusSignal(deviceId);
      return sgn();
    }
    // default
    return DeviceStatusInfo.Offline;
  }
  getDeviceStatusSignal(deviceId: string) {
    let sgn = deviceStatusState.get(deviceId);
    if (!sgn) {
      sgn = signal(DeviceStatusInfo.Offline);
      deviceStatusState.set(deviceId, sgn);
    }
    return sgn;
  }

  getLastPing(deviceId: string) {
    const device = this.devices.find((d) => d.id === deviceId);

    return device?.lastPing;
  }

  getDeviceScreenshot(deviceId: string) {
    if (!this.deviceScreenshotsTimestamps.get(deviceId)) {
      this.setDeviceScreenshot(deviceId);
    }
    let timestamp = this.deviceScreenshotsTimestamps.get(deviceId);

    if (!timestamp) {
      // initialize the timestamp to avoid creating always new timestamps for the same device on consequent calls
      this.setDeviceScreenshot(deviceId);
      timestamp = this.deviceScreenshotsTimestamps.get(deviceId);
    }

    return getScreenshotUrl(deviceId, timestamp);
  }

  setDeviceScreenshot(deviceId: string) {
    const timestamp = Math.floor(Date.now());
    this.deviceScreenshotsTimestamps.set(deviceId, timestamp);

    const idx = this.devices.findIndex((x) => x.id === deviceId);
    if (idx >= 0) {
      const url = getScreenshotUrl(deviceId, timestamp);
      this.devices[idx].screenshotUrl = url;
      this.devices[idx].lastPing = new Date();
    }
  }

  isAndroid(deviceId: string | undefined) {
    if (deviceId) {
      const deviceInfo = this.getDeviceInfo(deviceId);
      return deviceInfo?.applicationType === 'android';
    }
  }

  /**
   * checks if device screen is powered on from MQTT cache
   *
   * @param deviceId
   * @returns
   */
  isDeviceScreenPoweredOn(deviceId: string | undefined) {
    if (deviceId) {
      const deviceInfo = this.getDeviceInfo(deviceId);
      return deviceInfo?.screen?.isPoweredOn || false;
    }
    return false;
  }

  /**
   * Send MQTT message/command to device
   *
   * @param msg
   * @returns
   */
  mqttMessageToDeviceStatusInfo(msg: string) {
    switch (msg) {
      case TopicLogic.MSG_PAYLOADS.STATUS_ONLINE:
        return DeviceStatusInfo.Online;
      default:
        return DeviceStatusInfo.Offline;
    }
  }

  /**
   * force device clear cache
   *
   * @param deviceId
   */
  forceRefreshDeviceContent(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_CLEAR_CACHE);
  }

  turnScreenOn(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_TOGGLE_SCREEN_POWER, 0);
    if (this.isAndroid(deviceId)) {
      const device = this.getDevice(deviceId);
      if (device?.uid) {
        return this.setDevicePowerAction(
          device?.uid,
          DevicePowerAction.DisplayPowerOn
        );
      }
    }
  }
  turnScreenOff(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_TOGGLE_SCREEN_POWER, 0);
  }

  /**
   * reboot device
   *
   * @param deviceId
   */
  rebootDevice(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_REBOOT, 0);
    if (this.isAndroid(deviceId)) {
      const device = this.getDevice(deviceId);
      if (device?.uid) {
        return this.setDevicePowerAction(
          device.uid,
          DevicePowerAction.SystemReboot
        );
      }
    }
  }

  /** DEPRECATED */
  changeTimeZone(deviceId: string, newTimeZone: string, newNtpServer: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    const cmdParam = {
      command: TopicLogic.MSG_PAYLOADS.CMD_SET_TIMEZONE,
      timeZone: newTimeZone,
      ntpServer: newNtpServer,
    };
    const msg = JSON.stringify(cmdParam);
    this.sendMsg(topic, msg, 2);
  }
  /** DEPRECATED set brightness over mqtt */
  setBrightness(
    deviceId: string,
    brightness1: number,
    brightness2: number,
    time1: string,
    time2: string
  ) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    const cmdParam = {
      command: TopicLogic.MSG_PAYLOADS.CMD_SET_BRIGHTNESS,
      brightness1,
      brightness2,
      time1,
      time2,
    };
    const msg = JSON.stringify(cmdParam);
    this.sendMsg(topic, msg, 2);
  }
  /** DEPRECATED setVolume over MQTT */
  setVolume(deviceId: string, volume: number) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    const cmdParam = {
      command: TopicLogic.MSG_PAYLOADS.CMD_SET_VOLUME,
      volume,
    };
    const msg = JSON.stringify(cmdParam);
    this.sendMsg(topic, msg, 2);
  }

  /**
   * reload device applet
   *
   * @param deviceId
   */
  reloadDeviceApp(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_APP_RELOAD, 2);
  }

  refreshDeviceInfo(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_REFRESH_INFO, 2);
  }

  /** activate mqtt live log for a minute */
  activateLiveLog(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_GET_LIVE_LOG, 2);
  }

  /**
   * request a device to get a screenshot
   */
  askForScreenshot(deviceId: string) {
    const topic = TopicLogic.deviceCommandTopic(deviceId);
    this.sendMsg(topic, TopicLogic.MSG_PAYLOADS.CMD_SAVE_SCREENSHOT);
  }

  getDevice(deviceId: string) {
    const index = this.devices.findIndex((x) => x.id === deviceId);
    console.log('index', index, 'deviceId', deviceId, 'devices', this.devices);

    return index >= 0 ? this.devices[index] : undefined;
  }

  async getDeviceNoCache(id: string) {
    const { data } = await lastValueFrom(
      this.getSingleDeviceGQL.fetch({ id }, { fetchPolicy: 'no-cache' })
    );
    const { device } = data;
    if (device) {
      const deviceData: DeviceData = {
        ...device,
        status: this.getDeviceStatus(id),
        screenshotUrl: this.getDeviceScreenshot(id),
      };
      return deviceData;
    } else {
      return undefined;
    }
  }

  // #region MQTT Communications

  /**
   * low level MQTT send
   *
   * @param topic
   * @param msg
   */
  sendMqttMessage(topic: string, msg: string) {
    this.sendMsg(topic, msg);
  }

  /**
   * subscribe to all device MQTT messages, belonging to selected profile id
   *
   * @param profileId
   */
  private subscribeToMqttProfileMessages(profileId: string): void {
    const topic = TopicLogic.allProfileDevicesTopic(profileId);
    this.mqttSubs.sink = this._mqttService
      .observe(topic)
      .subscribe((message: IMqttMessage) => {
        // console.log('message from: ', message.topic);
        // console.log('content: ', message.payload.toString());

        const ti = TopicLogic.getTopicInfo(message.topic);
        switch (ti.MessageType) {
          case TopicLogic.MSG_TYPES.TOPIC_MSGTYPE_STATUS:
            const status = this.mqttMessageToDeviceStatusInfo(
              message.payload.toString()
            );
            const online = status === DeviceStatusInfo.Online;
            // console.log(`MQTT status ${ti.DeviceId} is now ${status.StatusLabel}`);
            if (this.getDeviceStatus(ti.DeviceId) !== status) {
              this.setDeviceStatus(ti.DeviceId, status);
              this.deviceStatusChanged.emit({
                deviceId: ti.DeviceId,
                ts: Date.now(),
                online,
              });
              this.fireDebouncedChangeEvent();
            }
            break;
          case TopicLogic.MSG_TYPES.TOPIC_MSGTYPE_DEVINFO:
            // TODO update data!
            const deviceInfo = this.parseDeviceInfo(message);
            if (deviceInfo) {
              this.deviceInfoChanged.emit(deviceInfo);
              this.updateDeviceInfo(ti.DeviceId, deviceInfo);
              this.fireDebouncedChangeEvent();
            }
            break;
          default:
            break;
        }
      });
    // console.log('subscribed to topic: ' + topic);
  }

  getMqttProfileMessages$(profileId: string) {
    const topic = TopicLogic.allProfileDevicesTopic(profileId);
    return this._mqttService.observe(topic);
  }
  getMqttDeviceMessages$(
    deviceId: string,
    profileId: string
  ): Observable<DeviceInfo> {
    const topic = TopicLogic.deviceInfoTopic(deviceId, profileId);
    return this._mqttService.observe(topic).pipe(
      map((message) => JSON.parse(message.payload.toString()) as DeviceInfo),
      tap((data) => console.log('mqtt deviceInfo', data))
    );
  }
  getMqttDeviceLog$(
    deviceId: string,
    profileId?: string
  ): Observable<IDeviceLog> | undefined {
    const useProfileId = profileId || this.sessionService.profileId();
    if (useProfileId) {
      const topic = TopicLogic.deviceLogTopic(useProfileId, deviceId);
      return this._mqttService
        .observe(topic)
        .pipe(
          map((message) => JSON.parse(message.payload.toString()) as IDeviceLog)
        );
    }
  }

  getDeviceInfoHistory$(deviceId: string): Observable<DeviceMonitorData[]> {
    const infoHistory = this.getDeviceMonitorHistoryGQL
      .fetch({ id: deviceId }, { fetchPolicy: 'network-only' })
      .pipe(
        // tap((data) => console.log('info data', data)),
        map(({ data }) => data.device?.monitorHistory as DeviceMonitorData[])
      );
    return infoHistory;
  }

  getDeviceStatusHistory$(deviceId: string): Observable<DeviceStatusData[]> {
    const infoHistory = this.getDeviceMonitorHistoryGQL
      .fetch({ id: deviceId }, { fetchPolicy: 'network-only' })
      .pipe(
        map(({ data }) => data.device?.statusHistory as DeviceStatusData[])
        // tap((data) => console.log('status data', data))
      )
      .pipe(
        map((data) => {
          const latestData: DeviceStatusData[] = [...data];
          const currentStatus = this.getDeviceStatus(deviceId);
          if (currentStatus) {
            latestData.push({
              ts: moment().toISOString(),
              online: currentStatus === DeviceStatusInfo.Online ? true : false,
              __typename: 'DeviceStatusRecord',
            });
          }
          return latestData;
        })
        // ,tap((data) => console.log('status data', data))
      );
    return infoHistory;
  }

  // Returns the status for a device, including Historical along with MQTT pushed messages
  getDeviceMqttStatus$(profileId: string, deviceId: string) {
    const mqtt = this.getDeviceStatusHistory$(deviceId).pipe(
      switchMap((history) => {
        return this.getMqttProfileMessages$(profileId)
          .pipe(
            filter(
              (message: IMqttMessage) =>
                message.topic ===
                `designage/appletSnd/${profileId}/${deviceId}/status`
            )
          )
          .pipe(
            map((message) => {
              const status = message.payload.toString();
              const monitorData: DeviceStatusData = {
                ts: moment().toISOString(),
                online: status === 'ON' ? true : false,
                __typename: 'DeviceStatusRecord',
              };

              return monitorData;
            })
          )
          .pipe(
            scan(
              (acc: DeviceStatusData[], curr: DeviceStatusData) => [
                ...acc,
                curr,
              ],
              history
            )
          );
      })
    );

    return merge(this.getDeviceStatusHistory$(deviceId), mqtt);
  }

  getDeviceMqttInfo(profileId: string, deviceId: string) {
    const mqtt = this.getDeviceInfoHistory$(deviceId).pipe(
      switchMap((history) => {
        return this.getMqttProfileMessages$(profileId)
          .pipe(
            filter(
              (message) =>
                message.topic ===
                `designage/appletSnd/${profileId}/${deviceId}/info`
            )
          )
          .pipe(
            map((message) => {
              const info = JSON.parse(message.payload.toString());
              const monitorData: DeviceMonitorData = {
                ts: moment().toISOString(),
                cpu: info.cpu > 0 ? info.cpu : null,
                temp: info.temperature > 0 ? info.temperature : null,
                __typename: 'DeviceStatusRecord',
              };
              return monitorData;
            })
          )
          .pipe(
            scan(
              (acc: DeviceMonitorData[], curr: DeviceMonitorData) => [
                ...acc,
                curr,
              ],
              history
            )
          );
      })
    );
    return merge(this.getDeviceInfoHistory$(deviceId), mqtt);
  }

  parseDeviceInfo(message: IMqttMessage) {
    try {
      const info: DeviceInfo = JSON.parse(message.payload.toString());
      return info;
    } catch (e) {
      console.error('ERROR parsing device info', e);
      console.error('original message payload:', message.payload);
    }
  }
  /**
   * updates data locally as they arrive, without reloading
   * same update is done on API and saved to db
   *
   * @param deviceId
   * @param deviceInfo
   */
  private updateDeviceInfo(deviceId: string, deviceInfo: DeviceInfo) {
    const sgn = this.getDeviceInfoSignal(deviceId);
    sgn?.set(deviceInfo);

    const d = this.devices.find((x) => x.id === deviceId);
    if (d) {
      d.deviceInfo = deviceInfo;
      d.timezoneOffset = this.getTimezoneOffsetByName(
        deviceInfo.currentTime?.timezone
      );
      d.lastPing = new Date();
    }
  }
  getDeviceInfo(deviceId: string) {
    let sgn = this.getDeviceInfoSignal(deviceId);

    return sgn ? sgn() : undefined;
  }
  /** get last value from mqtt or from previously fetched data */
  getDeviceInfoSignal(deviceId: string) {
    let sgn = deviceInfoState.get(deviceId);
    if (!sgn) {
      sgn = signal(this.devices.find((x) => x.id === deviceId)?.deviceInfo);
      deviceInfoState.set(deviceId, sgn);
    }
    return sgn;
  }

  /**
   * subscribe to screenshot updates
   *
   * @param profileId
   */
  private subscribeToMqttScreenshots(profileId: string): void {
    const topic = TopicLogic.allProfileScreenshotsTopic(profileId);
    this.mqttSubs.sink = this._mqttService
      .observe(topic)
      .subscribe((message: IMqttMessage) => {
        const ti = TopicLogic.getTopicInfo(message.topic);
        this.setDeviceScreenshot(ti.DeviceId);
        // this.fireDebouncedChangeEvent();
      });
  }

  private sendMsg(
    topicName: string,
    msg: string,
    qos: QoS = 1,
    retain: boolean = false
  ): void {
    // use unsafe publish for non-ssl websockets
    this._mqttService.unsafePublish(topicName, msg, { qos, retain });
  }

  // #endregion

  /**
   * Calls API to perform signageos power action
   *
   * @param deviceUid
   * @param devicePowerAction
   */
  setDevicePowerAction(
    deviceUid: string,
    devicePowerAction: DevicePowerAction
  ) {
    this.subs.sink = this.setDevicePowerActionGQL
      .mutate(
        { uid: deviceUid, devicePowerAction },
        { fetchPolicy: 'no-cache' }
      )
      .subscribe({
        next: ({ data }) => {
          if (data?.powerAction?.isSuccessful) {
            return true;
            // const successMessage = this.getPowerActionMessage(devicePowerAction);
            // this.toasterService.success(successMessage);
          } else {
            this.toasterService.error('ALERT');
            return false;
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('setDevicePowerAction', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
          return false;
        },
      });
  }

  /**
   * gets the success message of the action
   */
  getPowerActionMessage(devicePowerAction: DevicePowerAction) {
    switch (devicePowerAction) {
      case DevicePowerAction.AppletReload:
        return 'DEVICE_APP_RELOAD_SUCCESS';
      case DevicePowerAction.SystemReboot:
        return 'DEVICE_REBOOT_SUCCESS';
      case DevicePowerAction.DisplayPowerOn:
        return 'DEVICE_DISPLAY_ON_SUCCESS';
      case DevicePowerAction.DisplayPowerOff:
        return 'DEVICE_DISPLAY_OFF_SUCCESS';
      default:
        return '';
    }
  }

  getConnectedChannelPlaylists(deviceId: string) {
    return this.channelService.getChannelPlaylists(deviceId);
  }

  getConnectedChannel(channelId: string) {
    return this.channelService.getChannel(channelId);
  }
}
