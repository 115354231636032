import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceGridComponent } from './device-grid/device-grid.component';
import { DeviceThumbnailComponent } from './device-thumbnail/device-thumbnail.component';
// import { SharedModule } from '@designage/app/shared/shared.module';
import { DesquareCommonModule } from '@desquare/components/common/src/desquare-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetailedComponent } from './device-detailed/device-detailed.component';
import { MomentModule } from 'ngx-moment';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
@NgModule({
  declarations: [
    DeviceGridComponent,
    DeviceThumbnailComponent,
    DeviceDetailedComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DesquareCommonModule,
    MomentModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        extendsFromRoot: true,
        height: '0.5rem',
        'border-radius': '0.25rem',
        'background-color': '#38414a',
      },
    }),
  ],
  exports: [
    DeviceGridComponent,
    DeviceThumbnailComponent,
    DeviceDetailedComponent,
  ],
  bootstrap: [DeviceGridComponent],
})
export class DeviceModule {}
