import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphqlModule } from './graphql.module';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '@desquare/factories';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { MainModule } from './main/main.module';
import { LogoutComponent } from './logout/logout.component';
import { MapModule } from '@desquare/components/wt-component/src/map/map.module';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { DeviceModule } from '@desquare/components/wt-component/src/dashboard/device/device.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { StoreModule } from '@desquare/store/store.module';
import { AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '@desquare/environments';
import { RouterModule } from '@angular/router';
import { DesquareCommonModule } from '@desquare/components/common/src/desquare-common.module';

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'mqtt.designage.se', // 'broker.emqx.io',
  port: 443,
  protocol: 'wss',
  reconnectPeriod: 10000,

  // path: '/mqtt',
};

@NgModule({
  declarations: [AppComponent, LogoutComponent],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,

    DesquareCommonModule,
    DeviceModule,
    MapModule,
    DashboardModule,
    MainModule,
    SharedModule,
    BrowserAnimationsModule,
    GraphqlModule,
    ToastrModule.forRoot(),
    AuthModule.forRoot({
      domain: env.urls.auth0,
      clientId: env.auth0.clientId.watchtower,
      useRefreshTokens: env.auth0.useRefreshToken,
      useRefreshTokensFallback: true,
      cacheLocation: env.auth0.cacheLocation,
      httpInterceptor: {
        allowedList: ['/api/*'],
      },
      authorizationParams: {
        audience: env.auth0.apiAudience,
        redirect_uri: window.location.origin,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    NgxWebstorageModule.forRoot(),
    StoreModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
