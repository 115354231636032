import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { TableColumn } from '@boring.devs/ngx-datatable';
import { Router } from '@angular/router';
import {
  EncryptionService,
  ToasterService,
  CurrentUserService,
} from '@desquare/services';
import {
  GetProfilesForProfileListGQL,
  Profile,
  GetUserProfilesForProfileListGQL,
  ProfilesForProfileListFragment,
} from '@designage/gql';
import {
  IDatatablePageChangeArgs,
  IDatatableRowActivateArgs,
} from '@desquare/interfaces';
import { DatatableRowActivationType, StatusFilters } from '@desquare/enums';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
})
export class ProfileListComponent implements OnDestroy, OnInit {
  private subs = new SubSink();

  @Input() isProfileSelection = false;
  @Output() selectedProfile = new EventEmitter<Profile>();

  loaderMessage!: string;
  loading!: boolean;
  columns!: TableColumn[];
  profiles!: Observable<ProfilesForProfileListFragment>;
  total = 0;
  statusFilters = Object.keys(StatusFilters);
  selectedStatusFilter?: StatusFilters;
  searchText = '';
  profileSearchForm!: FormGroup;
  page = 1;
  pageSize = 5;
  pageSizeOptions = [5, 10, 15, 20, 25];

  constructor(
    private currentUserService: CurrentUserService,
    private getProfilesGQL: GetProfilesForProfileListGQL,
    private getUserProfilesGQL: GetUserProfilesForProfileListGQL,
    private encryptionService: EncryptionService,
    private toasterService: ToasterService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  get hasNoData(): boolean {
    return !this.loading && this.total === 0;
  }

  get statusFilterValue() {
    return this.selectedStatusFilter === StatusFilters.ACTIVE
      ? true
      : this.selectedStatusFilter === StatusFilters.DEACTIVATED
      ? false
      : null;
  }

  get showAddProfileButton() {
    return this.currentUserService.canManageProfiles;
  }

  ngOnInit() {
    this.initVariables();
    this.initForms();
    this.setFormState();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.columns = [
      {
        prop: 'name',
        name: 'NAME',
      },
      {
        prop: 'owner.displayName',
        name: 'OWNER',
      },
      {
        prop: 'users.length',
        name: 'NUMBER_OF_USERS',
      },
      {
        prop: 'updatedAt',
        name: 'LAST_UPDATED',
      },
      {
        prop: 'createdAt',
        name: 'CREATED_ON',
      },
      {
        prop: 'active',
        name: 'STATUS',
      },
    ];

    this.pageSize = this.currentUserService.preferredPageSize;

    this.getProfiles();
  }

  initForms() {
    this.profileSearchForm = this.formBuilder.group({
      search: [null],
      pageSize: [this.pageSize],
    });
  }

  setFormState() {
    this.subs.sink =
      this.profileSearchForm.controls.pageSize.valueChanges.subscribe(
        (value: number) => {
          this.pageSize = value;
          this.currentUserService.setPreferredPageSize(value);

          this.page = 1;
          this.getProfiles();
        }
      );
  }

  getProfiles() {
    if (!this.loading) {
      this.loading = true;
      this.loaderMessage = 'FETCHING_PROFILES';

      if (this.currentUserService.isSuperAdmin) {
        this.profiles = this.getProfilesGQL
          .watch(
            {
              page: this.page - 1,
              pageSize: this.pageSize,
              active: this.statusFilterValue,
              name: this.searchText,
            },
            {
              fetchPolicy: 'cache-and-network',
            }
          )
          .valueChanges.pipe(
            map(({ loading, data: { profiles } }) => {
              this.loading = loading;
              this.total = profiles.total;
              return profiles;
            }),
            catchError((error) => {
              this.toasterService.error('UNKNOWN_ERROR');
              this.loading = false;
              return of({
                total: 0,
                results: [],
              } as ProfilesForProfileListFragment);
            })
          );
      } else {
        if (this.currentUserService.currentUser) {
          this.profiles = this.getUserProfilesGQL
            .watch(
              {
                userId: this.currentUserService.currentUser.id,
                page: this.page - 1,
                pageSize: this.pageSize,
                active: this.statusFilterValue,
                name: this.searchText,
              },
              {
                fetchPolicy: 'cache-and-network',
              }
            )
            .valueChanges.pipe(
              map(({ loading, data: { user } }) => {
                this.loading = loading;
                if (user && user.profiles) {
                  const { profiles } = user;
                  this.total = profiles.total;
                  return profiles;
                } else {
                  return {
                    total: 0,
                    results: [],
                  } as ProfilesForProfileListFragment;
                }
              }),
              catchError((error) => {
                this.toasterService.error('UNKNOWN_ERROR');
                this.loading = false;
                return of({
                  total: 0,
                  results: [],
                } as ProfilesForProfileListFragment);
              })
            );
        }
      }
    }
  }

  onPageChange({ offset }: IDatatablePageChangeArgs) {
    this.page = offset;
    this.getProfiles();
  }

  navigateToProfile({ type, row }: IDatatableRowActivateArgs) {
    if (type === DatatableRowActivationType.CLICK) {
      const profile: Profile = row;

      if (profile && profile.id) {
        if (this.isProfileSelection) {
          this.selectedProfile.emit(profile);
        } else {
          const id = this.encryptionService.encrypt(profile.id);
          this.router.navigate(['/profile/manage', id]);
        }
      }
    }
  }

  setSelectedStatusFilter(status: StatusFilters) {
    this.page = 1;
    this.selectedStatusFilter = status;
    this.getProfiles();
  }

  search(value: string) {
    this.searchText = value;
    this.page = 1;
    this.getProfiles();
  }
}
