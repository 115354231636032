import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Options } from '@desquare/models';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public get options() {
    const options: Options = new Options();
    options.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return options;
  }

  public async get<T>(path: string, options?: Options) {
    try {
      return await this.http
        .get<T>(path, (options || this.options) as {})
        .toPromise();
    } catch (err: any) {
      this.handleError(err);
    }
  }

  public async post<T>(path: string, data: any, options?: Options) {
    try {
      return await this.http
        .post<T>(
          path,
          options ? data : JSON.stringify(data),
          options || (this.options as {})
        )
        .toPromise();
    } catch (err: any) {
      return this.handleError(err);
    }
  }

  private handleError(error: Response) {
    // To do: implement error logic
    switch (error.status) {
      // case 401: // Unauthorized
      //   break;
      // case 404: //Not found
      // window.location.replace('/app/dist/assets/pages/404.html?msg=' + JSON.stringify(error));
      // break;
      // case 500: //Internal Server Error
      // window.location.replace('/app/dist/assets/pages/error.html?msg=' + JSON.stringify(error));
      // break;
      default:
        console.error(error);
        break;
    }

    return Promise.reject(error || 'Server error');
  }
}
