<app-form-dialog
  headerText="UPDATE_FOLDER"
  [valid]="folderForm.valid"
  [pristine]="folderForm.pristine"
  [values]="folderForm.value"
  dismissButtonText="CANCEL"
  closeButtonText="Save"
>
  <form [formGroup]="folderForm">
    <div class="mb-3">
      <label class="form-label" for="name">{{ 'NAME' | translate }}</label>
      <input
        type="text"
        class="form-control form-control-dark"
        formControlName="name"
        autocomplete="off"
      />
    </div>
    <div
      class="alert alert-danger"
      *ngIf="
        folderForm &&
        !folderForm.controls['name'].pristine &&
        folderForm.controls['name'].hasError('required')
      "
    >
      {{ 'NAME_IS_REQUIRED' | translate }}
    </div>
    <div
      class="mb-3 form-check"
      ngbTooltip="{{ 'IS_PUBLIC_FOLDER_TT' | translate }}"
    >
      <label class="form-check-label" for="isPublic"
        >{{ 'IS_PUBLIC_FOLDER' | translate }}?
      </label>
      <input
        class="form-check-input"
        type="checkbox"
        formControlName="isPublic"
      />
    </div>
  </form>
</app-form-dialog>
