import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Folder, FolderType } from '@designage/gql';
import { IFolderNode } from '@desquare/interfaces';
import { CurrentUserService, FolderService } from '@desquare/services';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Component({
  selector: 'app-folder-tree',
  templateUrl: './folder-tree.component.html',
  styleUrls: ['./folder-tree.component.scss'],
  providers: [FolderService], // note this will be a new instance of folder service for every <app-folder-tree>
})
export class FolderTreeComponent implements OnInit {
  mainFolderTreeDataSource!: ArrayDataSource<IFolderNode>;
  mainFolderTreeControl = new NestedTreeControl<IFolderNode>(
    (node) => node.children
  );

  trashFolderTreeDataSource!: ArrayDataSource<IFolderNode>;
  trashFolderTreeControl = new NestedTreeControl<IFolderNode>(
    (node) => node.children
  );

  folders!: Folder[];
  folders$!: Observable<Folder[]>;
  mainFolderTree$!: Observable<IFolderNode[]>;
  filteredFolderTree$!: Observable<IFolderNode[]>;
  trashFolderTree$!: Observable<IFolderNode[]>;
  profileId?: string;

  @Input() showNodeMenu = true;
  @Input() showTrashFolderTree = true;
  @Input() removeFolderIds: string[] = [];

  @Input() selectedFolderId: string | null = null;
  @Output() selectFolderId = new EventEmitter<string | null>();

  rootNodeId = null;
  isRootNode = (_: number, node: IFolderNode) => node.id === this.rootNodeId;
  isTrashNode = (_: number, node: IFolderNode) =>
    node.folderType === FolderType.Trash;
  getTotalItemsInFolder = ({ children, totalMediaCount }: IFolderNode) =>
    children.length + (totalMediaCount ?? 0);

  disableEmptyTrashFolderButton = false;

  // this is an optional optimization for the tree,
  // currently causes issues with refetching the data
  // folderTrackBy = (index: number, node: IFolderNode) => node.id;

  constructor(
    private folderService: FolderService,
    private currentUserService: CurrentUserService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initTree();
  }

  initTree() {
    this.profileId = this.currentUserService.getCurrentProfileId();
    // console.log('this.profileId: ', this.profileId); // DEBUG
    if (!this.profileId) return console.error('no profile id');

    // folder stream (folder data from backend)
    this.folders$ = this.folderService
      .getProfileFoldersWithMedias(this.profileId)
      .pipe(
        map((result) => {
          const folders = result.data.profile?.folders ?? [];

          // save a non observable copy
          this.folders = folders;

          // set root as the default selected folder after refresh
          this.selectFolder(null);

          return folders;
        })
      );

    // // create main folder tree stream from folder stream
    // this.mainFolderTree$ = this.folders$.pipe(
    //   map((folders) => {
    //     const folderTree = this.createMainFolderTree(folders);

    //     return folderTree;
    //   })
    // );

    // create trash folder tree stream from folder stream
    this.trashFolderTree$ = this.folders$.pipe(
      map((folders) => this.createTrashFolderTree(folders)),
      map((trashFolderTree) => {
        // disable empty trash folder button when trash folder
        // is empty
        if (trashFolderTree.length === 0)
          this.disableEmptyTrashFolderButton = true;

        return trashFolderTree;
      })
    );

    // create filtered folder tree stream from folder stream
    // this tree is for move folder to folder dialog
    // note: this is a counter measure to prevent circular or self
    // reference using the parentFolderId field
    this.filteredFolderTree$ = this.folders$.pipe(
      map((folders) => {
        if (this.removeFolderIds.length > 0) {
          // get only the folders not in the remove folder list
          // this will cause the tree generator algorithm to not include the folders
          // including its descendants (since orphaned nodes are ignored)
          return this.createMainFolderTree(
            folders.filter(({ id }) => !this.removeFolderIds.includes(id))
          );
        } else {
          // if selectedFolderId === null then create the normal main folder tree
          return this.createMainFolderTree(folders);
        }
      }),
      map((folderTree) => {
        // manually set the dataNodes
        this.mainFolderTreeControl.dataNodes = folderTree;

        // expand root node initially
        const rootNode = this.mainFolderTreeControl.dataNodes.find(
          ({ id }) => id === this.rootNodeId
        );
        if (rootNode) this.mainFolderTreeControl.expand(rootNode);

        return folderTree;
      })
    );

    // set tree dataSource(s)
    this.mainFolderTreeDataSource = new ArrayDataSource(
      this.filteredFolderTree$
    );
    this.trashFolderTreeDataSource = new ArrayDataSource(this.trashFolderTree$);
  }

  addFolder(parentFolderId?: string) {
    this.folderService.openCreateFolderDialog(parentFolderId);
  }

  editFolder(folderId: string) {
    const folder = this.folders.find(({ id }) => id === folderId);

    if (!folder) return console.error('editFolder: no folder found');

    this.folderService.openUpdateFolderDialog(folderId, {
      name: folder.name,
      isPublic: folder.isPublic,
    });
  }

  deleteFolder(folderId: string, folderName: string) {
    this.folderService.openDeleteFolderDialog([
      {
        id: folderId,
        name: folderName,
      },
    ]);
  }

  permanentDeleteFolder(folderId: string, folderName: string) {
    this.folderService.openPermanentDeleteFolderDialog([
      {
        id: folderId,
        name: folderName,
      },
    ]);
  }

  emptyTrashFolder(trashNode: IFolderNode) {
    if (!trashNode.id) return;

    this.folderService.openPermanentDeleteFolderDialog([
      { id: trashNode.id, name: this.translateService.instant('TRASH_BIN') },
    ]);

    // const trashFolderChildren: Pick<Folder, 'id' | 'name'>[] =
    //   trashNode.children.map(({ id, name }) => {
    //     // note: we are sure that id cannot be null while being a child folder
    //     // since only the root node can have a id === null, hence the "id!"
    //     return { id: id!, name };
    //   });
    // this.folderService.openPermanentDeleteFolderDialog(trashFolderChildren);
  }

  moveFolder(folderId: string, folderName: string) {
    this.folderService.openMoveFolderToFolderDialog([
      { id: folderId, name: folderName },
    ]);
  }

  selectFolder(folderId: string | null) {
    this.selectedFolderId = folderId;

    // old toggle logic
    // this.selectedFolderId =
    //   folderId === this.selectedFolderId ? null : folderId;

    this.selectFolderId.emit(this.selectedFolderId);
  }

  createMainFolderTree(folders: Folder[]): IFolderNode[] {
    // filter in root folders
    // filter folders by folder type then create the folder node tree
    // if folder.folderType = undefined or null, then it is a basic folder
    const mainFolderTree = this.folderService.toFolderNodeTree(folders, {
      prefilter: ({ folderType }) =>
        folderType === undefined || folderType === null,
    });

    // root node
    // note(s):
    //  - the root node is just a frontend concept it doesn't actually exist in the backend
    //  - all the folder data will be pushed into the children field of root node
    //  - root node should not be editable
    const rootNode: IFolderNode = {
      id: this.rootNodeId,
      name: this.translateService.instant('ROOT_FOLDER'),
      children: mainFolderTree,
    };

    return [rootNode];
  }

  createTrashFolderTree(folders: Folder[]): IFolderNode[] {
    // filter in trash folder
    // post filtering the tree results in getting a branch of the tree
    // in this case we get the whole trash tree branch
    const trashFolderTree = this.folderService
      .toFolderNodeTree(folders)
      .filter(({ folderType }) => folderType === FolderType.Trash);

    // if trashFolderTree is empty then create proxy trash folder node
    if (trashFolderTree.length === 0) {
      const proxyTrashFolderNode: IFolderNode = {
        id: 'TRASH', // note: this is just a proxy id
        name: this.folderService.TRASH_FOLDER_NAME,
        children: [],
        folderType: FolderType.Trash,
      };
      return [proxyTrashFolderNode];
    }

    return trashFolderTree;
  }
}
