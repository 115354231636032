import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.scss'],
})
export class BasicDialogComponent {
  @ViewChild('bodyWrapper', {
    static: true,
  })
  bodyWrapper?: ElementRef;

  @Input() headerText = '';

  constructor(public modal: NgbModal) {}

  close() {
    this.modal.dismissAll();
  }
}
