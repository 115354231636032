import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { MapModule } from '@desquare/components/wt-component/src/map/map.module';
import { DeviceModule } from '@desquare/components/wt-component/src/dashboard/device/device.module';
import { SharedModule } from '../shared/shared.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { DesquareCommonModule } from '@desquare/components/common/src/desquare-common.module';
import { WtToolbarComponent } from '@desquare/components/wt-component/src/topbar-tools/topbar-tools.component';

@NgModule({
  // declarations: [DashboardComponent], // WtToolbarComponent
  imports: [
    DesquareCommonModule,
    MapModule,
    DeviceModule,
    SharedModule,
    CommonModule,
    ToolbarModule,
  ],
  // exports: [DashboardComponent],
  // bootstrap: [DashboardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule {}
