<input
  *ngIf="!inputDisabled"
  type="search"
  class="form-control form-control-dark"
  [class.form-control-sm]="formSizeSmall"
  [ngbTypeahead]="search"
  (focus)="focus$.next($any($event).target.value)"
  (click)="click$.next($any($event).target.value)"
  (selectItem)="onSelectItem($event)"
  placeholder="{{ placeHolderText | translate }}"
  [(ngModel)]="item"
  [ngModelOptions]="{ standalone: true }"
  [formControl]="control"
  #typeaheadInstance="ngbTypeahead"
/>

<input
  *ngIf="inputDisabled"
  class="form-control form-control-dark"
  [class.form-control-sm]="formSizeSmall"
  [disabled]="true"
  [value]="item"
/>
