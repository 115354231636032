<!-- main folder tree -->
<cdk-tree
  [dataSource]="mainFolderTreeDataSource"
  [treeControl]="mainFolderTreeControl"
>
  <!-- root node -->
  <cdk-nested-tree-node
    *cdkTreeNodeDef="let node; when: isRootNode"
    class="folder-tree-node"
  >
    <div
      class="rounded-1 folder-node root-node"
      [class.folder--active]="node.id === selectedFolderId"
      [attr.aria-label]="'Toggle ' + node.name"
    >
      <!-- expand arrow button -->
      <i
        cdkTreeNodeToggle
        [style.opacity]="node.children?.length > 0 ? '1' : '0'"
        class="me-1 cursor-pointer folder-arrow ri-arrow-right-s-line"
        [class.folderExpanded]="mainFolderTreeControl.isExpanded(node)"
      ></i>

      <!-- node text  -->
      <div
        class="folder-node-text root-node-text"
        (click)="selectFolder(node.id)"
      >
        {{ node.name | titlecase }}
      </div>

      <div class="root-node-action">
        <!-- add folder to root button -->
        <button
          *ngIf="showNodeMenu"
          class="btn"
          ngbTooltip="Add Folder To Root"
          (click)="addFolder(node.id)"
        >
          <i class="ri-folder-add-fill"></i>
        </button>

        <ng-template #folderCommands>
          <div class="d-flex flex-column">
            <!-- add folder to root button -->
            <button
              class="btn btn-outline-primary button-icon-and-text"
              (click)="addFolder(node.id)"
            >
              <i class="ri-folder-add-fill"></i>
              <div class="button-text">Add Folder To Root</div>
            </button>
          </div>
        </ng-template>
      </div>
    </div>

    <!-- children nodes -->
    <div
      [class.folder-tree-invisible]="!mainFolderTreeControl.isExpanded(node)"
    >
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>

  <!-- folder nodes -->
  <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="folder-tree-node">
    <div
      class="folder-node rounded-1"
      [class.folder--active]="node.id === selectedFolderId"
      [attr.aria-label]="'Toggle ' + node.name"
    >
      <i
        cdkTreeNodeToggle
        [style.opacity]="node.children?.length > 0 ? '1' : '0'"
        class="me-1 cursor-pointer folder-arrow ri-arrow-right-s-line"
        [class.folderExpanded]="mainFolderTreeControl.isExpanded(node)"
      ></i>
      <!-- [ngClass]="{
          'ri-arrow-down-s-line': mainFolderTreeControl.isExpanded(node),
          'ri-arrow-right-s-line':
            !mainFolderTreeControl.isExpanded(node)
        }" -->

      <div
        class="folder-node-text"
        (click)="selectFolder(node.id)"
        [ngbTooltip]="node.name"
      >
        {{ node.name }}
      </div>

      <div class="folder-node-actions">
        <!-- hamburger button -->
        <button
          *ngIf="showNodeMenu"
          class="btn hamburger-button"
          [ngbPopover]="folderCommands"
          animated="true"
          placement="auto"
          container="body"
          [autoClose]="true"
        >
          <i class="ri-menu-line"></i>
        </button>

        <ng-template #folderCommands>
          <div class="d-flex flex-column">
            <!-- add folder button -->
            <button
              class="btn btn-outline-primary button-icon-and-text"
              (click)="addFolder(node.id)"
            >
              <div class="button-text">Add Folder</div>
            </button>

            <!-- edit folder button -->
            <button
              class="btn btn-outline-primary button-icon-and-text"
              (click)="editFolder(node.id)"
            >
              <div class="button-text">Edit Folder</div>
            </button>

            <!-- move folder button -->
            <button
              class="btn btn-outline-primary button-icon-and-text"
              (click)="moveFolder(node.id, node.name)"
            >
              <div class="button-text">Move Folder</div>
            </button>

            <!-- delete folder -->
            <button
              class="btn btn-outline-danger button-icon-and-text"
              (click)="deleteFolder(node.id, node.name)"
            >
              <div class="button-text">Delete Folder</div>
            </button>
          </div>
        </ng-template>
      </div>
    </div>

    <div
      [class.folder-tree-invisible]="!mainFolderTreeControl.isExpanded(node)"
    >
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>
</cdk-tree>

<!-- trash folder tree -->
<div *ngIf="showTrashFolderTree">
  <hr class="my-2" />

  <cdk-tree
    [dataSource]="trashFolderTreeDataSource"
    [treeControl]="trashFolderTreeControl"
  >
    <!-- trash folder node -->
    <cdk-nested-tree-node
      *cdkTreeNodeDef="let node; when: isTrashNode"
      class="folder-tree-node"
    >
      <div
        class="folder-node"
        [class.folder--active]="node.id === selectedFolderId"
        [attr.aria-label]="'Toggle ' + node.name"
      >
        <i
          cdkTreeNodeToggle
          [style.opacity]="node.children?.length > 0 ? '1' : '0'"
          class="me-1 cursor-pointer folder-arrow ri-arrow-right-s-line"
          [class.folderExpanded]="trashFolderTreeControl.isExpanded(node)"
        ></i>
        <div
          class="folder-node-text"
          (click)="selectFolder(node.id)"
          [class.text-muted]="!getTotalItemsInFolder(node)"
          [ngbTooltip]="
            getTotalItemsInFolder(node) <= 0
              ? ('EMPTY' | translate)
              : ('TRASH_BIN_COUNT' | translate) + getTotalItemsInFolder(node)
          "
        >
          {{ 'TRASH_BIN' | translate }}
        </div>

        <div class="folder-node-actions">
          <!-- trash folder's hamburger button -->
          <button
            *ngIf="showNodeMenu && getTotalItemsInFolder(node)"
            class="btn hamburger-button"
            [ngbPopover]="folderCommands"
            animated="true"
            placement="auto"
            container="body"
            [autoClose]="true"
          >
            <i class="ri-menu-line"></i>
          </button>

          <ng-template #folderCommands>
            <div class="d-flex flex-column">
              <!-- restore folder button -->
              <!-- TODO: restore functionality to be implemented -->
              <!-- <div class="btn btn-outline-primary button-icon-and-text">
                <i class="ri-folder-settings-line"></i>
                <div class="button-text">Restore Folder</div>
              </div> -->

              <!-- empty trash folder button -->
              <button
                class="btn btn-outline-danger button-icon-and-text"
                (click)="emptyTrashFolder(node)"
                [disabled]="disableEmptyTrashFolderButton"
              >
                <i class="ri-delete-bin-line"></i>
                <div class="button-text">Empty Trash Folder</div>
              </button>
            </div>
          </ng-template>
        </div>
      </div>

      <div
        [class.folder-tree-invisible]="!trashFolderTreeControl.isExpanded(node)"
      >
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </cdk-nested-tree-node>

    <!-- folder nodes (under the trash folder) -->
    <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="folder-tree-node">
      <div
        class="folder-node"
        [class.folder--active]="node.id === selectedFolderId"
        [attr.aria-label]="'Toggle ' + node.name"
      >
        <i
          cdkTreeNodeToggle
          [style.opacity]="node.children?.length > 0 ? '1' : '0'"
          class="me-1 cursor-pointer folder-arrow ri-arrow-right-s-line"
          [class.folderExpanded]="trashFolderTreeControl.isExpanded(node)"
        ></i>
        <!-- [ngClass]="{
          'ri-arrow-down-s-line':
            trashFolderTreeControl.isExpanded(node),
          'ri-arrow-right-s-line':
            !trashFolderTreeControl.isExpanded(node)
        }" -->

        <div class="folder-node-text" (click)="selectFolder(node.id)">
          {{ node.name }}
        </div>

        <div class="folder-node-actions">
          <!-- trash folder's hamburger button -->
          <div
            *ngIf="showNodeMenu"
            class="btn hamburger-button"
            [ngbPopover]="folderCommands"
            animated="true"
            placement="auto"
            container="body"
            [autoClose]="true"
          >
            <i class="ri-menu-line"></i>
          </div>

          <ng-template #folderCommands>
            <div class="d-flex flex-column">
              <!-- restore folder button -->
              <!-- TODO: restore functionality to be implemented -->
              <!-- <div class="btn btn-outline-primary button-icon-and-text">
                <i class="ri-folder-settings-line"></i>
                <div class="button-text">Restore Folder</div>
              </div> -->

              <!-- permanent delete folder -->
              <button
                class="btn btn-outline-danger button-icon-and-text"
                (click)="permanentDeleteFolder(node.id, node.name)"
              >
                <i class="ri-folder-reduce-fill"></i>
                <div class="button-text">Permanently Delete Folder</div>
              </button>

              <!-- move folder button -->
              <button
                class="btn btn-outline-primary button-icon-and-text"
                (click)="moveFolder(node.id, node.name)"
              >
                <div class="button-text">Move Folder</div>
              </button>
            </div>
          </ng-template>
        </div>
      </div>

      <div
        [class.folder-tree-invisible]="!trashFolderTreeControl.isExpanded(node)"
      >
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </cdk-nested-tree-node>
  </cdk-tree>
</div>
