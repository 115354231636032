/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnInit,
  AfterContentChecked,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ColumnMode,
  DatatableComponent as NgxDataTableComponent,
  TableColumn,
  SelectionType,
  SortPropDir,
  SortDirection,
  NgxDatatableModule,
} from '@boring.devs/ngx-datatable';
import {
  IDatatablePageChangeArgs,
  IDatatableRowActivateArgs,
  IChannelAction,
  IDatatableSortArgs,
} from '@desquare/interfaces';
import { DatatableSettingsService } from '@desquare/services';
import {
  DatatableRowActivationType,
  ResizeCropMethods,
  ResizeCropMethodURIs,
  ScreenStatus,
  ZoneResolutions,
} from '@desquare/enums';
import { environment } from '@desquare/environments';
import { AssetItem, AssetType, ChannelBulkAction } from '@designage/gql';
import { DeviceStatusInfo } from '@desquare/models';
import { getOptimizedUrl, urlToWebp } from '@desquare/utils';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatatableComponent
  implements OnInit, OnChanges, AfterContentChecked
{
  readonly headerHeight = 50;
  // readonly pageLimit = 10;

  @ViewChild('tableWrapper', { static: true }) tableWrapper!: ElementRef;
  @ViewChild('table') ngxDatatable!: NgxDataTableComponent;

  @Input()
  rows: NgxDataTableComponent['rows'] = [];
  @Input() useRowSelection = false;
  @Input() columns!: TableColumn[];
  @Input() count = 0;
  @Input() externalPaging = false;
  @Input() limit = 5;
  @Input() loaderMessage?: string;
  @Input() loading = false;
  @Input() selected: any[] = [];
  @Input() enableSelectionActionButtons = false;
  @Input() enableAddActionButton = false;
  @Input() addActionButtonText?: string;
  @Input() selectActionButtonText = 'SELECT';
  @Input() deselectActionButtonText = 'DESELECT';
  @Input() showCheckbox = false;
  @Input() pageOffset = 0;
  @Input() isUsingCustomFooter = false;
  @Input() enableChannelActions = false;
  @Input() showChannelStatus = false;
  @Input() sorts: SortPropDir[] | undefined = [
    { prop: 'name', dir: SortDirection.asc },
  ];
  @Input() datatableUID?: string;
  @Input() rowHeight = 50;
  @Input() virtualScroll = false;
  @Input() virtualScrollAutoShrinkHeight = true;

  @Output() pageChange = new EventEmitter<IDatatablePageChangeArgs>();
  @Output() rowActivate = new EventEmitter<IDatatableRowActivateArgs>();
  @Output() itemSelect = new EventEmitter<any>();
  @Output() itemDeselect = new EventEmitter<any>();
  @Output() select = new EventEmitter<any>();
  @Output() selectedChange = new EventEmitter<any[]>();
  @Output() columnSelect = new EventEmitter<any>();
  @Output() addButtonClick = new EventEmitter<any>();
  @Output() selectedNameChange = new EventEmitter<any>();
  @Output() onChannelActionClick = new EventEmitter<IChannelAction>();

  ColumnMode = ColumnMode;

  // TODO: refactory and rename this 2 fucking items
  @Input()
  selectionType!: SelectionType;
  SelectionType = SelectionType;

  placeHolderImage!: string;

  datatableWidth!: number;
  datatableHeight!: number;
  activeAppVersion!: string;
  selectedRow!: any;

  get enableActionButtons() {
    return this.enableSelectionActionButtons;
  }

  get enableExtraButtons() {
    return this.enableAddActionButton;
  }
  get currentWidth() {
    return this.tableWrapper.nativeElement.offsetWidth;
  }

  constructor(private datatableSettingsService: DatatableSettingsService) {}

  ngOnInit() {
    this.datatableWidth = this.currentWidth;
    this.placeHolderImage = environment.assets.rawPlaceholder;
    this.initDatatableSettings();
  }

  ngOnChanges(): void {
    if (this.rows) {
      // Refresh the table content
      this.refreshRows();
    }
  }

  ngAfterContentChecked() {
    if (this.rows && this.datatableWidth !== this.currentWidth) {
      this.datatableWidth = this.currentWidth;
      this.rows = [...this.rows];
    }
  }

  refreshRows() {
    // this.selected = [];
    if (this.rows) {
      // we add a delay to allow UI refresh correctly
      const tmpRows = [...this.rows];
      setTimeout(() => {
        this.rows = null;
      }, 1);
      setTimeout(() => {
        this.rows = [...tmpRows];
      }, 2);
    }
  }

  updateSelectedItem(row: any, column: any) {
    const index = this.selected.findIndex((x) => x.id === row.id);

    if (index >= 0) {
      this.selected.splice(index, 1);
      this.itemDeselect.emit(row);
    } else {
      this.selected.push(row);
      this.itemSelect.emit(row);
    }
    this.selectedChange.emit(this.selected);
  }

  isSelected(row: any) {
    return !!this.selected.find((x) => x.id === row.id);
  }

  initDatatableSettings() {
    if (this.datatableSettingsService.loadSortSettings(this.datatableUID)) {
      this.sorts = this.datatableSettingsService.loadSortSettings(
        this.datatableUID
      );
    }
  }

  onSort(event: IDatatableSortArgs) {
    this.refreshRows();
    this.datatableSettingsService.saveSortSettings(event, this.datatableUID);
  }

  onPageChange(event: IDatatablePageChangeArgs) {
    console.log('event', event);

    event.offset = event.offset + 1;
    this.pageChange.emit(event);
  }

  onCheckboxClick(row: any, isSelected: boolean) {
    isSelected ? this.itemSelect.emit(row) : this.itemDeselect.emit(row);
  }

  onRowActivate(activatedArgs: IDatatableRowActivateArgs) {
    if (activatedArgs.type === DatatableRowActivationType.CLICK) {
      // This is needed to prevent `expressionchangedafterchecked` error
      if (activatedArgs.cellElement) {
        activatedArgs.cellElement.blur();
      } else {
        activatedArgs.event.target.blur();
      }
      console.log('activatedArgs', activatedArgs);

      this.selectedRow = activatedArgs.row;
      this.rowActivate.emit(activatedArgs);
    }
  }

  isImageFromAsset(value: string) {
    return value.includes('asset');
  }

  // TODO: transform this into a get property to understand if all rows in page are selected
  allRowsSelected = false;

  onSelectAll(value: boolean) {
    const first = this.ngxDatatable.bodyComponent.indexes.first;
    const last = this.ngxDatatable.bodyComponent.indexes.last;

    if (this.virtualScroll) {
      console.log('has virtual scroll');

      this.selected = value ? [...this.ngxDatatable.bodyComponent.rows] : [];
    } else {
      console.log('has not virtual scroll');
      this.selected = value
        ? [...this.ngxDatatable.bodyComponent.rows.slice(first, last)]
        : [];
    }

    this.allRowsSelected = value;

    this.selectedChange.emit(this.selected);
  }

  rowClass = (row: any) => {
    if (this.showCheckbox && this.isSelected(row)) {
      return 'is-selected';
    } else {
      return 'default-row-select';
    }
  };

  onColumnSelect(row: any, event: any) {
    // This is needed to prevent `expressionchangedafterchecked` error
    event.target.closest('datatable-body-cell').blur();
    this.columnSelect.emit(row);
  }

  onAddButtonClick(row: any) {
    this.addButtonClick.emit(row);
  }

  getAppVersionStatus(value: string) {
    return (value.split(':')[2] || value).trim();
  }

  getAppVersion(value: string) {
    return value.split(':')[0].trim();
  }

  getActiveVersion(value: string) {
    if (!this.activeAppVersion) {
      this.activeAppVersion = value.split(':')[1].trim();
    }
    return this.activeAppVersion;
  }

  channelActionClick(row: any, action: ChannelBulkAction) {
    const channel = row ? row : this.selectedRow;
    if (
      !this.isOnline(channel) &&
      action !== ChannelBulkAction.AddScreen &&
      action !== ChannelBulkAction.DeleteChannel
    ) {
      // Do nothing if channel is offline and action is not addScreen and deleteChannel
      return;
    }
    this.onChannelActionClick.emit({ action, channel });
  }

  isOnline(row: any) {
    return row.deviceStatus === DeviceStatusInfo.Online;
  }

  isActionInProgress(row: any) {
    return row.isActionInProgress;
  }

  getActionMessage(row: any) {
    return row.controlActionMsg;
  }

  hasDevice(row: any) {
    return row.devices.length;
  }

  hasStatusIndicator(row: any) {
    return row.deviceStatus ? true : false;
  }

  getStatusColor(row: any) {
    return row.deviceStatus ? row.deviceStatus.color : 'null';
  }

  getStatusLabel(row: any) {
    return row.deviceStatus ? row.deviceStatus.label : '';
  }

  // This will be changed later after device-channel refactor (DS-113)
  getDeviceScreenStatus(row: any) {
    return row.screenStatus ?? ScreenStatus.DISPLAY_OFF;
  }

  previewContentTransformer(previewContent?: AssetItem, popover?: boolean) {
    if (!previewContent) return; // placeholder image

    const { __typename } = previewContent;

    switch (__typename) {
      case 'ImageAsset': {
        const url = urlToWebp(
          getOptimizedUrl(
            AssetType.Image,
            previewContent.uri,
            {
              resolution: ZoneResolutions.HD,
              width: popover ? 250 : 50,
              height: popover ? 250 : 50,
              aspect: 56.25,
              orientation: 'landscape',
              default: false,
            },
            ResizeCropMethodURIs.PAD_URI
          )
        );
        // console.log('image url: ', url); // DEBUG

        return url;
      }
      case 'VideoAsset': {
        const url = urlToWebp(
          getOptimizedUrl(
            AssetType.Video,
            previewContent.uri,
            {
              resolution: ZoneResolutions.HD,
              width: popover ? 250 : 50,
              height: popover ? 250 : 50,
              aspect: 56.25,
              orientation: 'landscape',
              default: false,
            },
            ResizeCropMethodURIs.PAD_URI
          )
        );
        // console.log('video url: ', url); // DEBUG

        return url;
      }
      case 'IFrameAsset': {
        return; // placeholder image
      }
      case 'HtmlAsset': {
        return; // placeholder image
      }

      default: {
        return; // placeholder image
      }
    }
  }
}
