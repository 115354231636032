import { Component, Input, OnInit } from '@angular/core';
import { Media, MediaForMediaListFragment } from '@designage/gql';

@Component({
  selector: 'delete-folder-dialog',
  templateUrl: './delete-folder-dialog.component.html',
  styleUrls: ['./delete-folder-dialog.component.scss'],
})
export class DeleteFolderDialogComponent implements OnInit {
  @Input() folderNames!: string[];

  @Input() permanentDeleteMode: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
