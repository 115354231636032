import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CreateFolderPayload, Folder } from '@designage/gql';
import { IFolderNode } from '@desquare/interfaces';
import { CurrentUserService, FolderService } from '@desquare/services';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-folder-explorer',
  templateUrl: './folder-explorer.component.html',
  styleUrls: ['./folder-explorer.component.scss'],
  // providers: [FolderService], // sharing service instance with folder-explorer-commands
})
export class FolderExplorerComponent implements OnInit, OnDestroy {
  @Input() selectedFolderId!: string | null;
  @Output() selectFolderId = new EventEmitter<string | null>();

  constructor() {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}
}
