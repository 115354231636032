import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LayoutExplorerComponent } from './layout-explorer.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LayoutExplorerComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
  exports: [LayoutExplorerComponent],
})
export class LayoutExplorerModule {}
