<form [formGroup]="parentForm" class="d-flex align-items-center">
  <div class="input-group flex-nowrap align-items-center">
    <input
      #searchInput
      type="{{ inputType }}"
      (keydown.enter)="emitSearchValue(searchInput.value)"
      class="form-control form-control-dark search-input"
      formControlName="search"
      placeholder="{{ placeholder | translate }}"
      [ngStyle]="inputStyle"
    />
    <button
      (click)="clearFilterInput()"
      [hidden]="parentForm?.controls.search?.pristine"
      class="btn-close btn-close-white position-absolute"
      style="right: 0.25rem; z-index: 10"
      aria-label="Close"
    ></button>
  </div>

  <!-- This div contains the searchMode switch -->
  <div
    *ngIf="globalSearch"
    class="mx-2 btn-group btn-group-sm"
    name="searchMode"
    aria-label="Set folder or global search"
    style="height: fit-content"
    [hidden]="parentForm?.controls.search?.pristine"
  >
    <input
      class="btn-check"
      type="radio"
      formControlName="globalSearch"
      [checked]="!globalSearch.value"
      [value]="false"
    />
    <label
      for="folderSearch"
      class="btn btn-outline-light"
      ngbTooltip="{{ 'SEARCH_FOLDER_TT' | translate }}"
      (click)="onGlobalSearchButtonClicked(false)"
    >
      <div>{{ 'SEARCH_FOLDER' | translate }}</div>
    </label>

    <input
      class="btn-check"
      type="radio"
      formControlName="globalSearch"
      [checked]="globalSearch.value"
      [value]="true"
    />
    <label
      for="globalSearch"
      class="btn btn-outline-light"
      ngbTooltip="{{ 'SEARCH_GLOBAL_TT' | translate }}"
      (click)="onGlobalSearchButtonClicked(true)"
    >
      <div>{{ 'SEARCH_GLOBAL' | translate }}</div>
    </label>
  </div>
</form>
