<app-form-dialog
  headerText="MOVE_FOLDER"
  dismissButtonText="CANCEL"
  closeButtonText="MOVE"
  [values]="selectedDestinationFolderId"
  [valid]="selectedDestinationFolderId !== undefined"
>
  <div class="p-3">
    {{ 'MOVE_FOLDER_INFO' | translate }}
  </div>
  <hr />
  <!-- Source folders -->
  <div class="card">
    <h5 class="card-header">{{ 'FOLDER_SELECTED_FOLDER' | translate }}</h5>
    <div class="card-body p-3">
      <p *ngFor="let folder of selectedFolders">{{ folder.name }}</p>
    </div>
  </div>

  <!-- Target folder -->
  <div class="card">
    <h5 class="card-header">{{ 'FOLDER_TARGET_FOLDER' | translate }}</h5>
    <div class="card-body">
      <app-folder-tree
        [showNodeMenu]="false"
        [showTrashFolderTree]="false"
        [removeFolderIds]="selectedFolderIds"
        (selectFolderId)="selectedDestinationFolderId = $event"
      >
      </app-folder-tree>
    </div>
  </div>
</app-form-dialog>
