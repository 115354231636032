export interface ILocation {
  id: string;
  name: string;
  streetAddress1?: string;
  streetAddress2?: string;
  zip?: string;
  phoneNumber?: string;
  country?: string;
  region?: string;
  city?: string;
  coordinates: {
    x: number;
    y: number;
  } | null;
}
