import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { MapComponent } from '@desquare/components/wt-component/src/map/map.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { DeviceGridComponent } from '@desquare/components/wt-component/src/dashboard/device/device-grid/device-grid.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canLoad: [AuthGuard],
    children: [
      { path: '', redirectTo: 'watchtower', pathMatch: 'full' },
      {
        path: 'watchtower',
        loadChildren: () =>
          import('../watch-tower/watch-tower.module').then(
            (m) => m.WatchTowerModule
          ),
      },
      {
        path: 'dashboard2',
        component: DeviceGridComponent,
      },
      {
        path: 'dashboard3',
        component: MapComponent,
      },
      {
        path: 'map',
        component: MapComponent,
      },
      {
        path: 'channels',
        component: DeviceGridComponent,
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('../profile/profile.module').then((m) => m.ProfileModule),
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
