<div class="row controls">
  <div class="col-12 text-end">
    <button
      class="btn waves-effect waves-light mt-2 me-2 btn-outline-primary"
      (click)="clearLog()"
    >
      {{ 'CLEAR' | translate }}
    </button>
  </div>
</div>
<div
  class="channel-list-container container-fluid content-container-card overflow-hidden"
>
  <designage-data-table
    configId="device-log"
    [data]="logSource | async"
    [columns]="columns"
    [alwaysSort]="false"
    [showMultiSelect]="false"
    [showFooter]="false"
    [columnSelector]="false"
  >
  </designage-data-table>
</div>
