<div
  *ngIf="device"
  [ngClass]="{
    expanded: isExpandedLayout,
    'compact btn-group': isCompactLayout
  }"
>
  <ng-container *ngFor="let command of deviceCommands">
    <button
      *ngIf="command.show && !command.disable"
      class="
        btn btn-sm btn-outline-primary
        d-flex
        align-items-center
        justify-content-start
      "
      [disabled]="command.disable"
      [ngbTooltip]="isCompactLayout ? command.tooltip : null"
      (click)="command.callback()"
    >
      <i [class]="command.iconClassName"></i>
      <div *ngIf="isExpandedLayout" class="ms-1">
        {{ (isExpandedLayout ? command.label : null) | translate }}
      </div>
    </button>
  </ng-container>

  <button
    *ngIf="toggleScreenCommand.show"
    class="
      btn btn-sm btn-outline-primary
      d-flex
      align-items-center
      justify-content-start
    "
    [disabled]="toggleScreenCommand.disable"
    [ngbTooltip]="isCompactLayout ? toggleScreenCommand.tooltip : null"
    (click)="toggleScreenCommand.callback()"
  >
    <i [class]="toggleScreenCommand.iconClassName"></i>
    <div *ngIf="isExpandedLayout" class="ms-1">
      {{ (isExpandedLayout ? toggleScreenCommand.label : null) | translate }}
    </div>
  </button>
</div>

<div *ngIf="!device" class="d-flex align-items-center justify-content-center">
  <i class="ri-error-warning-line"></i>
  <div class="ms-1">
    {{ 'NO_DEVICE_AVAILABLE' | translate }}
  </div>
</div>
<!-- <div>
  <div class="d-flex flex-column">
  </div>
</div> -->
