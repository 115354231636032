<footer class="p-2 footer">
  <div class="container-fluid">
    <div class="mt-1 row justify-content-between">
      <div class="d-flex col-12 col-sm-6 col-md-8">
        © {{ year }}&nbsp;
        <span class="d-none d-lg-block">
          Designage is a platform owned and maintained by&nbsp;</span
        >
        <div class="footer-links">
          <a class="" href="https://www.desquare.se/">Desquare AB</a>
        </div>
        , Sweden
      </div>
      <div class="d-none d-sm-block col-6 col-md-4">
        <div class="text-end footer-links">
          <a href="https://www.desquare.se/en/about-us/">About Us</a>
          <a href="https://www.desquare.se/en/contact-us/">Contact Us</a>
        </div>
      </div>
    </div>
  </div>
</footer>
