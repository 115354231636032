import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@desquare/environments';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthService) {}

  async ngOnInit() {
    this.authService.logout({
      logoutParams: {
        returnTo: environment.urls.watchtowerApp,
      },
    });
  }
}
