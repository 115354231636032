<div class="modal-container">
  <div class="modal-header">
    <h3>{{ headerText | translate }}</h3>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="modal.dismiss()"
    ></button>
  </div>
  <div class="modal-body overflow-auto" style="max-height: 60vh">
    <div #bodyWrapper>
      <ng-content></ng-content>
    </div>

    <div *ngIf="!hasBody" class="modal-body">
      <div *ngIf="!useDangerMessage">
        <span>{{ bodyText | translate }}</span>
        <ul *ngIf="extraBodyItems">
          <li *ngFor="let item of extraBodyItems">{{ item }}</li>
        </ul>
      </div>
      <div *ngIf="useDangerMessage">
        <p>
          <strong>{{ bodyText | translate }}</strong>
        </p>
        <ul *ngIf="extraBodyItems">
          <li *ngFor="let item of extraBodyItems">{{ item }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.dismiss()"
    >
      {{ dismissButtonText | translate }}
    </button>
    <button
      [disabled]="!closeButtonEnabled"
      type="button"
      [class.btn-outline-danger]="useDangerButton"
      [class.btn-outline-success]="!useDangerButton"
      class="btn"
      (click)="accept()"
    >
      {{ closeButtonText | translate }}
    </button>
  </div>
</div>
