/* Marco Fabris

this class offers a simple way to cancel a cycle 
when working with server events

Situation:
while you cycle a data set from server you receive an updated dataset
you need to cancel previous cycle and restart

depending on the situation cancelling a cycle could be the wrong solution
in case of frequent updates you could end up with neverending cycles
as an alternative you could just mark that an update in source data 
is available with RefreshNeeded
*/
export class CycleRefreshManager {
  private _running = false;
  private _cancel = false;
  private _currentSourceVersionCycling = 0;
  private _latestSourceVersion = 0;
  private _completedVersion = -1;

  get isRunning() {
    return this._running;
  }

  get latestBatchId() {
    return this._latestSourceVersion;
  }
  batchStarted() {
    this._running = true;
    this._currentSourceVersionCycling = this._latestSourceVersion;
  }
  /** declare batch transformation was successful */
  batchSuccesful(batchId: number) {
    this._completedVersion = batchId;
  }
  batchStopped() {
    this._running = false;
  }

  sourceDataRefreshed() {
    this._latestSourceVersion++;
  }

  get isNewBatchWaiting() {
    return this._completedVersion !== this._latestSourceVersion;
  }
}
