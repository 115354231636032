<app-form-dialog
  closeButtonText="{{ okText | translate }}"
  dismissButtonText="{{ cancelText | translate }}"
  headerText="{{ title | translate }}"
  [valid]="selectedRows.length"
  [values]="selectedRows"
>
  <designage-data-table
    [configId]="tableConfigId"
    [data]="rows"
    [columns]="columns"
    [(selectedRows)]="selectedRows"
    [alwaysSort]="true"
    (rowClick)="onRowClick($event)"
    [showMultiSelect]="multiSelect"
  >
  </designage-data-table>
</app-form-dialog>
