import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MapComponent } from './map.component';
import { ClusterPopupComponent } from './cluster-popup/cluster-popup.component';
import { DevicePopupComponent } from './device-popup/channel-popup.component';
import { DesquareCommonModule } from '@desquare/components/common/src/desquare-common.module';

@NgModule({
  declarations: [MapComponent, ClusterPopupComponent, DevicePopupComponent],
  imports: [CommonModule, DesquareCommonModule, FormsModule],
  exports: [MapComponent],
  bootstrap: [MapComponent],
})
export class MapModule {}
