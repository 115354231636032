import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetItem, LayoutDetailedFragment, Maybe } from '@designage/gql';

enum Orientation {
  Landscape,
  Portrait,
}
interface IRegionDiv {
  regionName: string;
  top: string;
  left: string;
  height: string;
  width: string;
}

@Component({
  selector: 'app-layout-explorer',
  templateUrl: './layout-explorer.component.html',
  styleUrls: ['./layout-explorer.component.scss'],
})
export class LayoutExplorerComponent implements OnInit {
  @Input() canSelect: boolean = false;
  @Input() selected: Maybe<string>;
  @Input() selectedPreview: Maybe<AssetItem>;
  @Output() selectedChange = new EventEmitter<string>();

  /** component height */
  @Input() height = '200px';
  /** component width */
  @Input() width = '200px';
  /** component bounding box */
  @Input() boundingBox = '200px';
  /** show text in regions */
  @Input() showText = true;

  /** ui element % size */
  $screenDivHeight = 0;
  /** ui element % size */
  $screenDivWidth = 0;
  orientation = Orientation.Landscape;

  regionDivs: IRegionDiv[] = [];
  _layout: Maybe<LayoutDetailedFragment>;
  @Input()
  set layout(value: Maybe<LayoutDetailedFragment>) {
    this.regionDivs = [];
    this._layout = value;
    // calculate canvas geometry
    this.orientation =
      this.layoutHeight > this.layoutWidth
        ? Orientation.Portrait
        : Orientation.Landscape;
    this.$screenDivHeight =
      this.orientation === Orientation.Landscape
        ? 100 / (this.layoutAR || 1)
        : 100;
    this.$screenDivWidth =
      this.orientation === Orientation.Landscape
        ? 100
        : 100 * (this.layoutAR || 1);

    for (const region of this._layout?.source?.regionBlocks || []) {
      // x:100=t:h

      const top =
        (100 * (region?.top || 0)) /
        (this._layout?.source?.canvas?.height || 1);
      const left =
        (100 * (region?.left || 0)) /
        (this._layout?.source?.canvas?.width || 1);
      const height =
        (100 * (region?.height || 0)) /
        (this._layout?.source?.canvas?.height || 1);
      const width =
        (100 * (region?.width || 0)) /
        (this._layout?.source?.canvas?.width || 1);
      const style = `border: 1px solid red;position:absolute;top:${top}%;left:${left}%;height:${height}%;width:${width}%`;

      this.regionDivs.push({
        // divStyle: style,
        regionName: region?.regionName || '',
        top: `${top}%`,
        left: `${left}%`,
        height: `${height}%`,
        width: `${width}%`,
      });
    }
    // console.log('regionDivs', this.regionDivs);
  }
  get layout() {
    return this._layout;
  }

  get layoutRegions() {
    return this._layout?.source?.regionBlocks;
  }
  getHasRegions() {
    return (this.layout?.source?.regionBlocks || []).length > 0;
  }

  /** pixel height of layout canvas */
  get layoutHeight() {
    return this._layout?.source?.canvas?.height || 0;
  }
  /** pixel width of layout canvas */
  get layoutWidth() {
    return this._layout?.source?.canvas?.width || 0;
  }
  /** aspect ratio */
  get layoutAR() {
    return this.layoutWidth / (this.layoutHeight || 1);
  }

  /** resulting scaled % top of screen on ui */
  get screenDivTop() {
    // console.log('screthis.$screenDivHeightenDivTop', 100-)
    return `${(100 - this.$screenDivHeight) / 2}%`;
  }
  /** resulting scaled % left of screen on ui */
  get screenDivLeft() {
    return `${(100 - this.$screenDivWidth) / 2}%`;
  }
  /** resulting scaled % height of screen on ui */
  get screenDivHeight() {
    return `${this.$screenDivHeight}%`;
  }
  /** resulting scaled % width of screen on ui */
  get screenDivWidth() {
    return `${this.$screenDivWidth}%`;
  }

  ngOnInit() {}

  isSelected(regionName: string) {
    return regionName === this.selected;
  }

  selectRegion(region: IRegionDiv) {
    if (this.canSelect) {
      if (this.selected !== region.regionName) {
        this.selected = region.regionName;
      } else {
        this.selected = '';
      }
      this.selectedChange.emit(this.selected);
    }
  }
}
