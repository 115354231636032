import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ProfileSettingsService } from '@desquare/services';

@Pipe({
  name: 'date',
  standalone: true,
})
export class DateProxyPipe implements PipeTransform {
  constructor(
    private profileSettingsService: ProfileSettingsService,
    private translateService: TranslateService
  ) {}

  readonly profileTimezone =
    this.profileSettingsService.getProfileTimezoneValue();

  readonly profileDateFormat = this.profileSettingsService.profileDateFormat();
  readonly profileTimeFormat = this.profileSettingsService.profileTimeFormat();

  readonly customFormats = {
    short: `${this.profileDateFormat.short}, ${this.profileTimeFormat.short}`,
    medium: `${this.profileDateFormat.medium}, ${this.profileTimeFormat.medium}`,
    long: `${this.profileDateFormat.long}, ${this.profileTimeFormat.long}`,
    full: `${this.profileDateFormat.full}, ${this.profileTimeFormat.full}`,
    shortDate: `${this.profileDateFormat.short}`,
    mediumDate: `${this.profileDateFormat.medium}`,
    longDate: `${this.profileDateFormat.long}`,
    fullDate: `${this.profileDateFormat.full}`,
    shortTime: `${this.profileTimeFormat.short}`,
    mediumTime: `${this.profileTimeFormat.medium}`,
    longTime: `${this.profileTimeFormat.long}`,
    fullTime: `${this.profileTimeFormat.full}`,
  };

  // will override angular date pipe, provide currentLanguage if on the fly language switching is implemented
  public transform(
    value: any,
    format: string = 'mediumDate',
    locale: string = 'en',
    timezone: string = this.profileTimezone.timezone
  ) {
    // timezone = this.profileTimezone.offset || timezone;
    format =
      this.customFormats[format as keyof typeof this.customFormats] || format;
    const ngPipe = new DatePipe(
      locale || this.translateService.currentLang,
      timezone || this.profileTimezone.offset
    );
    // console.log('ngPipe', ngPipe);

    return ngPipe.transform(value, format);
  }
}
