import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '@desquare/environments';
import { ApiService } from '../api/api.service';
import { IAddress } from '@desquare/interfaces';
import { StyleService } from '../style/style.service';
import { Themes } from '@desquare/enums';
import GeoJSON, { Position } from 'geojson';
import { GeoJSONSource } from 'mapbox-gl';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  // TODO: update map style when theme changes
  // Check available styles on
  // https://docs.mapbox.com/api/maps/#styles
  // or use Mapbox Studio to create better ones
  //
  // default light/dark styles:
  //  readonly LightStyle = 'mapbox://styles/mapbox/streets-v11';
  //  readonly DarkStyle = 'mapbox://styles/mapbox/dark-v10';

  readonly LightStyle =
    'mapbox://styles/peterbrauner/cki8qdo4obra619quyb3iver0';
  readonly DarkStyle = 'mapbox://styles/peterbrauner/cki8q9pvb0xp419o10kqmhi2k';

  constructor(private api: ApiService, private styleService: StyleService) {
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
  }

  get style(): string {
    // TODO: fix style mgmt
    return this.DarkStyle;
    switch (this.styleService.currentTheme) {
      case Themes.LIGHT:
        return this.LightStyle;
      default:
        return this.DarkStyle;
    }
  }

  get mapLabelColor() {
    switch (this.styleService.currentTheme) {
      case Themes.LIGHT:
        return 'black';
      default:
        return 'white';
    }
  }

  BuildMap(
    fitBound: mapboxgl.LngLatBounds,
    containerDivId = 'map'
  ): mapboxgl.Map {
    let style = this.style;

    const map = new mapboxgl.Map({
      container: containerDivId,
      style: style,
      center: fitBound.getCenter(),
      bounds: fitBound,
      fitBoundsOptions: {
        padding: 70,
      },
    });

    map.addControl(new mapboxgl.NavigationControl());
    return map;
  }

  PointToLngLat(point: mapboxgl.Point): mapboxgl.LngLat {
    return new mapboxgl.LngLat(point.x, point.y);
  }

  coordsToLngLat([longitude, latitude]: number[]): mapboxgl.LngLat {
    return new mapboxgl.LngLat(longitude, latitude);
  }

  lngLatToCoords(coords: mapboxgl.LngLat): [number, number] {
    return [coords.lng, coords.lat];
  }

  getGeoJsonBoundingBox(
    features: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[]
  ): mapboxgl.LngLatBounds {
    let minLng = 180;
    let minLat = 90;
    let maxLng = -180;
    let maxLat = -90;
    if (features.length > 1) {
      features.forEach((feature) => {
        const point = this.coordsToLngLat(
          (feature.geometry as GeoJSON.Point).coordinates
        );

        minLng = Math.min(minLng, point.lng);
        minLat = Math.min(minLat, point.lat);
        maxLng = Math.max(maxLng, point.lng);
        maxLat = Math.max(maxLat, point.lat);
      });
    } else if (features.length === 1) {
      const point = this.coordsToLngLat(
        (features[0].geometry as GeoJSON.Point).coordinates
      );
      minLng = maxLng = point.lng;
      minLat = maxLat = point.lat;
    }

    return new mapboxgl.LngLatBounds([minLng, minLat, maxLng, maxLat]);
  }

  public async addressToCoords(
    address: IAddress
  ): Promise<number[] | undefined> {
    const geoJson = await this.forwardGeocode(address);
    if (geoJson?.features) {
      const p = geoJson?.features[0].geometry as GeoJSON.Point;
      return p.coordinates;
    }
    return undefined;
  }

  public async addressToLngLat(address: IAddress): Promise<mapboxgl.LngLat> {
    try {
      const geoJson = await this.forwardGeocode(address);
      if (geoJson?.features?.length) {
        const p = geoJson?.features[0].geometry as GeoJSON.Point;
        return this.coordsToLngLat(p.coordinates);
      }
      return new mapboxgl.LngLat(0, 0);
    } catch {
      return new mapboxgl.LngLat(0, 0);
    }
  }

  public async forwardGeocode(
    address: IAddress
  ): Promise<
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined
  > {
    // working address:
    // https://api.mapbox.com/geocoding/v5/mapbox.places/121%20axel%20Swartlingsgata%20Norrköping.json?country=SE&limit=1&access_token=pk.eyJ1IjoibWFyY29mYWJyaXMiLCJhIjoiY2thd2I2OHE0MDNsYjJ4bGl4bzF5Z2R5OSJ9.jSyltHT0mezEFJ-FrCAuNA

    const url = new URL(environment.mapbox.geocodingApi);
    url.pathname +=
      `${address.streetAddress1} ${address.streetAddress2} ${address.city} ${address.zip} ${address.region}`.trim() +
      `.json`;

    const options = this.api.options;
    options.params = {
      country: address.country,
      limit: '1',
      access_token: environment.mapbox.accessToken,
    };
    return await this.api.get<
      GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    >(url.href, options);
  }

  getClusterFeatures(
    map: mapboxgl.Map,
    MAP_DATASOURCE_KEY: string,
    clusterId: number
  ): GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] {
    let ret: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] =
      [];

    (map.getSource(MAP_DATASOURCE_KEY) as GeoJSONSource).getClusterChildren(
      clusterId,
      (err, features) => {
        if (err) {
          return ret;
        }
        ret = features;
      }
    );
    return ret;
  }

  explodeCluster(
    map: mapboxgl.Map,
    MAP_DATASOURCE_KEY: string,
    clusterId: number,
    coords: Position
  ) {
    /*
    const marker = this.markers.get(markerId);
    marker?.remove();
    (this.map.getSource(this.MAP_DATASOURCE_KEY) as GeoJSONSource).getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;

      this.map.easeTo({
        center: this.mapService.CoordsToLngLat(coords),
        zoom: zoom,
      });
    });*/

    (map.getSource(MAP_DATASOURCE_KEY) as GeoJSONSource).getClusterChildren(
      clusterId,
      (err, features) => {
        if (err) {
          return;
        }

        const bboxOpt: mapboxgl.FitBoundsOptions = {
          padding: 100,
        };

        if (features.length > 1) {
          const bbox = this.getGeoJsonBoundingBox(features);

          map.fitBounds(bbox, bboxOpt);
        } else {
          if (!features[0].properties) {
            return;
          }

          const cid: number = features[0].properties.cluster_id;
          (
            map.getSource(MAP_DATASOURCE_KEY) as GeoJSONSource
          ).getClusterExpansionZoom(
            cid,
            (getClusteredExpansionZoomError, zoom) => {
              if (getClusteredExpansionZoomError) {
                return;
              }

              coords = (features[0].geometry as GeoJSON.Point).coordinates;
              map.easeTo({
                center: this.coordsToLngLat(coords),
                zoom,
              });
            }
          );
        }
      }
    );
  }
}
