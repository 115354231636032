<p>toolbar works!</p>

<ng-template #filterControls>
  <div class="float-end">
    <!-- Device Sort -->
    <div
      class="dropdown notification-list me-3 d-inline"
      ngbDropdown
      #sortOptionDropdown="ngbDropdown"
    >
      <button
        class="
          btn
          dropdown-toggle
          no-caret
          nav-user
          me-0
          waves-effect waves-light
        "
        ngbDropdownToggle
        id="sortOptionDropdown"
        href="javascript: void(0);"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
      >
        <h5 class="pro-user-name ms-1">
          {{ currentSortOption | translate }}
          <i class="mdi mdi-chevron-down"></i>
        </h5>
      </button>
      <div
        class="dropdown-menu dropdown-menu-right p-0 mt-2"
        aria-labelledby="sortOptionDropdown"
        ngbDropdownMenu
      >
        <!-- item-->
        <app-loader [message]="loaderMessage" *ngIf="loading"></app-loader>
        <div
          [hidden]="loading"
          class="dropdown-header noti-title shadowed-box p-2"
        >
          <h5 class="text-overflow m-0 text-muted">
            {{ 'SORT_BY' | translate }}:
          </h5>
          <ng-container *ngFor="let _sortOption of sortOptions; let i = index">
            <hr *ngIf="i === 2" />
            <h5
              (click)="
                setSortOption(_sortOption);
                $event.stopPropagation();
                sortOptionDropdown.close()
              "
              class="item me-2 left"
            >
              {{ _sortOption | translate }}
            </h5>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Filter Device Status dropdown -->
    <div
      class="dropdown notification-list me-3 d-inline"
      ngbDropdown
      #filterDeviceStatusDropdown="ngbDropdown"
    >
      <button
        class="
          btn
          dropdown-toggle
          no-caret
          nav-user
          me-0
          waves-effect waves-light
        "
        ngbDropdownToggle
        id="filterDeviceStatusDropdown"
        href="javascript: void(0);"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
      >
        <h5 class="pro-user-name ms-1">
          {{ 'FILTER_DEVICE_STATUS' | translate }}
          <i class="mdi mdi-chevron-down"></i>
        </h5>
      </button>
      <div
        class="dropdown-menu dropdown-menu-right p-0 mt-2"
        aria-labelledby="filterDeviceStatusDropdown"
        ngbDropdownMenu
      >
        <div
          [hidden]="loading"
          class="dropdown-header noti-title shadowed-box p-2"
        >
          <div
            class="bg-secondary p-1 m-1 rounded"
            data-toggle="tooltip"
            data-placement="bottom"
            [title]="'PLAYERS_CONFIGURED_NOT_INSTALLED' | translate"
          >
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="deviceFilter1"
                checked
              />
              <label
                class="custom-control-label device-filter-label me-1"
                for="deviceFilter1"
                >{{ 'NOT_YET_INSTALLED' | translate }}</label
              >
              <span class="badge bg-secondary">22</span>
            </div>
          </div>

          <div
            class="bg-offline-warning p-1 m-1 rounded"
            data-toggle="tooltip"
            data-placement="bottom"
            [title]="'PLAYERS_CONFIGURED_NOT_INSTALLED' | translate"
          >
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="deviceFilter2"
                checked
              />
              <label
                class="custom-control-label device-filter-label me-1"
                for="deviceFilter2"
              >
                {{ 'OFFLINE_MORE_THAN_30_MINS' | translate }}
              </label>
              <span class="badge bg-secondary">22</span>
            </div>
          </div>

          <div
            class="bg-offline p-1 m-1 rounded"
            data-toggle="tooltip"
            data-placement="bottom"
            [title]="'PLAYERS_OFFLINE_MORE_THAN_24_HRS' | translate"
          >
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="deviceFilter3"
                checked
              />
              <label
                class="custom-control-label device-filter-label me-1"
                for="deviceFilter3"
              >
                {{ 'OFFLINE_MORE_THAN_24_HRS' | translate }}
              </label>
              <span class="badge bg-secondary">22</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
