<div class="modal-container">
  <div class="modal-header">
    <h3>{{ headerText | translate }}</h3>
    <button
      type="button"
      class="btn-close btn-close-white"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="modal.dismiss()"
    ></button>
  </div>
  <div class="modal-body">
    <div #bodyWrapper>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="showBackButton"
      (click)="back()"
    >
      {{ backButtonText | translate }}
    </button>

    <div class="push-right">
      <button
        type="button"
        class="btn btn-outline-warning me-2"
        [disabled]="dismissButtonEnabled"
        (click)="dismiss()"
      >
        {{ dismissButtonText | translate }}
      </button>
      <button
        type="button"
        class="btn btn-outline-danger me-2"
        *ngIf="showDeleteButton"
        (click)="delete()"
      >
        {{ deleteButtonText | translate }}
      </button>
      <button
        type="button"
        *ngIf="showExtraButton"
        class="btn btn-success me-2"
        (click)="extra()"
      >
        {{ extraButtonText | translate }}
      </button>

      <button
        [disabled]="!valid"
        type="button"
        [class.btn-outline-danger]="useDangerButton && pristine"
        [class.btn-outline-success]="!useDangerButton && pristine"
        [class.btn-danger]="useDangerButton && !pristine"
        [class.btn-success]="!useDangerButton && !pristine"
        class="btn"
        (click)="accept()"
      >
        {{ closeButtonText | translate }}
      </button>
    </div>
  </div>
</div>
