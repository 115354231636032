import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Profile } from '@designage/gql';

@Component({
  selector: 'app-profile-selection-dialog',
  templateUrl: './profile-selection-dialog.component.html',
  styleUrls: ['./profile-selection-dialog.component.scss'],
})
export class ProfileSelectionDialogComponent {
  constructor(public modal: NgbActiveModal) {}

  selectProfile(profile: Profile) {
    this.modal.close(profile);
  }

  dismiss() {
    this.modal.dismiss();
  }
}
