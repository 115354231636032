<div
  class="container-fluid content-container-card profile-list-container"
  [ngClass]="{ 'p-0 m-0': isProfileSelection }"
>
  <div class="row header">
    <div class="col-8">
      <h4 class="header-title">{{ 'PROFILE_LIST' | translate }}</h4>

      <div class="row">
        <div class="col-12">
          <form class="d-inline" [formGroup]="profileSearchForm">
            <select
              formControlName="pageSize"
              class="me-2 form-select form-select-sm form-select-dark page-size-selector"
            >
              <option disabled>{{ 'PAGE_SIZE' | translate }}</option>
              <option
                *ngFor="let pageSize of pageSizeOptions"
                [ngValue]="pageSize"
              >
                {{ pageSize }}
              </option>
            </select>
            <select
              (change)="setSelectedStatusFilter($event.target.value)"
              class="form-select form-select-sm form-select-dark status-filter"
            >
              <option disabled>{{ 'STATUS_FILTER' | translate }}</option>
              <option
                *ngFor="let statusFilter of statusFilters"
                [value]="statusFilter"
              >
                {{ statusFilter | translate }}
              </option>
            </select>
            <app-search-input
              class="me-2 search-input"
              [parentForm]="profileSearchForm"
              (search)="search($event)"
            ></app-search-input>
            <span [hidden]="!loading" class="text-muted searching-message">{{
              'FETCHING_PROFILES' | translate
            }}</span>
          </form>
        </div>
      </div>
    </div>
    <div class="col-2">
      <!-- <button class="btn btn-primary btn-block">Bulk actions</!-->
    </div>
    <div class="col-2">
      <button
        *ngIf="showAddProfileButton"
        [routerLink]="['/profile/create']"
        class="float-end btn btn-outline-primary"
      >
        {{ 'ADD_PROFILE' | translate }}
      </button>
    </div>
  </div>

  <div class="row">
    <div [class.hidden]="hasNoData" class="col-12 table-responsive">
      <app-datatable
        [rows]="(profiles | async)?.results"
        [columns]="columns"
        [loading]="loading"
        [loaderMessage]="loaderMessage"
        [externalPaging]="true"
        [useRowSelection]="true"
        [limit]="pageSize"
        [count]="total"
        [pageOffset]="page - 1"
        [datatableUID]="'app-profile-list'"
        (pageChange)="onPageChange($event)"
        (rowActivate)="navigateToProfile($event)"
      ></app-datatable>
    </div>

    <div [class.hidden]="loading || !hasNoData" class="col-12">
      <hr />
      <h4 class="unavailable-message">{{ 'NO_PROFILES_FOUND' | translate }}</h4>
    </div>
  </div>
</div>
