import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormDialogCloseReason } from '@desquare/enums';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDialogComponent {
  @ViewChild('bodyWrapper', {
    static: true,
  })
  bodyWrapper?: ElementRef;

  @Input() useDangerButton = false;
  @Input() dismissButtonText = 'NO';
  @Input() dismissButtonEnabled = false;
  @Input() deleteButtonText = 'DELETE';
  @Input() showDeleteButton = false;
  @Input() closeButtonText = 'YES';
  @Input() headerText = 'FORM_DIALOG_HEADER';
  @Input() pristine = true;
  @Input() valid = false;
  @Input() backButtonText = 'BACK';
  @Input() showBackButton = false;
  @Input() extraButtonText = 'EXT';
  @Input() showExtraButton = false;

  // onSubmit can be any object from a form
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() values: any = {};

  constructor(public modal: NgbActiveModal) {}

  accept() {
    this.modal.close(this.values);
  }

  dismiss() {
    this.modal.dismiss(this.values);
  }

  delete() {
    this.modal.close(FormDialogCloseReason.DELETE);
  }

  back() {
    this.modal.close(FormDialogCloseReason.BACK);
  }

  extra() {
    this.modal.close(FormDialogCloseReason.EXTRA);
  }
}
