import { Component } from '@angular/core';
import { Device, Maybe } from '@designage/gql';
import { DeviceDataService, UiDataService } from '@desquare/services';
import { DeviceData } from '@desquare/types';

@Component({
  selector: 'app-channel-popup',
  templateUrl: './channel-popup.component.html',
  styleUrls: ['./channel-popup.component.scss'],
})
export class DevicePopupComponent {
  device!: Maybe<DeviceData>;

  constructor(
    private dataService: DeviceDataService,
    private uiDataService: UiDataService
  ) {}

  selectedDevices$ = this.uiDataService.getSelectedForDetailsPanel();

  get isSelected() {
    let selectedDevices = this.selectedDevices$.getValue();
    return selectedDevices.some((d) => d.id === this.device?.id);
  }

  getStatusLabel(device: DeviceData) {
    return device.status?.StatusLabel;
  }

  getStatusColor(device: DeviceData) {
    return device.status?.Color;
  }

  async resetPosition() {
    if (this.device?.id) {
      this.dataService.resetDevicePosition(this.device?.id);
    }
  }
  deviceDetailsToggle(device: Device) {
    this.uiDataService.updateSelectedDevices(device);
  }
}
