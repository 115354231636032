import {
  ChangeDetectorRef,
  ContentChild,
  Directive,
  ElementRef,
  Input,
} from '@angular/core';
import { DatatableComponent } from '@boring.devs/ngx-datatable';
import { lastValueFrom, Observable } from 'rxjs';

/**
 * Context: swimlane ngx-datatable scrollbarV (virtual scroll) has an issue
 * with using rowHeight = 'auto', this directive covers the functionality of
 * rowHeight = 'auto' but doesn't cause the bugs. rowHeight = 'auto' supposedly
 * automatically readjusts the height of the datatable when there is not enough
 * items to be scrollable.
 *
 * Reference: https://github.com/swimlane/ngx-datatable/issues/2082
 */
@Directive({
  selector: '[appRecalculateDataTableHeight]',
})
export class RecalculateDataTableHeightDirective {
  // this variable should match the selector name
  // reference: https://stackoverflow.com/a/46501321
  @Input() appRecalculateDataTableHeight?: any[];
  @Input() count?: number;

  @ContentChild(DatatableComponent) datatable!: DatatableComponent;

  /** the data table element */
  private elem: any;

  /** the data table 'body' element */
  private body: any;

  /** the initial heights (req. for restore) */
  private initHeight!: number;
  private initBodyHeight!: number;

  /** the initial heights (offsets) for header & footer*/
  private offset: number = 0;

  /**
   *
   * @param element
   * @param cdr
   */
  constructor(private element: ElementRef, private cdr: ChangeDetectorRef) {}

  /**
   * initial call after first table render
   */
  ngAfterViewInit() {
    if (!this.appRecalculateDataTableHeight) return;

    setTimeout(() => {
      this.elem = this.element.nativeElement.querySelector('.ngx-datatable');
      this.body = this.element.nativeElement.querySelector('.datatable-body');
      let header =
        this.element.nativeElement.querySelector('.datatable-header');
      let footer =
        this.element.nativeElement.querySelector('.datatable-footer');

      this.initHeight = this.elem.offsetHeight;
      this.initBodyHeight = this.body.offsetHeight;

      if (header) {
        this.offset += header.offsetHeight;
      }
      if (footer) {
        this.offset += footer.offsetHeight;
      }

      this.changeHeight();
    });
  }

  /**
   *	when datamodel is changed
   */
  ngOnChanges() {
    if (!this.appRecalculateDataTableHeight) return;

    setTimeout(() => {
      this.changeHeight();
    });
  }

  /**
   * apply changes in height to the elements
   */
  changeHeight() {
    this.resetHeight();

    let height = this.calculateHeight();
    if (!height) return;

    if (this.elem) {
      this.elem.style.height = height + this.offset + 'px';
      this.body.style.height = height + 'px';
    }
  }

  /**
   *	restore inital values for the table
   */
  resetHeight() {
    if (this.datatable && this.elem) {
      this.elem.style.height = this.initHeight + 'px';
      this.body.style.height = this.initBodyHeight + 'px';

      this.datatable.recalculate();
      this.cdr.detectChanges();
    }
  }

  /**
   * get the height of the table
   *
   * @returns
   */
  calculateHeight(): number | undefined {
    let rows = this.element.nativeElement.querySelectorAll(
      '.datatable-body-row'
    );
    // if (rows.length) {
    //   console.log(rows.length);

    //   return rows.item(0).offsetHeight * rows.length;
    // }
    if (this.count) {
      return rows.item(0).offsetHeight * this.count;
    }
    return;
  }
}
