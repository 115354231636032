import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataBridgeService {
  private channelDataMessage = new BehaviorSubject<any>([]);
  currentChannelDataMessage = this.channelDataMessage.asObservable();

  private profileChannelsData = new BehaviorSubject<any>([]);
  currentProfileChannelsData = this.profileChannelsData.asObservable();

  constructor() {}
  changeChannel(channelId: any[]) {
    this.channelDataMessage.next(channelId);
  }

  updateProfileChannelsData(channelId: any[]) {
    this.profileChannelsData.next(channelId);
  }
}
