<div class="h-100 py-3 container-fluid folder-explorer">
  <div class="d-flex justify-content-between">
    <!-- explorer header -->
    <div class="d-flex align-items-center text-truncate">
      <label>{{ 'FOLDER_EXPLORER_HEADER' | translate }}</label>
    </div>

    <!-- TODO: remove this component later -->
    <!-- <div class="d-flex align-items-center">
      <app-folder-explorer-commands></app-folder-explorer-commands>
    </div> -->
  </div>

  <hr />

  <!-- explorer body -->
  <app-folder-tree
    [selectedFolderId]="selectedFolderId"
    (selectFolderId)="selectFolderId.emit($event)"
  ></app-folder-tree>
</div>
