import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wifi-strength-indicator',
  templateUrl: './wifi-strength-indicator.component.html',
  styleUrls: ['./wifi-strength-indicator.component.scss'],
})
export class WifiStrengthIndicatorComponent {
  @Input() signal!: number;
}
