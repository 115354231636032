<app-confirm-dialog
  headerText="DELETE_FOLDER"
  [useDangerButton]="true"
  [closeButtonText]="'PROCEED'"
  [dismissButtonText]="'CANCEL'"
>
  <div *ngIf="permanentDeleteMode; else toTrashFolderMode" class="card mb-3">
    <h5 class="card-header bg-danger mb-3">
      {{ 'DELETE_FOLDER_PROMPT' | translate }}
    </h5>
    <div class="card-body p-3">
      <p *ngFor="let name of folderNames">{{ name }}</p>
    </div>
    <div class="alert alert-danger">
      {{ 'PERMANENT_DELETE_FOLDER_PROMPT' | translate }}
    </div>
    <div class="p-2">{{ 'PERMANENT_DELETE_FOLDER_WARNING' | translate }}</div>
  </div>

  <ng-template #toTrashFolderMode>
    <div class="card">
      <h5 class="card-header bg-warning mb-3">
        {{ 'FOLDER_TO_TRASH_PROMPT_HEADER' | translate }}
      </h5>
      <div class="card-body p-3">
        <p *ngFor="let name of folderNames">{{ name }}</p>
      </div>
    </div>

    <div class="card">
      <h5 class="card-header bg-secondary">
        {{ 'TRASH_FOLDER_INFO_HEADER' | translate }}
      </h5>
      <div class="alert alert-danger">
        {{ 'TRASH_FOLDER_INFO_PROMPT_2' | translate }}
      </div>
      <div class="p-2">{{ 'TRASH_FOLDER_INFO_PROMPT' | translate }}</div>
      <div class="p-2">{{ 'TRASH_FOLDER_INFO_PROMPT_1' | translate }}</div>
    </div>
  </ng-template>
</app-confirm-dialog>
