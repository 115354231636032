import { Component } from '@angular/core';
import { StyleService } from '@desquare/services';
import { Themes } from '@desquare/enums';

@Component({
  selector: 'app-themepicker',
  templateUrl: './themepicker.component.html',
  styleUrls: ['./themepicker.component.scss'],
})
export class ThemePickerComponent {
  themes = Themes;

  constructor(private styleService: StyleService) {}

  get currentTheme() {
    return this.styleService.currentTheme;
  }

  switchTheme(theme: Themes) {
    this.styleService.setTheme(theme);
  }
}
