import { EventEmitter, Injectable } from '@angular/core';
import {
  AddPlaylistsToChannelsGQL,
  ChannelsForChannelListFragment,
  CreateChannelGQL,
  CreateChannelInput,
  GetChannelGQL,
  GetChannelPlaylistsGQL,
  GetEventsListDocument,
  GetProfileChannelsForChannelListGQL,
  Maybe,
  Playlist,
  PlaylistStatus,
  PublishChannelsContentGQL,
} from '@designage/gql';
import { localStorageKeys } from '@desquare/constants';
import { ICreateChannelForm } from '@desquare/interfaces';
import { lastValueFrom } from 'rxjs';
import { CurrentUserService } from '../current-user/current-user.service';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  publishedPlaylistsForCurrentChannelChanged = new EventEmitter();

  constructor(
    private currentUserService: CurrentUserService,
    private createChannelGQL: CreateChannelGQL,
    private getChannelPlaylistsGQL: GetChannelPlaylistsGQL,
    private publishChannelsContentGql: PublishChannelsContentGQL,
    private addPlaylistsToChannelsGql: AddPlaylistsToChannelsGQL,
    private getChannelGQL: GetChannelGQL,
    private getProfileChannelsGql: GetProfileChannelsForChannelListGQL
  ) {}

  async getCurrentProfileChannels() {
    const profileId = this.currentUserService.getCurrentProfileId();
    return await this.getProfileChannelsList(profileId);
  }

  async getProfileChannelsList(profileId: string) {
    const { data } = await lastValueFrom(
      this.getProfileChannelsGql.fetch({ id: profileId })
    );
    return data.profile?.channels as Maybe<ChannelsForChannelListFragment>[];
  }

  async getChannel(channelId: string) {
    const { data } = await lastValueFrom(
      this.getChannelGQL.fetch(
        { id: channelId },
        {
          fetchPolicy: 'network-only',
        }
      )
    );
    return data.channel;
  }

  async addPlaylistsToChannels(
    channelIds: string[],
    playlistIds: string[],
    region = '',
    publish = false
  ) {
    const { data } = await lastValueFrom(
      this.addPlaylistsToChannelsGql.mutate({
        input: {
          playlistIds,
          channelIds,
          region,
          publish,
        },
      })
    );
    return data?.addPlaylistsToChannels;
  }

  createChannel(input: CreateChannelInput) {
    return this.createChannelGQL.mutate(
      { input }
      /* not really usable here
      {
        refetchQueries: [
          {
            query: GetEventsListDocument,
            variables: {
              profileId: input.profileId,
              // deviceId: this.deviceId,
            },
          },
        ],
      }*/
    );
  }

  async getChannelPlaylists(
    channelId: string,
    statusFilters: PlaylistStatus[] = [PlaylistStatus.Active]
  ) {
    const { data } = await lastValueFrom(
      this.getChannelPlaylistsGQL.fetch(
        {
          channelId,
          playlistName: '',
          statuses: statusFilters,
        },
        { fetchPolicy: 'network-only' }
      )
    );
    return (data.channel?.playlists as Playlist[]) || [];
  }

  async republishChannelContent(channelIds: string[]) {
    const { data } = await lastValueFrom(
      this.publishChannelsContentGql.mutate({
        input: {
          channelIds,
        },
      })
    );
    return data?.publishChannels;
  }
}
