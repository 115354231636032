import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  DeviceStatusColor,
  DeviceStatusInfo,
  FeatureProperties,
} from '@desquare/models';
import { DeviceDataService, UiDataService } from '@desquare/services';

import { DeviceData } from '@desquare/types';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-cluster-popup',
  templateUrl: './cluster-popup.component.html',
  styleUrls: ['./cluster-popup.component.scss'],
})
export class ClusterPopupComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  featureProperties!: FeatureProperties;
  accordionActiveId!: string;
  statusColors = DeviceStatusColor;
  devicesInCluster!: DeviceData[];
  device!: DeviceData;
  collapsed!: boolean;
  selectedChannel: DeviceData[] = [];

  constructor(
    private dataService: DeviceDataService,
    private uiDataService: UiDataService
  ) {}

  selectedDevices$ = this.uiDataService.getSelectedForDetailsPanel();

  isSelected(device: DeviceData) {
    let selectedDevices = this.selectedDevices$.getValue();
    return selectedDevices.findIndex((d) => d.id === device.id);
  }

  async resetPosition() {
    await this.dataService.resetDevicesPosition(
      this.featureProperties.containedDeviceIds
    );
  }

  ngOnInit() {
    this.initValues();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initValues() {
    // this.subs.sink = this.selectedDevices$.subscribe(
    //   (devices: DeviceData[]) => {
    //     this.selectedChannel = devices;
    //     console.log('map:', this.selectedChannel);
    //   }
    // );
    this.accordionActiveId = 'accordion-asset-row';
    this.devicesInCluster = this.dataService.devices.filter((x) =>
      this.featureProperties.containedDeviceIds.includes(x.id)
    );
  }

  deviceDetailsToggle(device: DeviceData) {
    this.uiDataService.updateSelectedDevices(device);
  }
}
