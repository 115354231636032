<ng-container *ngIf="device$ | async as device">
  <!-- Device Information -->
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col" colspan="2">
          {{ 'DEVICE_INFORMATION' | translate }}
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'DEVICE_NAME' | translate }}</td>
        <td>
          <div class="d-flex justify-content-between">
            <span class="text-truncate">
              {{ device.name }}
            </span>
            <copy-to-clipboard [cbcontent]="device.name"></copy-to-clipboard>
          </div>
        </td>
      </tr>
      <tr>
        <td>{{ 'LOCATION' | translate }}</td>
        <td>{{ device.location?.name }}</td>
      </tr>
      <br />
      <tr>
        <td>
          {{ 'DEVICE_TYPE' | translate }}
          <span *ngIf="device.deviceInfo?.modelVersion">{{
            device.deviceInfo?.modelVersion
          }}</span>
        </td>
        <td>{{ device.deviceInfo?.applicationType | titlecase }}</td>
      </tr>
      <tr>
        <td>{{ 'DEVICE_MODEL' | translate }}</td>
        <td>{{ device.deviceInfo?.model | titlecase }}</td>
      </tr>

      <tr>
        <td>{{ 'SERIAL' | translate }}</td>
        <td>
          <div class="d-flex justify-content-between">
            <span class="text-truncate">
              {{ device.deviceInfo?.serialNumber }}
            </span>
            <copy-to-clipboard
              [cbcontent]="device.deviceInfo?.serialNumber"
            ></copy-to-clipboard>
          </div>
        </td>
      </tr>
      <tr>
        <td>{{ 'FIRMWARE' | translate }}</td>
        <td>
          <div class="d-flex justify-content-between">
            <span class="text-truncate">
              {{ device.deviceInfo?.firmware }}
            </span>
            <copy-to-clipboard
              [cbcontent]="device.deviceInfo?.firmware"
            ></copy-to-clipboard>
          </div>
        </td>
      </tr>
      <tr></tr>
    </tbody>
  </table>

  <!-- Device Software -->
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col" colspan="2">
          {{ 'DEVICE_SOFTWARE' | translate }}
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'APPLET_VERSION' | translate }}</td>
        <td>
          <div class="d-flex justify-content-between">
            <span class="text-truncate">
              {{ device.deviceInfo?.appletVersion }}
            </span>
            <copy-to-clipboard
              [cbcontent]="device.deviceInfo?.appletVersion"
            ></copy-to-clipboard>
          </div>
        </td>
      </tr>
      <tr>
        <td>{{ 'CORE_APP_VERSION' | translate }}</td>
        <td>
          <div class="d-flex justify-content-between">
            <span class="text-truncate">
              {{ device.deviceInfo?.applicationVersion }}
            </span>
            <copy-to-clipboard
              [cbcontent]="device.deviceInfo?.applicationVersion"
            ></copy-to-clipboard>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Network -->
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col" colspan="2">
          <div class="d-flex justify-content-between">
            <div>
              {{ 'NETWORK_INFORMATION' | translate }}
            </div>
            <div
              *ngIf="device.deviceInfo?.networkAdapters?.length"
              class="px-1 cursor-pointer bg-secondary rounded-circle"
              (click)="miniMenuOpen = !miniMenuOpen"
              ngbTooltip="{{ 'SHOW_ALL_NETWORK' | translate }}"
            >
              {{ miniMenuOpen ? '-' : '+' }}
            </div>
          </div>
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="!miniMenuOpen; else networkDetails">
        <tr>
          <td>{{ 'NETWORK_ACTIVE_INTERFACE' | translate }}</td>
          <td>
            <div class="d-flex" *ngIf="networkAdapterType">
              {{ networkAdapterType | titlecase }}
              <wifi-strength-indicator
                class="ps-2"
                *ngIf="networkAdapterType === 'wifi'"
                [signal]="networkWifiStrength"
              ></wifi-strength-indicator>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            {{ 'IP' | translate }}
          </td>
          <td>
            <div
              class="d-flex justify-content-between"
              *ngIf="networkAdapterIP"
            >
              <span class="text-truncate"> {{ networkAdapterIP }} </span
              ><copy-to-clipboard
                [cbcontent]="networkAdapterIP"
              ></copy-to-clipboard>
            </div>
          </td>
        </tr>
      </ng-container>

      <ng-template #networkDetails>
        <ng-container
          *ngFor="
            let adapter of activeAdapters;
            let i = index;
            let c = count;
            first as isFirst;
            last as isLast
          "
        >
          <tr class="text-decoration-underline">
            <td colspan="2">
              {{ 'NETWORK_ADAPTER' | translate }} {{ i + 1 }}
              <span class="fw-bolder" *ngIf="isFirst">
                ({{ 'ACTIVE' | translate }})
              </span>
            </td>
          </tr>
          <tr>
            <td>{{ 'NAME' | translate }}</td>
            <td>
              <div class="d-flex justify-content-between">
                <span class="text-truncate">
                  {{ adapter.name }}
                </span>
                <copy-to-clipboard
                  [cbcontent]="adapter.name"
                ></copy-to-clipboard>
              </div>
            </td>
          </tr>

          <tr>
            <td>{{ 'NETWORK_ACTIVE_INTERFACE' | translate }}</td>
            <td>{{ adapter.type }}</td>
          </tr>
          <tr *ngIf="adapter.type === 'wifi'">
            <td>{{ 'IP_WIFI_STRENGTH' | translate }}</td>
            <td>{{ adapter.wifiStrength }}%</td>
          </tr>
          <tr *ngIf="adapter.type === 'wifi'">
            <td>{{ 'IP_WIFI_SSID' | translate }}</td>
            <td>{{ adapter.wifiSsid }}</td>
          </tr>

          <tr>
            <td>{{ 'IP' | translate }}</td>
            <td>
              <div class="d-flex justify-content-between">
                <span class="text-truncate">
                  {{ adapter.localAddress }}
                </span>
                <copy-to-clipboard
                  [cbcontent]="adapter.localAddress"
                ></copy-to-clipboard>
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ 'IP_NETMASK' | translate }}</td>
            <td>
              <div class="d-flex justify-content-between">
                <span class="text-truncate">
                  {{ adapter.netmask }}
                </span>
                <copy-to-clipboard
                  [cbcontent]="adapter.netmask"
                ></copy-to-clipboard>
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ 'IP_GATEWAY' | translate }}</td>
            <td>
              <div class="d-flex justify-content-between">
                <span class="text-truncate">
                  {{ adapter.gateway }}
                </span>
                <copy-to-clipboard
                  [cbcontent]="adapter.gateway"
                ></copy-to-clipboard>
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ 'IP_DNS' | translate }}</td>
            <td>
              <div
                class="d-flex justify-content-between"
                *ngFor="let dns of adapter.dns"
              >
                <span class="text-truncate">
                  {{ dns }}
                </span>
                <copy-to-clipboard [cbcontent]="dns"></copy-to-clipboard>
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ 'NETWORK_MAC' | translate }}</td>
            <td>
              <div class="d-flex justify-content-between">
                <span class="text-truncate">
                  {{ adapter.macAddress | uppercase }}
                </span>
                <copy-to-clipboard
                  [cbcontent]="adapter.macAddress"
                ></copy-to-clipboard>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              {{ adapter.disabled ? 'DISABLED' : ('ENABLED' | translate) }}
            </td>
            <td></td>
          </tr>
          <br *ngIf="c > 1 && !isLast" />
        </ng-container>
      </ng-template>
    </tbody>
  </table>

  <!-- Date Time -->
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col" colspan="2">
          {{ 'TIME_INFORMATION' | translate }}
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'LOCAL_DEVICE_TIME' | translate }}</td>
        <td *ngIf="count$ | async as count">
          {{
            (device.status?.Status === 1 ? count : timestamp)
              | date : 'mediumTime' : undefined : device.timezoneOffset
          }}
        </td>
      </tr>
      <tr>
        <td>{{ 'DATE' | translate }}</td>
        <td>
          {{
            device.deviceInfo?.currentTime?.currentDate | date : 'mediumDate'
          }}
        </td>
      </tr>
      <tr>
        <td>{{ 'TIME_ZONE' | translate }}</td>
        <td>
          {{ device.deviceInfo?.currentTime?.timezone }} (
          {{ device.timezoneOffset }} )
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Status -->
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col" colspan="2">
          {{ 'DEVICE_STATUS' | translate }}
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'STATUS' | translate }}</td>
        <td>
          {{
            getStatusEnum(device.status?.Status) || offLineStatus | titlecase
          }}
          <!-- {{ device.status | json }} -->
        </td>
      </tr>
      <tr>
        <td>
          {{
            getStatusEnum(device.status?.Status) === 'offline'
              ? ('OFFLINE_FOR' | translate)
              : ('PING' | translate)
          }}
        </td>
        <td>
          <span
            ngbTooltip="{{
              timestamp | amLocal | amDateFormat : 'YYYY-MM-DD HH:mm'
            }}"
          >
            {{ timestamp | amTimeAgo }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Debug -->
  <table class="table table-sm" *ngIf="isUserSuperAdmin">
    <thead>
      <tr>
        <th scope="col" colspan="2">
          <div class="d-flex justify-content-between">
            <div>Admin device debug</div>
            <div
              class="px-1 cursor-pointer bg-secondary rounded-circle"
              (click)="debugMiniMenuOpen = !debugMiniMenuOpen"
            >
              {{ debugMiniMenuOpen ? '-' : '+' }}
            </div>
          </div>
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="debugMiniMenuOpen">
        <tr>
          <td>deviceId:</td>
          <td>
            {{ device.id }}
            <copy-to-clipboard [cbcontent]="device.id"></copy-to-clipboard>
          </td>
        </tr>
        <tr>
          <td>data url:</td>
          <td>
            {{ s3playlistsUrl }}
            <copy-to-clipboard [cbcontent]="s3playlistsUrl"></copy-to-clipboard>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>
