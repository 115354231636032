import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgClickOutsideDirective } from 'ng-click-outside2';

import { SlimscrollDirective } from './slimscroll.directive';
import { PreloaderComponent } from './preloader/preloader.component';
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { UploadUiComponent } from './upload-ui/upload-ui.component';

@NgModule({
  declarations: [
    SlimscrollDirective,
    PreloaderComponent,
    PagetitleComponent,
    UploadUiComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgClickOutsideDirective,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
  ],
  exports: [
    SlimscrollDirective,
    PreloaderComponent,
    PagetitleComponent,
    UploadUiComponent,
  ],
})
export class UIModule {}
