import { Component, OnInit, Input } from '@angular/core';
import { DeviceStatusInfo, DeviceStatusCode } from '@desquare/models';
import { DeviceData } from '@desquare/types';
import * as _ from 'lodash';
import { Observable, of, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import {} from 'module';
import { CurrentUserService, DeviceDataService } from '@desquare/services';
import { Maybe } from 'graphql/jsutils/Maybe';
import { NetworkInterface } from '@designage/gql';
import { getNetworkAdaptersUtil } from '@desquare/utils';
import { environment } from '@desquare/environments';

@Component({
  selector: 'device-details-table',
  templateUrl: './device-details-table.component.html',
  styleUrls: ['./device-details-table.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceDetailsTableComponent implements OnInit {
  s3playlistsUrl = '';

  _device!: Maybe<DeviceData>;
  @Input()
  set device(value: Maybe<DeviceData>) {
    this.s3playlistsUrl = '';

    if (value && value.id) {
      this.s3playlistsUrl = `${
        environment.urls.dataBucket
      }/api/v1/playlists/device/${value.id.substring(0, 3)}/${
        value.id
      }.json`.toLowerCase();

      this._device = value;
      this.device$ = of(value);
      // this.deviceInfoSignal = this.deviceDataService.getDeviceInfoSignal(value.id);
      this.timestamp =
        value.deviceInfo?.currentTime?.currentDate || value.updatedAt;

      this.getNetworkAdapter();
      this.realTime = new Date(value.deviceInfo?.currentTime?.currentDate);
    }
  }
  get device() {
    return this._device;
  }

  timestamp!: Date;

  device$: Observable<DeviceData> | undefined;

  realTime!: Date;
  realTimeInterval!: ReturnType<typeof setInterval>;
  readonly oneSecond = 1000;
  activeAdapters: NetworkInterface[] = [];
  networkAdapterIP!: string | undefined | null;
  networkAdapterType!: string | undefined | null;
  networkWifiStrength: number | undefined | null;
  networkAdapterMac: string | undefined | null;
  activeAdapter!: NetworkInterface;
  miniMenuOpen = false;
  debugMiniMenuOpen = true;

  offLineStatus = DeviceStatusInfo.CtrlOffline;

  count$ = interval(1000).pipe(
    // transform the stream to the result you want -- hh:mm:ss
    map((count) => this.realTimeClockFormat(count * 1000))
  );
  get isUserSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }
  constructor(
    private deviceDataService: DeviceDataService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {}

  getStatusEnum(statusEnum: number) {
    return DeviceStatusCode[statusEnum];
  }

  getNetworkAdapter() {
    // If SMIL 2.2.7 or later or Classic Applet 3.0 or later
    if (this.device?.deviceInfo && this.device?.deviceInfo?.networkAdapters) {
      const { activeAdapters, activeAdapter } = getNetworkAdaptersUtil(
        this.device.deviceInfo
      );
      this.activeAdapters = activeAdapters;
      this.networkAdapterType = activeAdapter?.type;
      this.networkWifiStrength = activeAdapter?.wifiStrength;
      this.networkAdapterIP = activeAdapter?.localAddress;
    }
    // If SMIL pre 2.2.7
    const networkInfo = this.device?.deviceInfo?.networkInfo;
    if (networkInfo) {
      this.networkAdapterType = networkInfo.activeInterface;
      this.networkAdapterIP = networkInfo.localAddress;
    }
  }

  realTimeClockFormat(seconds: number) {
    return new Date(
      seconds + +new Date(this.device?.deviceInfo?.currentTime?.currentDate)
    );
  }
}
