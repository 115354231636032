<label class="d-inline-block dropdown-label">{{ label | translate }}:</label>
<div class="labeled-value" *ngIf="items.length > 0; else empty" ngbDropdown class="d-inline-block">
  <div id="channelDropdown" class="channel-selector no-caret" ngbDropdownToggle>
    <label class="dropdown-title">
      <span>{{ selectedItem.name }}</span>
      <i class="label-icon mdi mdi-chevron-down"></i>
    </label>
  </div>
  <div ngbDropdownMenu aria-labelledby="channelDropdown" class="dropdown-container shadowed-box">
    <button
      ngbDropdownItem
      class="text-white menu-item"
      [ngClass]="{ 'menu-item-selected': item === selectedItem }"
      *ngFor="let item of displayableItems; let i = index"
      (click)="itemClick(i)"
    >
      {{ item.name }}
    </button>
    <button *ngIf="displayShowAllButton && items.length > maxThreshold" (click)="showAllClick()" class="btn btn-link">
      {{ 'SHOW_ALL_TOTAL' | translate: { total: items.length } }}
    </button>
  </div>
</div>

<ng-template #empty>
  <h5 class="labeled-value d-inline-block">{{ nullMessagePrompt | translate }}</h5>
</ng-template>
