/*
    We use MapBox clustering functionailty to plot icons on a map.
    Mapbox groups channels in a map creating clusters.
    A cluster has an ID and contains a set of channels.
    When map zoom changes the cluster can be aggregated with other clusters
    or be splitted in sub clusters/single channel icons.

    PROBLEM: a common situation is that the cluster remain unchanged in channels contained
    but changes its ID, so we consider it as a different cluster even if we know already
    everything about it and its popup is already open.


 */

class ClusteredChannel {
  constructor(public channelId: string, public clusterId: string) {}
}

export class VariableClusters {
  // this map connects a channelId to its last known cluster
  clusteredChannels: ClusteredChannel[] = []; // new Map<string, number>();

  getIndexByChannelId(channelId: string): number {
    return this.clusteredChannels.findIndex((x) => x.channelId === channelId);
  }
  getByChannelId(channelId: string): ClusteredChannel | undefined {
    return this.clusteredChannels.find((x) => x.channelId === channelId);
  }

  saveClusterContent(channelIds: string[], clusterId: string) {
    channelIds
      .filter((x) => x.length > 0)
      .forEach((channelId) => {
        const idx = this.getIndexByChannelId(channelId);
        if (idx >= 0) {
          this.clusteredChannels[idx].clusterId = clusterId;
        } else {
          this.clusteredChannels.push(
            new ClusteredChannel(channelId, clusterId)
          );
        }
      });
  }

  getOldClusterId(channelIds: string[]): string {
    const searchedChannelIds = channelIds.filter((x) => x.length > 0);
    const heuristicClusterId = this.getByChannelId(
      searchedChannelIds[0]
    )?.clusterId;
    if (heuristicClusterId) {
      const channels = this.clusteredChannels.filter(
        (x) => x.clusterId === heuristicClusterId // && searchedChannelIds.indexOf(x.channelId) >= 0
      );
      if (channels.length === searchedChannelIds.length) {
        // these 2 clusters have the same content!
        return heuristicClusterId;
      }
    }
    return '';
  }
}
