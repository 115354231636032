import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AngularSplitModule } from 'angular-split';
import { BasicDialogComponent } from './basic-dialog/basic-dialog.component';
import { DateProxyPipe } from './pipe/pipe/date-proxy.pipe';
import { DeviceCommandsComponent } from './device-commands/device-commands.component';
import { CloudinaryModule } from '@cloudinary/ng';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DatatableComponent } from './datatable/datatable.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { DurationPipe } from './pipe/duration/duration.pipe';
import { FileSizePipe } from './pipe/file-size/file-size.pipe';
import { FilterCallbackPipe } from './pipe/filter-callback/filter-callback.pipe';
import { FooterComponent } from './footer/footer.component';
import { FormDialogComponent } from './form-dialog/form-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@boring.devs/ngx-datatable';
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from 'ngx-mask';
import { ProcessingLoaderComponent } from './processing-loader/processing-loader.component';
import { SafePipe } from './pipe/safe/safe.pipe';
import { SearchInputComponent } from './search-input/search-input.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@desquare/factories';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { UIModule } from './ui/ui.module';
import { BaseDialogComponent } from './base-dialog/base-dialog.component';
import { CreateFolderDialogComponent } from './folder/create-folder-dialog/create-folder-dialog.component';
import { UpdateFolderDialogComponent } from './folder/update-folder-dialog/update-folder-dialog.component';
import { MoveMediaFolderDialogComponent } from './folder/move-media-folder-dialog/move-media-folder-dialog.component';
import { FolderTreeComponent } from './folder/folder-tree/folder-tree.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkTableModule } from '@angular/cdk/table';

import { DeleteFolderDialogComponent } from './folder/delete-folder-dialog/delete-folder-dialog.component';
import { MoveFolderToFolderDialogComponent } from './folder/move-folder-to-folder-dialog/move-folder-to-folder-dialog.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { ClipboardModule } from 'ngx-clipboard';

import { FolderExplorerComponent } from './folder/folder-explorer/folder-explorer.component';
import { RecalculateDataTableHeightDirective } from './datatable/directive/recalculate-data-table-height.directive';
import { DeleteMediaDialogComponent } from './media/delete-media-dialog/delete-media-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { WifiStrengthIndicatorComponent } from './wifi-strength-indicator/wifi-strength-indicator.component';
import { TypeofPipe } from './pipe/typeof/typeof.pipe';
import { DeviceDetailsTableComponent } from './device-details-table/device-details-table.component';
import { MomentModule } from 'ngx-moment';
import { DesignageEchartComponent } from './designage-echart/designage-echart.component';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { DeviceHistoricalDashboardComponent } from './device-historical-dashboard/device-historical-dashboard.component';
import { CloudinaryMediaComponent } from './cloudinary/cloudinaryMedia.component';
import { TableRowSelectionDialogComponent } from './tablerow-selection-dialog/tablerow-selection-dialog.component';
import { SmartDateTimeComponent } from './smart-date-time/smart-date-time.component';
import { DeviceLogComponent } from './device-log/device-log.component';
import { DeviceConnectedLinksComponent } from './device-connected-links/device-connected-links.component';
import { LayoutExplorerModule } from './layout-explorer/layout-explorer.module';
import { DesignageDataTableComponent } from './designage-data-table/designage-data-table.component';
import { PlaylistManageDialogComponent } from './playlist-manage-dialog/playlist-manage-dialog.component';

export const ngxMaskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    BasicDialogComponent,
    ConfirmDialogComponent,
    DatatableComponent,
    DatepickerComponent,
    DeviceCommandsComponent,
    DeviceConnectedLinksComponent,
    DropdownMenuComponent,
    DurationPipe,
    FileSizePipe,
    FilterCallbackPipe,
    FooterComponent,
    FormDialogComponent,
    LoaderComponent,
    ProcessingLoaderComponent,
    SafePipe,
    SearchInputComponent,
    TimepickerComponent,
    TypeaheadComponent,
    BaseDialogComponent,
    CreateFolderDialogComponent,
    UpdateFolderDialogComponent,
    MoveMediaFolderDialogComponent,
    FolderTreeComponent,
    DeleteFolderDialogComponent,
    MoveFolderToFolderDialogComponent,
    FolderExplorerComponent,
    CopyToClipboardComponent,
    RecalculateDataTableHeightDirective,
    DeleteMediaDialogComponent,
    WifiStrengthIndicatorComponent,
    TypeofPipe,
    DeviceDetailsTableComponent,
    DesignageEchartComponent,
    DeviceHistoricalDashboardComponent,
    CloudinaryMediaComponent,
    TableRowSelectionDialogComponent,
    SmartDateTimeComponent,
    DeviceLogComponent,
    PlaylistManageDialogComponent,
  ],
  imports: [
    AngularSplitModule,
    CommonModule,
    DateProxyPipe,
    DesignageDataTableComponent,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgxDatatableModule,
    NgxMaskDirective,
    NgxMaskPipe,
    CloudinaryModule,
    // CloudinaryModule.forRoot(cloudinaryLib, {
    //   cloud_name: environment.cloudinary.cloudName as string,
    // }),
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    UIModule,
    CdkTableModule,
    CdkTreeModule,
    MatCheckboxModule,
    ClipboardModule,
    // DesignageDataTableModule,
    MatCheckboxModule,
    MomentModule,
    NgxEchartsModule.forRoot({ echarts }),
    LayoutExplorerModule,
  ],
  exports: [
    AngularSplitModule,
    BasicDialogComponent,
    CdkTableModule,
    MatCheckboxModule,
    ConfirmDialogComponent,
    CloudinaryModule,
    DatatableComponent,
    DatepickerComponent,
    DateProxyPipe,
    DeviceCommandsComponent,
    DeviceConnectedLinksComponent,
    DropdownMenuComponent,
    DurationPipe,
    FileSizePipe,
    FilterCallbackPipe,
    FormDialogComponent,
    FormsModule,
    FooterComponent,
    HttpClientModule,
    LoaderComponent,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ProcessingLoaderComponent,
    ReactiveFormsModule,
    SafePipe,
    SearchInputComponent,
    SmartDateTimeComponent,
    TimepickerComponent,
    TranslateModule,
    TypeaheadComponent,
    UIModule,
    BaseDialogComponent,
    CreateFolderDialogComponent,
    MoveMediaFolderDialogComponent,
    FolderTreeComponent,
    DeleteFolderDialogComponent,
    MoveFolderToFolderDialogComponent,
    FolderExplorerComponent,
    CopyToClipboardComponent,
    DeleteMediaDialogComponent,
    RecalculateDataTableHeightDirective,
    DesignageDataTableComponent,
    TypeofPipe,
    MatCheckboxModule,
    WifiStrengthIndicatorComponent,
    DeviceDetailsTableComponent,
    DesignageEchartComponent,
    DeviceHistoricalDashboardComponent,
    CloudinaryMediaComponent,
    TableRowSelectionDialogComponent,
    DeviceLogComponent,
    LayoutExplorerModule,
    PlaylistManageDialogComponent,
  ],
  providers: [provideNgxMask(ngxMaskOptions)],
})
export class DesquareCommonModule {}
