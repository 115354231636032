import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import {
  GetIsInitializingAppGQL,
  WriteIsInitializingAppToCacheGQL,
} from '@designage/gql';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { languageConstants } from '@desquare/constants';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserService, SessionService } from '@desquare/services';
import {
  EMPTY,
  catchError,
  lastValueFrom,
  map,
  retry,
  switchMap,
  tap,
} from 'rxjs';
import { routeUtil } from '@desquare/utils';
@Component({
  selector: 'app-watchtower-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
}) // implements OnInit, OnDestroy
export class AppComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  initializing = true;
  initialLocation = '';
  loginRequired: boolean;

  constructor(
    private sessionService: SessionService,
    private location: Location,
    private router: Router,
    private setIsInitializingAppGQL: WriteIsInitializingAppToCacheGQL,
    private getIsInitializingAppGQL: GetIsInitializingAppGQL,
    private translateService: TranslateService,
    ngbConfigTooltip: NgbTooltipConfig,
    private currentUserService: CurrentUserService
  ) {
    ngbConfigTooltip.container = 'body';
    ngbConfigTooltip.placement = 'bottom';
    ngbConfigTooltip.openDelay = 300;
    ngbConfigTooltip.closeDelay = 100;
    ngbConfigTooltip.tooltipClass = 'ngbTooltipClass';
    translateService.setDefaultLang('en');

    this.loginRequired = !routeUtil.isPublicAppRoute(this.location.path());
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  async ngOnInit() {
    this.sessionService.initAppName('wt');
    await this.initializeTranslations();
    this.initVariables();
    this.initSubscriptions();
  }

  initVariables() {
    this.setIsInitializingApp(true);
    this.initialLocation = this.location.path();
  }

  async initializeTranslations() {
    this.translateService.addLangs(languageConstants.SUPPORTED_LANGUAGES);

    this.translateService.setDefaultLang('en');

    await lastValueFrom(this.translateService.use('en'));
  }

  /** if current url requires login AND if Auth0 is not authenticated (or logs out) redirects to login, returns Auth0.isAuthenticated$ observable */
  loginIfRequired() {
    return this.sessionService.isAuthenticated$.pipe(
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          if (this.loginRequired) {
            const { origin, pathname, search } = window.location;
            const redirectUri = `${origin}/auth0-callback`;
            const target = `${pathname}${search}`;

            // console.error('SHOULD LOGIN');
            // return;
            this.sessionService.initLogin(origin);
            this.sessionService.login(target);
            /* this.authService.loginWithRedirect({
            authorizationParams: {
              audience: environment.auth0.apiAudience,
              redirect_uri: redirectUri,
            },
            // redirect_uri: redirectUri,
            appState: { target }, // https://community.auth0.com/t/redirect-uri-issue-after-successful-login-with-auth0-auth0-angular/58111/2
          });*/
          }
        }
      })
      // tap((x) => console.log('isAuthenticated: ', x)) // DEBUG
    );
  }

  initSubscriptions() {
    this.subs.sink = this.getIsInitializingApp().subscribe();
    this.subs.sink = this.loginIfRequired()
      .pipe(
        switchMap(async (isAuthenticated) => {
          if (isAuthenticated) {
            await this.currentUserService.initCurrentUser();
            this.setIsInitializingApp(false);
            // return this.fetchActiveUserDetails();
          } else {
            this.setIsInitializingApp(false);
            return EMPTY;
          }
        }),
        catchError((error) => {
          console.warn('Encountered an error, will retry after 4sec.');
          throw new Error(error);
        }),
        retry({
          delay: 4000, // 4sec.
        })
      )
      .subscribe();
  }

  getIsInitializingApp() {
    return this.getIsInitializingAppGQL.watch().valueChanges.pipe(
      tap(({ data: { isInitializingApp } }) => {
        this.initializing = !!isInitializingApp;
      })
    );
  }

  navigateAfterInit() {
    if (!this.isAuth0CallbackRoute(this.location.path())) {
      if (
        (this.location.path() === '' && this.initialLocation === '') ||
        this.isAuth0CallbackRoute(this.initialLocation)
      ) {
        this.router.navigate(['/watchtower']);
      } else {
        this.router.navigateByUrl(this.initialLocation);
      }
    }
  }

  setIsInitializingApp(value: boolean) {
    this.subs.sink = this.setIsInitializingAppGQL
      .mutate({ input: value })
      .subscribe();
  }

  isAuth0CallbackRoute(path: string) {
    return path.includes('/auth0-callback');
  }
}
