import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ToasterService,
  CurrentUserService,
  UiDataService,
  SessionService,
} from '@desquare/services';
import { environment } from '@desquare/environments';
import { Profile, GetMeProfilesQuery, MeProfileFragment } from '@designage/gql';
import { SubSink } from 'subsink';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileSelectionDialogComponent } from '@watchtower/app/profile/profile-selection-dialog/profile-selection-dialog.component';
import { ExcludeMaybe, ArrayElement } from '@desquare/types';
import { LocalStorageService } from 'ngx-webstorage';
import {
  Roles,
  WatchTowerViews,
  WATCH_TOWER_VIEWS_VALUES,
  Themes,
} from '@desquare/enums';
import { ISplit } from '@desquare/interfaces';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  private roleValues: string[] = Object.values(Roles);

  notificationItems!: Array<Record<string, unknown>>;
  languages!: Array<{
    id: number;
    flag?: string;
    name: string;
  }>;
  openMobileMenu!: boolean;
  profilePicture = environment.assets.placeholderImage;
  displayedProfiles: Array<MeProfileFragment> = [];
  actualProfiles: Array<MeProfileFragment> = [];

  loading = false;
  loaderMessage = '';
  totalProfiles = 0;
  watchTowerViews = WATCH_TOWER_VIEWS_VALUES;
  themes = Themes;
  lsKey = 'viewMode';
  get viewMode(): ISplit {
    return this.uiDataService.viewMode;
  }

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    public session: SessionService,
    private currentUserService: CurrentUserService,
    private uiDataService: UiDataService
  ) {}

  get activeUserProfilePicture() {
    return (
      this.session.user()?.profilePicture?.url ||
      this.session.user()?.auth0Profile?.picture ||
      environment.assets.placeholderImage
    );
  }

  get userRole() {
    return this.roleValues.find((value) =>
      this.session
        .user()
        ?.auth0Profile?.roles.map((role) => role.name)
        .includes(value)
    );
  }

  get currentWatchTowerView() {
    return this.currentUserService.currentWatchTowerView;
  }

  ngOnInit() {
    this.initVariables();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.openMobileMenu = false;
  }

  initSubscriptions() {
    // this.uiMode$ = this.currentUserService.getUiMode();

    this.displayedProfiles =
      this.session
        .profiles()
        ?.filter((x) => x.id !== this.session.profile()?.id)
        .slice(0, 10) || [];

    this.totalProfiles = this.session.profiles()?.length || 0;
  }
  setSplitOrientation(splitMode?: 'vertical' | 'horizontal') {
    if (splitMode) this.viewMode.splitMode = splitMode;
    // Update observer
    this.uiDataService.updateViewMode(this.viewMode);
  }

  switchProfile(profile: Profile) {
    if (profile.active) {
      this.toasterService.success('SWITCH_PROFILE_MESSAGE', undefined, {
        profile: profile.name,
      });
      this.currentUserService.setActiveProfile(profile);
      window.location.href = '/';
    } else {
      this.toasterService.error('PROFILE_UNAVAILABLE');
    }
  }

  showProfileDialog() {
    const modalRef = this.modalService.open(ProfileSelectionDialogComponent, {
      size: 'lg',
    });
    modalRef.result.then(
      (profile: Profile) => {
        this.switchProfile(profile);
      },
      () => {}
    );
  }

  /**
   * Logout the user
   */
  logout() {
    this.session.logout('wt');
  }

  setWatchTowerView(
    watchTowerView: WatchTowerViews = WatchTowerViews.DEVICE_LIST
  ) {
    this.currentUserService.setWatchTowerView(watchTowerView);
  }
}
