import { DeviceStatusInfo } from './DeviceStatus';

export class FeatureProperties {
  constructor(properties: { [name: string]: any }) {
    if (properties.LocationId) {
      this.id = properties.LocationId;
      this.name = properties.Name;
      this.isLocation = true;
      return;
    }
    this.online = properties.Online;
    this.ctrlOffline = properties.CtrlOffline;
    this.panelOff = properties.PanelOff;
    this.offline = properties.Offline;
    this.info = properties.Info;
    this.warning = properties.Warning;
    this.devicesCount = properties.DevicesCount;

    if (properties.point_count) {
      // cluster
      this.isCluster = true;
      this.id = properties.cluster_id;
      this.point_count = properties.point_count;
      const ids: string = properties.DeviceIds;
      this.containedDeviceIds = ids.split(',').filter((x) => x.length > 0);
    } else {
      // normal marker, not a cluster
      this.isCluster = false;
      this.id = properties.DeviceId;
      this.name = properties.Name;
    }
  }

  id = '';
  isLocation = false;
  isCluster = false;
  point_count: number | undefined;
  name = '';
  devicesCount = 0;
  online = 0;
  offline = 0;
  ctrlOffline = 0;
  panelOff = 0;
  info = 0;
  warning = 0;

  containedDeviceIds: string[] = [];

  get channelsCount(): number {
    return this.point_count ?? (this.point_count || 1);
  }

  getStatusArray() {
    return [
      this.online,
      this.ctrlOffline,
      this.panelOff,
      this.offline,
      this.info,
      this.warning,
    ];
  }
  // this is used to understand if a status has changed
  get StatusKey() {
    const statuses = this.getStatusArray();

    return statuses.toString();
  }

  ///in case of a single device channel returns the channel status
  getSingleStatus(): DeviceStatusInfo {
    const statuses = this.getStatusArray();
    let maxStatusIdx = 1;
    for (let statusIdx = 0; statusIdx < 6; statusIdx++) {
      if (statuses[statusIdx] > statuses[maxStatusIdx]) {
        maxStatusIdx = statusIdx;
      }
    }
    return DeviceStatusInfo.Statuses[maxStatusIdx];
  }
}
