<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'SELECT_A_PROFILE' | translate }}</h4>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="modal.dismiss()"
    ></button>
  </div>
  <div class="modal-body">
    <app-profile-list
      [isProfileSelection]="true"
      (selectedProfile)="selectProfile($event)"
    ></app-profile-list>
  </div>
</div>
