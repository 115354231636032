import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  AfterContentInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements AfterContentInit {
  @ViewChild('bodyWrapper', {
    static: true,
  })
  bodyWrapper?: ElementRef;

  /** compose a list of items in the body */
  @Input() extraBodyItems: string[] | undefined = undefined;
  /** enable/disable confirmation button */
  @Input() closeButtonEnabled = true;
  /** mark confirmation button as dangerous */
  @Input() useDangerButton = false;
  /** use danger message */
  @Input() useDangerMessage = false;

  /** label of NO button */
  @Input() dismissButtonText = 'NO';
  /** label of the confirmation button (YES) */
  @Input() closeButtonText = 'YES';
  @Input() headerText = 'CONFIRM_DIALOG_HEADER';
  @Input() bodyText = 'CONFIRM_DIALOG_MESSAGE';
  hasBody = false;

  constructor(public modal: NgbActiveModal) {}

  ngAfterContentInit() {
    this.hasBody =
      !!this.bodyWrapper &&
      this.bodyWrapper.nativeElement.childNodes.length > 0;
  }
  accept() {
    this.modal.close(true);
  }
}
