import { Component, OnInit } from '@angular/core';
import { Folder } from '@designage/gql';

@Component({
  selector: 'move-folder-to-folder-dialog',
  templateUrl: './move-folder-to-folder-dialog.component.html',
  styleUrls: ['./move-folder-to-folder-dialog.component.scss'],
})
export class MoveFolderToFolderDialogComponent implements OnInit {
  selectedDestinationFolderId: string | null = null;
  selectedFolders: Pick<Folder, 'id' | 'name'>[] = [];

  get selectedFolderIds(): string[] {
    return this.selectedFolders.map(({ id }) => id);
  }

  constructor() {}

  ngOnInit(): void {}
}
