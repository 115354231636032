import { IBaseEventArgs } from './IBaseEventArgs';
import { ILocation } from './ILocation';

export interface ILocationChangeEventArgs extends IBaseEventArgs {
  entityId: string;
  location: ILocation;
}

export interface ILocationChangeMessageEvent extends MessageEvent {
  data: ILocationChangeEventArgs;
}
