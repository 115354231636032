import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoJSONSource, LngLat } from 'mapbox-gl';
import { Position } from 'geojson';
import {
  MapService,
  DynamicComponentService,
  ToasterService,
  DeviceDataService,
  DataBridgeService,
  UiDataService,
  SessionService,
} from '@desquare/services';
import { SubSink } from 'subsink';
import {
  UpdateLocationGQL,
  ChannelLocationForMapFragment,
  DeviceBaseFragment,
  UpdateDeviceCoordinatesGQL,
} from '@designage/gql';
import { ApolloError } from '@apollo/client/errors';
import { Maybe } from 'graphql/jsutils/Maybe';
import { IAddress } from '@desquare/interfaces';
import { createSvgIcon } from '@desquare/factories';
import { DevicePopupComponent } from './device-popup/channel-popup.component';
import { ClusterPopupComponent } from './cluster-popup/cluster-popup.component';
import { getCode } from 'country-list';
import { CycleRefreshManager, lngLatIsValid } from '@desquare/utils';
import { MarkerAndPopups } from './MarkersManager';
import { DeviceStatusSummary } from '../../../common/src/DeviceStatusSummary';
import { VariableClusters } from './VariableClusters';
import { environment } from '@desquare/environments';
import {
  createMapEventPublisher,
  EventNames,
  EventSources,
  MapEventEntityTypes,
  createLocationEventSubscriber,
  ICoordinates,
} from '@desquare/designage2-events';
import { FeatureProperties } from '@desquare/models';
import { DeviceData } from '@desquare/types';

enum MapMode {
  profileMap = 0, // multi channel
  singleChannelMap = 1,
  locationMap = 2,
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  private mapEventPublisher = createMapEventPublisher(
    window.parent,
    environment.urls.designageApp
  );
  private locationEventSubscriber = createLocationEventSubscriber(window);

  private isMapInitialized = false;

  geoCodingCache = new Map<string, LngLat>();
  markersAndPopups = new MarkerAndPopups();
  markersOnScreen = new Set<string>(); // : string[] = [];
  loaderMessage!: string;
  loading!: boolean;
  onErrorMessage!: string;
  selectedChannel: any[] = [];

  variableClusters = new VariableClusters();

  private subs = new SubSink();

  map!: mapboxgl.Map;

  // current profile
  @Input()
  profileId!: Maybe<string>;

  // query string parameters
  channelId: Maybe<string> = null;
  locationId: string | null = null;
  enablePopups = true;
  enableStatusIcons = true;
  enableDrag = true;

  // MAP KEYS
  readonly MAP_DATASOURCE_KEY = 'points';
  readonly MAP_LAYER_CHANNEL = 'MAP_LAYER_ID_CIRCLE';
  readonly MAP_LAYER_CHANNEL_LABEL = 'MAP_LAYER_ID_LABEL';
  readonly MAP_LAYER_CLUSTERS = 'MAP_LAYER_ID_CLUSTERS';
  readonly SINGLE_MARKER_DEFAULT_ZOOM = 10;
  readonly CLUSTER_MAX_ZOOM = 20;
  readonly CLUSTER_RADIUS = 40;

  get devices() {
    return this.dataService.visibleDevices;
  }

  location: ChannelLocationForMapFragment | null = null;

  srcGeoJsonFeatures: GeoJSON.Feature<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  >[] = [];

  // map flags
  mapMode: MapMode = MapMode.profileMap;

  refreshManager = new CycleRefreshManager();

  chrono = 0;
  chronoLap = 0;
  isExplodingCluster = false;
  isDraggingMarker = false;

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService,
    private updateDeviceCoordsGql: UpdateDeviceCoordinatesGQL,
    private updateLocationGQL: UpdateLocationGQL,
    private uiDataService: UiDataService,
    private router: Router,
    private toasterService: ToasterService,
    private session: SessionService,
    private dynamicComponentService: DynamicComponentService,
    private dataService: DeviceDataService,
    private dataBridge: DataBridgeService
  ) {}

  ngOnInit(): void {
    this.timeProfileStart();

    this.route.queryParams.subscribe((params) => {
      if (params.profileId) {
        this.profileId = params.profileId;
      }
      this.channelId = params.channelid;
      this.locationId = params.locationId;
      this.enablePopups = params.enablePopups === 'false' ? false : true;
      this.enableStatusIcons =
        params.enableStatusIcons === 'false' ? false : true;
      // TODO: check the user has the right to change coordinates
      this.enableDrag = params.enableDrag === 'false' ? false : true;

      if (this.channelId) {
        this.mapMode = MapMode.singleChannelMap;
      }
      if (this.locationId) {
        this.mapMode = MapMode.locationMap;
      }
      this.initSubscriptions();
      this.timeProfile('initSubscriptions');
    });

    this.dataBridge.currentChannelDataMessage.subscribe((channel) => {
      this.selectedChannel = channel;
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initSubscriptions() {
    setInterval(async () => {
      try {
        if (
          this.refreshManager.isNewBatchWaiting &&
          !this.refreshManager.isRunning
        ) {
          this.refreshManager.batchStarted();
          await this.deviceDataChanged();
        }
      } finally {
        this.refreshManager.batchStopped();
      }
    }, 500);

    this.subs.sink = this.dataService.deviceDataChanged.subscribe(async () => {
      this.refreshManager.sourceDataRefreshed();
    });

    // console.log('profile', this.profileId);

    this.profileId = this.session.profileId();
    this.initDataLink();

    this.subs.sink = this.uiDataService.ViewModeObservable$.subscribe(
      (event) => {
        if (event && this.map) {
          setTimeout(() => {
            this.map.resize();
          }, 50);
        }
      }
    );

    this.locationEventSubscriber.addHandlerOnLocationEventChange((event) => {
      this.location = event.data.location;
      this.updateLocationGeojsonSource();
    });
  }

  initDataLink() {
    if (!this.profileId) return;

    this.dataService.initDataLinks({
      profileId: this.profileId,
      deviceId: '',
      locationId: '',
    });
  }

  async deviceDataChanged() {
    // if (this.refreshManager.IsRunning) return;
    // while (this.refreshManager.IsRefreshNeeded) {

    const currentBatchId = this.refreshManager.latestBatchId;
    this.timeProfileStart();

    // this.channels = this.dataService.channels;
    this.location = this.dataService.location;
    this.someDevicesAdded = false;
    this.someDevicesRemoved = false;
    await this.devicesToGeoJson(currentBatchId);
    this.timeProfile('channelsToGeoJson');

    if (currentBatchId !== this.refreshManager.latestBatchId) return;

    if (!this.isMapInitialized) {
      this.initializeMap();
    } else {
      if (this.FeatureCollection) {
        const data = this.map.getSource(
          this.MAP_DATASOURCE_KEY
        ) as GeoJSONSource;
        if (data) data.setData(this.FeatureCollection);
        if (this.someDevicesAdded || this.someDevicesRemoved) {
          const fitBounds = this.mapService.getGeoJsonBoundingBox(
            this.srcGeoJsonFeatures
          );
          this.map.fitBounds(fitBounds, { animate: true, padding: 70 });
        }
      }
    }

    // }
    this.refreshManager.batchSuccesful(currentBatchId);
  }

  async updateLocationGeojsonSource() {
    await this.devicesToGeoJson(0);
    // workaround until this issue is fixed in the typings
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/14877#issuecomment-467320825
    (
      this.map.getSource(this.MAP_DATASOURCE_KEY) as mapboxgl.GeoJSONSource
    ).setData(this.FeatureCollection);

    if (this.srcGeoJsonFeatures.length === 1) {
      const geo = this.srcGeoJsonFeatures[0].geometry as any;
      this.map.flyTo({
        center: { lng: geo.coordinates[0], lat: geo.coordinates[1] },
        speed: 0.8,
      });
    }
  }

  // focusOnChannel() {
  //   this.dataBridge.changeChannel();
  // }

  /*
  getLocations() {
    this.loaderMessage = 'FETCHING_DEVICE';
    this.loading = true;

    // const decryptPID = profileId; // this.encryptionService.decrypt(id);

    if (this.profileId && this.channelId) {
      this.subs.sink = this.getSingleChannelGQL.watch({ id: this.channelId }, { fetchPolicy: 'no-cache' }).valueChanges.subscribe(
        ({ data, loading }) => {
          this.loading = loading;

          if (data?.channel) {
            this.channels.push(data?.channel);
          }
          this.initializeMap();
        },
        (error: ApolloError) => {
          this.loading = false;
          error.graphQLErrors.forEach(err => {
            this.onErrorMessage = 'DEVICE_NOT_PROVISIONED_PROPERLY';
            this.toasterService.error('DEVICE_UNAVAILABLE');
          });
        },
      );
    } else if (this.profileId && this.locationId) {
      this.subs.sink = this.getLocationGQL.watch({ id: this.locationId }, { fetchPolicy: 'no-cache' }).valueChanges.subscribe(
        ({ data, loading }) => {
          this.loading = loading;

          if (data?.location) {
            this.location = data?.location;
          }
          this.initializeMap();
        },
        (error: ApolloError) => {
          this.loading = false;
          error.graphQLErrors.forEach(err => {
            this.onErrorMessage = 'DEVICE_NOT_PROVISIONED_PROPERLY';
            this.toasterService.error('DEVICE_UNAVAILABLE');
          });
        },
      );
    } else if (this.profileId) {
      this.subs.sink = this.getProfileChannelsGQL.watch({ id: this.profileId }, { fetchPolicy: 'no-cache' }).valueChanges.subscribe(
        ({ data, loading }) => {
          this.loading = loading;

          if (data?.profile) {
            this.channels = data?.profile?.channels;
          }
          this.initializeMap();
        },
        (error: ApolloError) => {
          this.loading = false;
          error.graphQLErrors.forEach(err => {
            this.onErrorMessage = 'DEVICE_NOT_PROVISIONED_PROPERLY';
            this.toasterService.error('DEVICE_UNAVAILABLE');
          });
        },
      );
    }
  }
*/
  // creates map and attach events
  async initializeMap() {
    this.timeProfile('initGeoJson');
    const fitBounds = this.mapService.getGeoJsonBoundingBox(
      this.srcGeoJsonFeatures
    );
    this.map = this.mapService.BuildMap(fitBounds, 'map1');
    this.timeProfile('initMap');
    this.isMapInitialized = true;
    this.map.on('load', (event) => {
      this.addSourceToMap();
      this.addLayersToMap();
      this.timeProfile('addSourceToMap');

      if (!this.IsMapClustered) {
        this.map.setZoom(this.SINGLE_MARKER_DEFAULT_ZOOM);
      }

      // manage markers manually
      this.map.on('data', (ev) => {
        if (!ev.isSourceLoaded) return;

        this.map.on('move', () => this.updateMarkers());
        // moveend also fires on zoomend
        this.map.on('moveend', () => this.updateMarkers());

        this.updateMarkers();
      });
    });
  }

  timeProfileStart() {
    this.chrono = this.chronoLap = performance.now();
  }
  timeProfile(message: string = 'lap') {
    const now = performance.now();
    const lapMs = now - this.chronoLap;
    this.chronoLap = now;
    const totalMs = now - this.chrono;
    // Needed for debug
    // console.log(`${message} took ${lapMs} ms, total ${totalMs} ms`);
  }

  someDevicesAdded = false;
  someDevicesRemoved = false;
  // #region GEOJSON
  async devicesToGeoJson(currentBatchId: number) {
    this.geoCodingCache.clear();

    const visibleDeviceIds = this.devices.map((x) => x.id);

    // remove old data
    for (let i = this.srcGeoJsonFeatures.length - 1; i >= 0; i--) {
      if (currentBatchId != this.refreshManager.latestBatchId) return;
      if (!visibleDeviceIds.includes(this.srcGeoJsonFeatures[i].id as string)) {
        this.srcGeoJsonFeatures.splice(i, 1);
        this.someDevicesRemoved = true;
      }
    }
    for (const device of this.devices) {
      if (currentBatchId != this.refreshManager.latestBatchId) return;

      const feature = await this.createGeoJsonDeviceFeature(device);
      const idx = this.srcGeoJsonFeatures.findIndex((x) => x.id === feature.id);
      if (idx < 0) {
        this.srcGeoJsonFeatures.push(feature);
        this.someDevicesAdded = true;
      } else {
        this.srcGeoJsonFeatures[idx] = feature;
      }
    }
    if (currentBatchId != this.refreshManager.latestBatchId) return;
    // TODO: I think following lines can be deleted
    const locFeat = await this.createGeoJsonLocationFeature(this.location);
    if (locFeat) {
      const idx = this.srcGeoJsonFeatures.findIndex((x) => x.id === locFeat.id);
      if (idx < 0) {
        this.srcGeoJsonFeatures.push(locFeat);
      } else {
        this.srcGeoJsonFeatures[idx] = locFeat;
      }
    }
  }

  async createGeoJsonLocationFeature(
    location: ChannelLocationForMapFragment | null
  ): Promise<GeoJSON.Feature<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  > | null> {
    if (!location) {
      return null;
    }

    const pos = await this.getLocationCoordsOrGeocode(location);

    if (!pos) {
      return null;
    }

    const feature: GeoJSON.Feature<
      GeoJSON.Geometry,
      GeoJSON.GeoJsonProperties
    > = {
      type: 'Feature',
      properties: {
        LocationId: location.id,
        Name: location.name,
      },
      geometry: {
        coordinates: [pos.lng, pos.lat],
        type: 'Point',
      },
      id: this.getLocationFeatureId(location),
    };
    return feature;
  }

  async createGeoJsonDeviceFeature(
    device: DeviceData
  ): Promise<GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>> {
    const devSummary = new DeviceStatusSummary([device.status]);
    const pos = await this.getChannelCoordsOrGeocode(device);

    const feature: GeoJSON.Feature<
      GeoJSON.Geometry,
      GeoJSON.GeoJsonProperties
    > = {
      type: 'Feature',
      properties: {
        DeviceId: device.id, // this is not a duplicate, is needed for cluster syntax and markers logic
        ChannelId: device.channelId,
        Name: device.name,
        DevicesCount: devSummary.DevicesCount, // if this =0 then offline=1
        Online: devSummary.Online,
        CtrlOffline: devSummary.CtrlOffline,
        PanelOff: devSummary.PanelOff,
        Offline: devSummary.Offline,
        Info: devSummary.Info,
        Warning: devSummary.Warning,
        isSelected: Boolean,
      },
      geometry: {
        coordinates: [pos.lng, pos.lat],
        type: 'Point',
      },
      id: device.id,
    };
    return feature;
  }

  getLocationFeatureId(
    location: ChannelLocationForMapFragment
  ): string | number | undefined {
    return 'loc_' + location.id || '';
  }
  /** dependent from srcGeoJsonFeatures */
  get FeatureCollection(): GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  > {
    const fc: GeoJSON.FeatureCollection<
      GeoJSON.Geometry,
      GeoJSON.GeoJsonProperties
    > = {
      features: this.srcGeoJsonFeatures,
      type: 'FeatureCollection',
    };
    return fc;
  }

  private addSourceToMap() {
    const geoJson: mapboxgl.AnySourceData = {
      type: 'geojson',
      data: this.FeatureCollection,
      maxzoom: 24,
      cluster: this.IsMapClustered,
      clusterRadius: this.CLUSTER_RADIUS,
      clusterMaxZoom: this.CLUSTER_MAX_ZOOM,
      clusterProperties: {
        DevicesCount: ['+', ['get', 'DevicesCount']],
        DeviceIds: ['concat', ['concat', ['get', 'DeviceId'], ['string', ',']]],
        // keep separate counts for each device status
        Online: ['+', ['get', 'Online']],
        Offline: ['+', ['get', 'Offline']],
        CtrlOffline: ['+', ['get', 'CtrlOffline']],
        Info: ['+', ['get', 'Info']],
        Warning: ['+', ['get', 'Warning']],
        PanelOff: ['+', ['get', 'PanelOff']],
        // tmp: ['+', ['case', ['==', ['get', 'Status'], 5], 1, 0]],
      },
    };

    this.map.addSource(this.MAP_DATASOURCE_KEY, geoJson);
  }

  private addLayersToMap() {
    this.map.addLayer({
      id: this.MAP_LAYER_CLUSTERS,
      type: 'circle',
      source: this.MAP_DATASOURCE_KEY,
      // filter: ['!=', 'cluster', true],
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': 'black',
        'circle-opacity': 0,
        'circle-radius': 0,
      },
    });

    // circle and symbol layers for rendering individual earthquakes (unclustered points)
    this.map.addLayer({
      id: this.MAP_LAYER_CHANNEL,
      type: 'circle',
      source: this.MAP_DATASOURCE_KEY,
      // filter: ['!=', 'cluster', true],
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': 'black',
        'circle-opacity': 0,
        'circle-radius': 0,
      },
    });
    this.map.addLayer({
      id: this.MAP_LAYER_CHANNEL_LABEL,
      type: 'symbol',
      source: this.MAP_DATASOURCE_KEY,
      // filter: ['!=', 'cluster', true],
      filter: ['!', ['has', 'point_count']],
      layout: {
        'text-field': ['get', 'Name'],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-offset': [1.5, 0],
        'text-anchor': 'left',
        'text-justify': 'left',
      },
      paint: {
        'text-color': this.mapService.mapLabelColor,
      },
    });
  }

  private get IsMapClustered(): boolean {
    return this.devices.length !== 1 && this.mapMode === MapMode.profileMap;
  }

  private getFeatureIndex(id: string | number | undefined) {
    return this.srcGeoJsonFeatures.findIndex((feature) =>
      this.isEqualFeature(feature, id)
    );
  }

  isEqualFeature(
    feature: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>,
    featureId: string | number | undefined
  ): boolean {
    return feature.id === featureId;
  }
  // #endregion

  // #region COOrDINAtES AND GEOCODING

  /*
   ***********************************
   * Geographic coordinates management
   *
   * KB:
   * - Profile refers to a location
   * - Channels refer to a Profile
   * - Each channel refer to a location that can be different from Profile's one and from other channels'
   *   (so Channels refer to Location in a Many-to-One relationship)
   * - A user can switch the location connected to a Profile or to a Channel.
   *
   * Channels have their own coordinates field that is used to place their icon on the map
   * if Channel.coordinates is null then Location's coordinates field is used to place icon
   * if Location.coordinates is null {
   *    coordinates are geocode through Mapbox Geocoding Api using Location's address
   *    coordinates are then saved to Location for next time
   * }
   * if a user drag a channel icon around the map we save new coordinates to Channel
   * We do not save these coordinates to Channel.Location since same location could be
   * connected to other Channels
   *
   ************************************/

  async getLocationCoordsOrGeocode(
    location: ChannelLocationForMapFragment
  ): Promise<mapboxgl.LngLat> {
    let coordinates = new LngLat(
      location?.coordinates?.x || 0,
      location?.coordinates?.y || 0
    );

    if (!lngLatIsValid(coordinates)) {
      const cache = this.geoCodingCache.get(location.id);

      if (cache) {
        coordinates = cache;
      } else {
        const address: IAddress = {
          streetAddress1: location.streetAddress1 || 'Laxholmstorget 3',
          streetAddress2: location.streetAddress2 || '',
          zip: location.zip || '60221',
          city: location.city || 'Norrköping',
          region: location.region || '',
          country: (location.country && getCode(location.country)) || 'SE',
        };
        coordinates = await this.mapService.addressToLngLat(address);

        this.updateLocationCoords(location.id, location.name, coordinates);

        this.geoCodingCache.set(location.id, coordinates);
      }
    }
    return coordinates;
  }

  async getChannelCoordsOrGeocode(
    device: DeviceBaseFragment
  ): Promise<mapboxgl.LngLat> {
    let coordinates = new LngLat(
      device.coordinates?.x || 0,
      device.coordinates?.y || 0
    );

    if (!lngLatIsValid(coordinates)) {
      if (device.location?.id) {
        coordinates = await this.getLocationCoordsOrGeocode(device.location);
      }
    }

    return coordinates;
  }

  // #endregion

  // #region Custom Markers Management

  // places markers on map depending on zoom and clustering
  updateMarkers() {
    if (
      this.isDraggingMarker ||
      this.isExplodingCluster ||
      !this.map.isSourceLoaded
    ) {
      return;
    }

    const newMarkers = new Set<string>();
    const features = this.map.querySourceFeatures(this.MAP_DATASOURCE_KEY);

    // for every feature on the map, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already

    for (const feature of features) {
      if (!feature.properties) {
        continue;
      }
      const props = new FeatureProperties(feature.properties);
      if (newMarkers.has(props.id)) {
        continue;
      }

      const coords = (feature.geometry as GeoJSON.Point).coordinates;

      let mp = this.markersAndPopups.get(props.id);
      let pinned = false;

      if (mp && mp.statusKey !== props.StatusKey) {
        this.markersOnScreen.delete(props.id);
        pinned = mp.popupIsPinned;
        mp.marker.remove();
        mp.popup?.remove();
        mp = undefined;
      }
      if (!mp) {
        mp = this.createMarker(props, coords);
        mp.popupIsPinned = pinned;
      } else {
        const lngLat = this.mapService.coordsToLngLat(coords);
        mp.marker.setLngLat(lngLat);
        if (mp.popupIsPinned) {
          mp.popup?.setLngLat(lngLat);
        }
      }
      mp.marker.setLngLat(this.mapService.coordsToLngLat(coords));

      newMarkers.add(props.id);

      // if not already displayed the show it
      if (!this.markersOnScreen.has(props.id)) {
        mp.marker.addTo(this.map);

        if (mp.popup) {
          // check if the marker popup is pinned or not
          // ATTENTION: while zooming a cluster marker can change ID: use VariableClusters to check IDs
          const previousClusterOwner = props.isCluster
            ? this.variableClusters.getOldClusterId(props.containedDeviceIds)
            : '';

          if (previousClusterOwner) {
            this.markersAndPopups.movePopupPin(props.id, previousClusterOwner);
          }

          if (this.markersAndPopups.isPopupPinned(props.id)) {
            this.showPopup(props.id);
          }
        }
        if (props.isCluster) {
          this.variableClusters.saveClusterContent(
            props.containedDeviceIds,
            props.id
          );
        }
        // this line is probably the bug
        // this.markersOnScreen.add(props.id);
      }
    }

    // for every marker we've added previously, remove those that are no longer visible
    for (const id of Array.from(this.markersOnScreen)) {
      if (!newMarkers.has(id)) {
        // remove marker and popup for not visible markers
        const mp = this.markersAndPopups.get(id);
        mp?.marker.remove();
        mp?.popup?.remove();
      }
    }

    // save list of currently visible markers
    this.markersOnScreen = newMarkers;
  }

  // create a marker with an SVG chart indicating devices statuses and a popup table with a chart summary description
  private createMarker(props: FeatureProperties, coords: Position) {
    const element = this.createMarkerElement(props);

    const marker = element
      ? new mapboxgl.Marker({ element })
      : new mapboxgl.Marker();
    marker.setLngLat(this.mapService.coordsToLngLat(coords));

    let popup: mapboxgl.Popup | undefined;
    if (this.enablePopups && !props.isLocation) {
      popup = this.createPopup(props);
    }
    const ret = this.markersAndPopups.add(
      props.id,
      marker,
      popup,
      props.StatusKey
    );

    this.attachEvents(marker, props, coords);

    return ret;
  }

  attachEvents(
    marker: mapboxgl.Marker,
    props: FeatureProperties,
    coords: Position
  ) {
    const element = marker.getElement();
    element.style.cursor = 'pointer';

    if (props.isCluster) {
      element.ondblclick = (event) => {
        this.explodeCluster(props.id, coords);
      };
    }

    // mouse hovering
    let mouseTimeout: any;
    element.addEventListener('mouseenter', () => {
      clearTimeout(mouseTimeout);
      this.showPopup(props.id);
    });
    element.addEventListener('mouseleave', () => {
      mouseTimeout = setTimeout(() => this.hidePopup(props.id), 500);
    });

    element.addEventListener('click', () => {
      this.markersAndPopups.togglePopupPin(props.id);
      if (this.markersAndPopups.isPopupPinned(props.id)) {
        this.showPopup(props.id);
      } else {
        this.hidePopup(props.id);
      }
    });

    if (this.enableDrag && !props.isCluster) {
      marker.setDraggable(true);
      marker.on('mouseup', () => {
        this.isDraggingMarker = false;
      });
      marker.on('drag', () => {
        this.isDraggingMarker = true;
        this.hidePopup(props.id, true);
        this.updateFeatureCoords(marker, props.id);
      });
      marker.on('dragend', () => {
        this.isDraggingMarker = false;

        if (props.isLocation) {
          this.updateLocationCoords(props.id, props.name, marker.getLngLat());
        } else {
          this.updateDeviceCoords(props.id, marker.getLngLat());
        }
      });
    }
  }

  explodeCluster(id: string, coords: Position) {
    this.isExplodingCluster = true;
    this.mapService.explodeCluster(
      this.map,
      this.MAP_DATASOURCE_KEY,
      +id,
      coords
    );
    this.isExplodingCluster = false;
  }

  showPopup(id: string): any {
    if (this.isDraggingMarker || this.isExplodingCluster) return;
    const mp = this.markersAndPopups.get(id);
    if (mp && mp.popup) {
      mp.popup.setLngLat(mp.marker.getLngLat());
      mp.popup.addTo(this.map);
    }
  }

  hidePopup(id: string, forceUnpin = false): any {
    if (!forceUnpin && this.markersAndPopups.isPopupPinned(id)) return;

    const mp = this.markersAndPopups.get(id);
    if (mp?.popup) {
      mp.popup.remove();
      if (forceUnpin) {
        mp.popupIsPinned = false;
      }
    }
  }

  createMarkerElement(props: FeatureProperties): HTMLElement | null {
    // a location doesn't have a status
    if (!this.enableStatusIcons || this.mapMode === MapMode.locationMap) {
      return null;
    } else {
      return createSvgIcon(props);
    }
  }

  /// when dragging a marker update its related feature in map so the label will follow the mouse
  updateFeatureCoords(marker: mapboxgl.Marker, featureId: string) {
    const coords = marker.getLngLat();
    const idx = this.srcGeoJsonFeatures.findIndex((f) => f.id === featureId);
    this.srcGeoJsonFeatures[idx].geometry = {
      coordinates: [coords.lng, coords.lat],
      type: 'Point',
    };
    (this.map.getSource(this.MAP_DATASOURCE_KEY) as GeoJSONSource).setData(
      this.FeatureCollection
    );
  }

  // #region marker popup

  private createPopup(props: FeatureProperties): mapboxgl.Popup {
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      closeOnMove: false,
      offset: 25,
    });

    if (props.isCluster) {
      popup.setDOMContent(this.createPopupForCluster(props));
    } else {
      popup.setDOMContent(this.createPopupForChannel(props));
    }
    return popup;
  }

  createPopupForChannel(props: FeatureProperties): HTMLDivElement {
    const device = this.devices.find((x) => x.id === props.id);
    return this.dynamicComponentService.injectComponent(
      DevicePopupComponent,
      (x) => (x.device = device)
    );
  }

  private createPopupForCluster(props: FeatureProperties): HTMLDivElement {
    return this.dynamicComponentService.injectComponent(
      ClusterPopupComponent,
      (x) => (x.featureProperties = props)
    );
  }

  // #endregion

  // #region Update DB

  /// on dragend event save to db new position
  updateDeviceCoords(deviceId: string, coords: LngLat) {
    const oldLoading = this.loading;
    this.loading = true;
    this.loaderMessage = 'UPDATING_CHANNEL';

    this.subs.sink = this.updateDeviceCoordsGql
      .mutate({
        input: {
          deviceId,
          coordinates: {
            x: coords?.lng || 0,
            y: coords?.lat || 0,
          },
        },
      })
      .subscribe({
        next: ({ data }) => {
          if (
            data &&
            data.updateDeviceCoordinates.isSuccessful &&
            data.updateDeviceCoordinates.device &&
            data.updateDeviceCoordinates.device.id
          ) {
            // this.toasterService.success('UPDATE_CHANNEL_SUCCESS');
            const idx = this.devices.findIndex(
              (x) => x.id === data.updateDeviceCoordinates.device?.id
            );
            this.devices[idx].coordinates =
              data.updateDeviceCoordinates.device.coordinates;
          } else {
            this.toasterService.error('UNKNOWN_ERROR');
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('updateDeviceCoords', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });

          this.loading = oldLoading;
        },
      });
  }

  updateLocationCoords(id: string, name: string, coords: mapboxgl.LngLat) {
    // if coordinates is not valid do not update anything
    if (!lngLatIsValid(coords)) return;
    const oldLoading = this.loading;
    this.loading = true;
    this.loaderMessage = 'UPDATING_CHANNEL';

    this.subs.sink = this.updateLocationGQL
      .mutate({
        input: {
          id,
          name: name ?? '',
          coordinates: {
            x: coords?.lng || 0,
            y: coords?.lat || 0,
          },
        },
      })
      .subscribe({
        next: ({ data }) => {
          if (
            !(
              data &&
              data.updateLocation.isSuccessful &&
              data.updateLocation.location &&
              data.updateLocation.location.id
            )
          ) {
            this.toasterService.error('UNKNOWN_ERROR');
          }

          this.loading = oldLoading;
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('updateLocationCoords', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });

          this.loading = oldLoading;
        },
      });
  }
  // #endregion

  publishCoordinates(id: string, coordinates: ICoordinates) {
    this.mapEventPublisher.publishOnMapDragEndArgs({
      entityId: id,
      coords: coordinates,
      entityType: MapEventEntityTypes.LOCATION,
      EventName: EventNames.ON_MAP_DRAG_END,
      EventSource: EventSources.WATCHTOWER,
    });
  }
}
