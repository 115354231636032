import { Component, EventEmitter, Input, Output } from '@angular/core';
import { timeStringToStruct, timeStructToString } from '@desquare/utils';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'designage-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
})
export class TimepickerComponent {
  _minTime = '';
  @Input() set minTime(value: string) {
    this._minTime = value;
    if (this._minTime !== '') {
      if (this.time < this._minTime) {
        const newTimeStruct = timeStringToStruct(this._minTime);
        newTimeStruct.hour = newTimeStruct.hour + 1;
        this.time = timeStructToString(newTimeStruct, this.showSecond);
      }
    }
  }
  get minTime() {
    return this._minTime;
  }
  @Input()
  set time(value: string) {
    this._time = value ? value : this.showSecond ? '00:00:00' : '00:00';
    this.pickerTime = timeStringToStruct(this._time);
  }
  get time() {
    return this._time;
  }
  @Output() timeChange = new EventEmitter<string>();

  @Input() asInputField = true;
  @Input() showSecond = true;
  @Input() hourStep = 1;
  @Input() minuteStep = 1;
  @Input() secondStep = 1;

  pickerTime!: NgbTimeStruct;
  _time!: string;

  constructor() {}

  getFormattedMinTime() {
    if (this.minTime === '') return undefined;
    const timeStruct = timeStringToStruct(this.minTime);

    return timeStructToString(timeStruct, this.showSecond);
  }
  getDisplayTime() {
    // HH:mm or HH:mm:ss

    return timeStructToString(this.pickerTime, this.showSecond);
  }

  onTimeChange(open: boolean) {
    const minTime = this.getFormattedMinTime();
    let newTime = this.getDisplayTime();
    if (!open && this.time !== newTime) {
      if (minTime && minTime !== '' && newTime < minTime) {
        newTime = minTime;
        this.pickerTime = timeStringToStruct(newTime);
      }
      this.timeChange.emit(newTime);
    }
  }

  onSetTime() {
    const newTime = this.getDisplayTime();
    if (this.time !== newTime) {
      console.log('newTime', newTime);
      this.timeChange.emit(newTime);
    }
  }
  popoverShown() {
    console.log('popoverShown');
  }
}
