import { FeatureProperties, DeviceStatusInfo } from '@desquare/models';

export function createSvgIcon(props: FeatureProperties): HTMLElement {
  if (props.channelsCount > 1 || props.devicesCount > 1) {
    return createSvgDonutChart(props);
  } else {
    return createSvgSingleStatusIcon(props);
  }
}

export function createSvgSingleStatusIcon(
  props: FeatureProperties
): HTMLElement {
  const radius = 13;
  const border = radius + 2;

  const w = border * 2;
  const status = props.getSingleStatus();
  let html = `<svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: 10px sans-serif; display: block">
       <circle cx="${border}" cy="${border}" r="${border}" fill="white" />
       <circle cx="${border}" cy="${border}" r="${radius}" fill="${status.Color}" />
      </svg>`;

  const element = document.createElement('div');
  element.innerHTML = html;
  return element;
}

export function createSvgDonutChart(props: FeatureProperties): HTMLElement {
  const offsets = [];
  //some channels could not have a device, the fall back status is offline
  let offlineChannels = props.channelsCount - props.devicesCount;
  offlineChannels = offlineChannels < 0 ? 0 : offlineChannels;
  const counts = props.getStatusArray();
  let devicesCount = 0;

  for (const perStatusCount of counts) {
    offsets.push(devicesCount);
    devicesCount += perStatusCount;
  }

  // a cluster icon will show the amount of channels
  // a channel icon will show the amount of devices (if is a multi device channel)
  const total = props.isCluster ? props.channelsCount : props.devicesCount;
  const label = total.toString();
  const fontSize = total >= 100 ? 22 : total >= 50 ? 20 : total >= 10 ? 18 : 16;
  const r = total >= 100 ? 50 : total >= 50 ? 32 : total >= 10 ? 24 : 18;
  const r0 = Math.round(r * 0.6);
  const w = r * 2;

  let html = `<svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

  for (let i = 0; i < counts.length; i++) {
    html += createSvgDonutSegment(
      offsets[i] / total,
      (offsets[i] + counts[i]) / total,
      r,
      r0,
      DeviceStatusInfo.StatusColors[i]
    );
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
       <text dominant-baseline="central" transform="translate(${r}, ${r})">${label}</text>
    </svg>`;

  // html = '<a href="#">' + html + '</a>';
  const element = document.createElement('div');
  element.innerHTML = html;
  return element;
}

export function createSvgDonutSegment(
  start: number,
  end: number,
  r: number,
  r0: number,
  color: string
) {
  if (end - start === 1) {
    end -= 0.00001;
  }
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0);
  const y0 = Math.sin(a0);
  const x1 = Math.cos(a1);
  const y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  return [
    '<path d="M',
    r + r0 * x0,
    r + r0 * y0,
    'L',
    r + r * x0,
    r + r * y0,
    'A',
    r,
    r,
    0,
    largeArc,
    1,
    r + r * x1,
    r + r * y1,
    'L',
    r + r0 * x1,
    r + r0 * y1,
    'A',
    r0,
    r0,
    0,
    largeArc,
    0,
    r + r0 * x0,
    r + r0 * y0,
    '" fill="' + color + '" />',
  ].join(' ');
}
