import { MapBoxZoomEvent } from 'mapbox-gl';

interface IMarker {
  id: string;
  marker: mapboxgl.Marker;
  popup: mapboxgl.Popup | undefined;
  popupIsPinned: boolean;
  statusKey: string;
}

export class MarkerAndPopups {
  markers = new Map<string, IMarker>();

  constructor() {}

  add(id: string, marker: mapboxgl.Marker, popup: mapboxgl.Popup | undefined, statusKey: string) {
    const mp: IMarker = {
      id,
      marker,
      popup,
      popupIsPinned: false,
      statusKey,
    };
    this.markers.set(id, mp);
    return mp;
  }

  get(id: string) {
    return this.markers.get(id);
  }

  clear() {
    this.markers.clear();
  }

  getkeys() {
    return Array.from(this.markers.keys());
  }

  getMarker(id: string) {
    const m = this.markers.get(id);
    if (m) {
      return m.marker;
    }
    return undefined;
  }
  getPopup(id: string): mapboxgl.Popup | undefined {
    const m = this.markers.get(id);
    if (m) {
      return m.popup;
    }
    return undefined;
  }
  isPopupPinned(id: string): boolean {
    const m = this.markers.get(id);
    if (m) {
      return m.popupIsPinned;
    }
    return false;
  }

  pinPopup(id: string, skipTest: boolean = false) {
    const m = this.markers.get(id);
    if (m) {
      m.popupIsPinned = true;
    }
  }
  unpinPopup(id: string, skipTest: boolean = false) {
    const m = this.markers.get(id);
    if (m) {
      m.popupIsPinned = false;
    }
  }
  togglePopupPin(id: string) {
    const m = this.markers.get(id);
    if (m) {
      m.popupIsPinned = !m.popupIsPinned;
    }
  }

  //clusters change their id but we can recognize them analyzing their content
  // then we can keep open their popups
  movePopupPin(newClusterId: string, oldClusterId: string) {
    const src = this.markers.get(oldClusterId);
    const dst = this.markers.get(newClusterId);
    if (src != undefined && dst != undefined) {
      dst.popupIsPinned = src.popupIsPinned;
      src.popupIsPinned = false;
    }
  }
}
