import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateFolderInput } from '@designage/gql';
import { CurrentUserService, FolderService } from '@desquare/services';

@Component({
  selector: 'app-create-folder-dialog',
  templateUrl: './create-folder-dialog.component.html',
  styleUrls: ['./create-folder-dialog.component.scss'],
})
export class CreateFolderDialogComponent implements OnInit {
  // createFolderInput?: CreateFolderInput;
  loading = false;
  loaderMessage?: string;

  @Input() parentFolderId?: string;

  get profileId() {
    return this.currentUserService.currentProfile?.id;
  }

  // form variables
  folderForm!: FormGroup;
  get isFormValid() {
    return this.folderForm.valid;
  }
  get isFormPristine() {
    return this.folderForm.pristine;
  }
  get createFolderInput(): CreateFolderInput {
    return this.folderForm.value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    // TODO: refactor later, remove unnecessary fields, only name is used
    this.folderForm = this.formBuilder.group({
      profileId: [this.profileId || null, Validators.required],
      name: [null, Validators.required],
      isPublic: true,
      parentFolderId: this.parentFolderId,
    });
  }
}
