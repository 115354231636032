<i
  *ngIf="!button"
  ngxClipboard
  [cbContent]="cbcontent"
  role="button"
  class="ri-file-copy-2-line pe-auto px-1 float-end copy-button"
  ngbTooltip="Copy value"
  (click)="copyToaster(cbcontent)"
></i>
<button
  *ngIf="button"
  ngxClipboard
  [cbContent]="cbcontent"
  role="button"
  class="mx-1 float-end btn btn-sm btn-outline-success"
  ngbTooltip="Copy value"
  (click)="copyToaster(cbcontent)"
>
  Copy value
</button>
