<div
  *ngIf="componentReady"
  class="card text-white shadowed-box"
  [ngClass]="isSelected !== -1 ? 'device-selected' : ''"
>
  <img
    #screenshotEl
    class="card-img-top screenshot"
    [src]="screenshotUri"
    [alt]="'DEVICE_SCREENSHOT' | translate"
    (click)="channelDetailsToggle()"
    (error)="screenshotError()"
  />
  <!-- (mousedown)="startStopLiveScreenshots()" -->
  <div class="card-img-overlay device-name">
    <h5 class="card-title" [title]="device?.name || ('NO_DATA' | translate)">
      {{ device?.name || 'NO_DATA' | translate | uppercase }}
    </h5>
  </div>

  <ul class="list-group list-group-flush">
    <li
      class="list-group-item-status"
      [class.bg-not-installed]="isNotInstalled"
      [ngClass]="getStatusColor"
    >
      <div class="h-100 d-flex align-items-center justify-content-evenly">
        <div
          *ngIf="!isNotInstalled"
          class="fs-5 pointer"
          ngbTooltip="{{ 'CHANNEL_DETAILS' | translate }}"
          (click)="channelDetailsToggle()"
        >
          <i
            [ngClass]="
              isSelected !== -1
                ? 'ri-information-fill border border-white rounded-circle'
                : 'ri-information-line'
            "
          ></i>
        </div>

        <div
          *ngIf="!isOffline && !isNotInstalled"
          class="fs-5 pointer"
          ngbTooltip="{{ 'RELOAD_SCREENSHOT' | translate }}"
          (click)="updateScreenshot()"
        >
          <i
            [ngClass]="
              loader
                ? 'spinner-border spinner-border-sm mx-1'
                : 'ri-camera-switch-fill'
            "
          ></i>
        </div>

        <div
          class="fs-5 pointer"
          [ngbPopover]="popDeviceCommands"
          animated="true"
          container="body"
          [autoClose]="'outer'"
          placement="bottom auto"
        >
          <i class="ri-settings-3-fill"></i>
        </div>

        <div
          *ngIf="showConnectedLinks()"
          class="fs-5 pointer"
          (shown)="getConnectedLinks()"
          [ngbTooltip]="'CONNECTED_CHANNEL' | translate"
          [ngbPopover]="popConnectedChannel"
          animated="true"
          container="body"
          [autoClose]="'outer'"
          placement="end bottom auto"
        >
          <i class="ri-tv-line"></i>
        </div>
        <div
          *ngIf="showConnectedLinks()"
          class="fs-5 pointer"
          (shown)="getConnectedLinks()"
          [ngbTooltip]="'CONNECTED_PLAYLISTS' | translate"
          [ngbPopover]="popConnectedPlaylists"
          animated="true"
          container="body"
          [autoClose]="'outer'"
          placement="end bottom auto"
        >
          <i class="ri-play-list-2-fill"></i>
        </div>

        <ng-template #popDeviceCommands>
          <app-device-commands
            [device]="device"
            layout="expanded"
            [options]="deviceCommandsOptions()"
            [channelId]="device.channelId"
            [screenStatus]="device.status"
          ></app-device-commands>
        </ng-template>

        <ng-template #popConnectedChannel>
          <app-device-connected-links
            [links]="connectedLinks()"
            [connection]="'channel'"
          ></app-device-connected-links>
        </ng-template>
        <ng-template #popConnectedPlaylists>
          <app-device-connected-links
            [connection]="'playlists'"
            [links]="connectedLinks()"
          ></app-device-connected-links>
        </ng-template>
      </div>
    </li>

    <div class="d-flex flex-column">
      <div class="w-100 d-flex">
        <p class="col-6 text-truncate">
          {{ 'STATUS' | translate }}
        </p>
        <p class="col-6 text-truncate">
          {{ status.StatusLabel }}
        </p>
      </div>
      <ng-container
        *ngFor="let deviceData of selectedDeviceData$ | async"
        class="w-100 d-flex"
        [ngSwitch]="deviceData.name"
      >
        <!-- If Time -->
        <ng-container *ngSwitchCase="'DEVICE_TIME'">
          <ng-container *ngIf="deviceData.visible">
            <div class="w-100 d-flex">
              <p class="col-6 text-truncate">
                {{ deviceData.name | translate }}
              </p>
              <p class="col-6 text-truncate">
                {{
                  this.timestamp
                    | date : 'shortTime' : undefined : device.timezoneOffset
                }}
                <!-- {{ getInnerObjects(deviceData.prop) | date : 'shortTime' }} -->
              </p>
            </div>
          </ng-container>
        </ng-container>

        <!-- If Date -->
        <ng-container *ngSwitchCase="'DEVICE_DATE'">
          <ng-container *ngIf="deviceData.visible">
            <div class="w-100 d-flex">
              <p class="col-6 text-truncate">
                {{ deviceData.name | translate }}
              </p>
              <p class="col-6 text-truncate">
                {{ getInnerObjects(deviceData.prop) | date : 'mediumDate' }}
              </p>
            </div>
          </ng-container>
        </ng-container>

        <!-- If Volume -->
        <ng-container *ngSwitchCase="'DEVICE_VOLUME'">
          <ng-container
            *ngIf="deviceData.visible && getInnerObjects(deviceData.prop) >= 0"
          >
            <div class="w-100 d-flex">
              <p class="col-6 text-truncate">
                {{ deviceData.name | translate }}
              </p>
              <p class="col-6 text-truncate">
                {{ getInnerObjects(deviceData.prop) }}%
              </p>
            </div>
          </ng-container>
        </ng-container>

        <!-- If Ping -->
        <ng-container *ngSwitchCase="'DEVICE_TIMESTAMP'">
          <ng-container *ngIf="deviceData.visible">
            <div class="w-100 d-flex">
              <p class="col-6 text-truncate">
                {{
                  status.StatusLabel === 'Offline'
                    ? ('OFFLINE_FOR' | translate)
                    : ('PING' | translate)
                }}
              </p>
              <p
                class="col-6 text-truncate"
                ngbTooltip="{{
                  timestamp
                    | date : 'medium' : undefined : device.timezoneOffset
                }}"
              >
                {{ timestamp | amTimeAgo }}
              </p>
            </div>
          </ng-container>
        </ng-container>

        <!-- If Network -->
        <ng-container *ngSwitchCase="'IP'">
          <ng-container *ngIf="deviceData.visible">
            <div class="w-100 d-flex">
              <p class="col-6 text-truncate">
                {{ 'NETWORK_ACTIVE_INTERFACE' | translate }}
              </p>
              <p class="col-6 d-flex">
                {{ networkAdapterType | titlecase }}

                <wifi-strength-indicator
                  class="ps-2"
                  *ngIf="networkAdapterType === 'wifi'"
                  [signal]="networkWifiStrength"
                ></wifi-strength-indicator>
              </p>
            </div>
            <div class="w-100 d-flex">
              <p class="col-6 text-truncate">
                {{ deviceData.name | translate }}
              </p>
              <p class="col-6 text-truncate">
                {{ networkAdapterIP }}
              </p>
            </div>
          </ng-container>
        </ng-container>

        <!-- All others -->
        <ng-container *ngSwitchDefault>
          <ng-container #genericDeviceData *ngIf="deviceData.visible">
            <div class="w-100 d-flex">
              <p class="col-6 text-truncate">
                {{ deviceData.name | translate }}
              </p>
              <p class="col-6 text-truncate">
                {{ getInnerObjects(deviceData.prop) }}
              </p>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ul>
</div>
