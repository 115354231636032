<h5>
  {{ 'THEMES' | translate }}:
  <i
    (click)="switchTheme(themes.LIGHT)"
    class="ri-contrast-line theme-icon"
    [class.bg-secondary]="currentTheme === themes.LIGHT"
  ></i>
  <i
    (click)="switchTheme(themes.DARK)"
    class="ri-contrast-fill theme-icon"
    [class.bg-secondary]="currentTheme === themes.DARK"
  ></i>
</h5>
