import { Component, OnInit } from '@angular/core';
import { FolderService } from '@desquare/services';

@Component({
  selector: 'move-media-folder-dialog',
  templateUrl: './move-media-folder-dialog.component.html',
  styleUrls: ['./move-media-folder-dialog.component.scss'],
})
export class MoveMediaFolderDialogComponent implements OnInit {
  selectedFolderId: string | null = null;
  selectedMedia: string[] = [];
  // MoveType: ''

  constructor() {}

  ngOnInit(): void {
    console.log('selectedMedia', this.selectedMedia);
  }
}
