import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Maybe } from 'graphql/jsutils/Maybe';

interface IDropdownItem {
  id: number;
  name: string;
}

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent {
  @Input() items: IDropdownItem[] = [];
  @Input() selectedItem!: IDropdownItem;
  @Input() loading = true;
  @Input() nullMessagePrompt: Maybe<string>;
  @Input() label: Maybe<string>;
  @Input() displayShowAllButton = true;
  @Input() maxThreshold = 10;
  @Input() options: string[] = [];

  @Output() selectedItemChanges = new EventEmitter<number>();
  @Output() showAllTrigger = new EventEmitter();

  get displayableItems() {
    return this.displayShowAllButton
      ? this.items.filter((item, index) => index < this.maxThreshold)
      : this.items;
  }

  constructor() {}

  itemClick(index: number) {
    this.selectedItem = this.items[index];
    this.selectedItemChanges.emit(index);
  }

  showAllClick() {
    this.showAllTrigger.emit();
  }
}
