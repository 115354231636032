<!-- [appRecalculateDataTableHeight]="rows" - directive for recalculating height 
  this is disabled when 'undefined' is passed -->
<div
  #tableWrapper
  id="tableWrapper"
  class="h-100"
  [appRecalculateDataTableHeight]="
    virtualScrollAutoShrinkHeight && virtualScroll ? rows : undefined
  "
  [count]="count"
>
  <ngx-datatable
    #table
    class="bootstrap"
    [class.row-pointer]="useRowSelection"
    [class.virtualScrollDatatable]="virtualScroll"
    [rows]="rows"
    [columns]="columns"
    [columnMode]="ColumnMode.force"
    [selected]="selected"
    [headerHeight]="headerHeight"
    [footerHeight]="50"
    [limit]="virtualScroll ? undefined : limit"
    [count]="count"
    [loadingIndicator]="loading"
    [selectionType]="selectionType"
    [externalPaging]="virtualScroll ? undefined : externalPaging"
    [rowClass]="rowClass"
    [offset]="virtualScroll ? undefined : pageOffset"
    [sorts]="sorts"
    (sort)="onSort($event)"
    (page)="virtualScroll ? undefined : onPageChange($event)"
    (activate)="onRowActivate($event)"
    [rowHeight]="virtualScroll ? rowHeight : 'auto'"
    headerCheckboxable="true"
    [scrollbarV]="virtualScroll"
  >
    <ngx-datatable-column
      *ngIf="showCheckbox"
      [width]="40"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <!-- Template for header checkbox -->
      <ng-template ngx-datatable-header-template>
        <!-- <div class="selection" (click)="onSelectAll(!allRowsSelected)"> -->
        <div
          class="selection cursor-pointer"
          (click)="onSelectAll(!allRowsSelected)"
        >
          <i *ngIf="!allRowsSelected" class="ri-checkbox-blank-line"></i>
          <i *ngIf="allRowsSelected" class="ri-checkbox-line selected"></i>
        </div>
      </ng-template>

      <ng-template ngx-datatable-cell-template let-row="row">
        <div *ngIf="enableSelectionActionButtons" class="selection">
          <i *ngIf="!isSelected(row)" class="ri-checkbox-blank-line"></i>
          <i *ngIf="isSelected(row)" class="ri-checkbox-line selected"></i>
        </div>

        <div
          *ngIf="!enableSelectionActionButtons"
          class="selection pointer"
          (click)="updateSelectedItem(row)"
        >
          <i *ngIf="!isSelected(row)" class="ri-checkbox-blank-line"></i>
          <i *ngIf="isSelected(row)" class="ri-checkbox-line selected"></i>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      *ngIf="showChannelStatus"
      [width]="40"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template ngx-datatable-cell-template let-row="row">
        <span
          class="status-indicator col-status"
          [style.background-color]="row.deviceStatus.Color"
          ngbTooltip="{{ row.deviceStatus.StatusLabel | translate }}"
        ></span>
      </ng-template>
    </ngx-datatable-column>
    <ng-container *ngFor="let column of columns">
      <ngx-datatable-column
        [name]="column.name | translate"
        [prop]="column.prop"
        [width]="column.width"
        [minWidth]="column.minWidth"
        [maxWidth]="column.maxWidth"
        [cellClass]="column.cellClass"
        [headerClass]="column.headerClass"
      >
        <ng-template
          let-row="row"
          let-rowIndex="rowIndex"
          let-value="value"
          let-isSelected="isSelected"
          ngx-datatable-cell-template
        >
          <div [ngSwitch]="column.prop">
            <div *ngSwitchCase="'createdAt'">
              {{ value | date : 'medium' }}
            </div>
            <div *ngSwitchCase="'updatedAt'">
              {{ value | date : 'medium' }}
            </div>
            <div *ngSwitchCase="'readableType'">
              {{ value | titlecase }}
            </div>
            <div *ngSwitchCase="'active'">
              <span class="badge bg-success" *ngIf="value">{{
                'ACTIVE' | translate
              }}</span>
              <span class="badge" *ngIf="!value">{{
                'DEACTIVATED' | translate
              }}</span>
            </div>
            <div *ngSwitchCase="'isScheduled'">
              <span class="badge bg-success" *ngIf="value">{{
                'SCHEDULED' | translate
              }}</span>
              <span class="badge" *ngIf="!value">{{
                'NOT_SCHEDULED' | translate
              }}</span>
            </div>
            <!-- TMP -->
            <div
              *ngSwitchCase="'previewContent'"
              class="p-1 previewContent"
              [ngbPopover]="popThumbnailContent"
              [popoverTitle]="row.name"
              triggers="mouseenter:mouseleave"
              container="body"
              placement="end"
            >
              <img
                [src]="previewContentTransformer(row.previewContent)"
                alt=""
              />
            </div>
            <ng-template #popThumbnailContent>
              <div class="p-1 previewContent">
                <img
                  [src]="previewContentTransformer(row.previewContent, true)"
                  alt=""
                />
              </div>
            </ng-template>
            <!-- TMP -->
            <div *ngSwitchCase="'publicId'" class="text-center">
              <ng-container [ngSwitch]="row.type">
                <div [id]="row.id">
                  <cl-media
                    *ngSwitchCase="'IMAGE'"
                    ngbTooltip="{{ title | translate }}"
                    class="thumbnail cl-media-image"
                    [public-id]="value"
                    mediaType="image"
                    loading="lazy"
                    width="auto"
                    height="120"
                    resizeMethod="limit"
                    quality="auto"
                    fetch-format="avif"
                  ></cl-media>

                  <!-- <cl-image
                    loading="lazy"
                    *ngSwitchCase="'IMAGE'"
                    [public-id]="value"
                    class="thumbnail"
                  >
                    <cl-placeholder type="”blur”"></cl-placeholder>
                    <cl-transformation
                      width="auto"
                      height="120"
                      crop="limit"
                      quality="auto"
                      fetch-format="auto"
                    ></cl-transformation>
                  </cl-image> -->
                  <cl-media
                    *ngSwitchCase="'VIDEO'"
                    ngbTooltip="{{ title | translate }}"
                    class="thumbnail cl-media-video"
                    [public-id]="value"
                    mediaType="videoPoster"
                    loading="lazy"
                    width="auto"
                    height="120"
                    resizeMethod="limit"
                    quality="auto"
                    fetch-format="avif"
                  ></cl-media>
                  <!-- <cl-video
                    *ngSwitchCase="'VIDEO'"
                    [public-id]="value + '.webp'"
                    resource-type="video"
                    class="thumbnail"
                  >
                    <cl-transformation
                      width="auto"
                      height="120"
                      crop="limit"
                      quality="auto"
                      fetch-format="auto"
                    ></cl-transformation>
                  </cl-video> -->

                  <img
                    *ngSwitchDefault
                    [src]="placeHolderImage"
                    class="asset-img"
                  />
                </div>
              </ng-container>
            </div>
            <div *ngSwitchCase="'metadata.bytes'">
              {{ value | fileSize }}
            </div>
            <div
              *ngSwitchCase="'downloadLink'"
              (click)="$event.stopPropagation()"
              class="text-center"
            >
              <a *ngIf="value" [href]="value">
                <i class="mdi mdi-download"></i>
                {{ 'DOWNLOAD' | translate }}
              </a>
              <p *ngIf="!value">
                {{ 'DOWNLOAD_LINK_UNAVAILABLE' | translate }}
              </p>
            </div>
            <div *ngSwitchCase="'duration'" class="text-center">
              {{ value | duration }}
            </div>
            <div *ngSwitchCase="'playlists.length'">
              {{ value }}
              <i
                class="ms-1 mdi mdi-eye eye-icon"
                (click)="$event.stopPropagation(); onColumnSelect(row, $event)"
              ></i>
            </div>

            <div *ngSwitchCase="'profiles.total'">
              {{ value }}
              <i
                class="ms-1 mdi mdi-eye eye-icon"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  onColumnSelect(row, $event)
                "
              ></i>
            </div>
            <div *ngSwitchCase="'channels.length'">
              {{ value }}
              <i
                class="ms-1 mdi mdi-eye eye-icon"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  onColumnSelect(row, $event)
                "
              ></i>
            </div>
            <div *ngSwitchCase="'devices.length'">
              {{ 'DEVICE_COUNT' | translate : { count: value } }}
            </div>
            <div *ngSwitchCase="'status'">
              <span class="badge bg-warning" *ngIf="value === 'DRAFT'">{{
                value | translate
              }}</span>
              <span
                class="badge bg-warning"
                *ngIf="value === 'READY_TO_PUBLISH'"
                >{{ value | translate }}</span
              >
              <span class="badge bg-primary" *ngIf="value === 'PUBLISHED'">{{
                value | translate
              }}</span>
              <span class="badge bg-danger" *ngIf="value === 'NOT_SAVED'">{{
                value | translate
              }}</span>
              <span class="badge bg-secondary" *ngIf="value === 'ARCHIVED'">{{
                value | translate
              }}</span>
              <span class="badge bg-success" *ngIf="value === 'ACTIVE'">{{
                value | translate
              }}</span>
              <span class="badge" *ngIf="value === 'DEPRECATED'">{{
                value | translate
              }}</span>
            </div>
            <div *ngSwitchCase="'screenStatus'">
              <div *ngIf="value === 'UPDATING'">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ row.actionMessage | translate }}
              </div>
              <div *ngIf="value === 'NOT_AVAILABLE'">
                {{ 'NO_SCREEN' | translate }}
              </div>
              <div *ngIf="value === 'OFFLINE'">
                {{ 'DEVICE_OFFLINE' | translate }}
              </div>
              <div
                *ngIf="value === 'DISPLAY_ON' || value === 'DISPLAY_OFF'"
                class="btn-group"
                data-toggle="buttons"
              >
                <input
                  class="btn-check"
                  type="radio"
                  name="channelDisplays"
                  id="displayOn"
                  autocomplete="off"
                  [checked]="'DISPLAY_ON'"
                  [disabled]="
                    !isOnline(row) ||
                    isActionInProgress(row) ||
                    value === 'DISPLAY_ON'
                  "
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    channelActionClick(row, 'DISPLAY_ON')
                  "
                />
                <label
                  for="displayOn"
                  class="btn btn-sm text-white"
                  [class.btn-outline-success]="value === 'DISPLAY_ON'"
                  [class.active]="value === 'DISPLAY_ON'"
                  [class.btn-outline-secondary]="value === 'DISPLAY_OFF'"
                  ngbTooltip="Display ON"
                >
                  {{ 'ON' | translate }}
                </label>
                <input
                  class="btn-check"
                  type="radio"
                  name="channelDisplays"
                  id="displayOff"
                  autocomplete="off"
                  [disabled]="
                    !isOnline(row) ||
                    isActionInProgress(row) ||
                    value === 'DISPLAY_OFF'
                  "
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    channelActionClick(row, 'DISPLAY_OFF')
                  "
                />
                <label
                  for="displayOff"
                  class="btn btn-sm text-white"
                  [class.btn-outline-danger]="value === 'DISPLAY_OFF'"
                  [class.active]="value === 'DISPLAY_OFF'"
                  [class.btn-outline-secondary]="value === 'DISPLAY_ON'"
                  ngbTooltip="Display OFF"
                >
                  {{ 'OFF' | translate }}
                </label>
              </div>
            </div>
            <div *ngSwitchCase="'appVersionStatus'">
              <div *ngIf="getAppVersionStatus(value) === 'UPDATING'">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ row.actionMessage | translate }}
              </div>
              <div *ngIf="getAppVersionStatus(value) !== 'UPDATING'">
                <span
                  class="badge"
                  [class.bg-warning]="getAppVersionStatus(value) === 'OUTDATED'"
                  [class.bg-success]="getAppVersionStatus(value) === 'UPDATED'"
                  [class.bg-secondary]="
                    getAppVersionStatus(value) === 'NOT_AVAILABLE'
                  "
                >
                  {{ getAppVersion(value) | translate }}
                </span>
                <i
                  *ngIf="
                    getAppVersionStatus(value) === 'OUTDATED' && isOnline(row)
                  "
                  class="ps-1 ri-error-warning-line"
                  placement="end"
                  container="body"
                  [ngbPopover]="popContent"
                ></i>
              </div>
            </div>
            <!-- <div *ngSwitchCase="'canRead'">
              <div class="selection pointer" (click)="updateMe(row)">
                <i *ngIf="!value" class="ri-checkbox-blank-line"></i>
                <i *ngIf="value" class="ri-checkbox-line selected"></i>
              </div>
            </div>
            <div *ngSwitchCase="'canCreate'">
              <div class="selection pointer" (click)="updateMe(row)">
                <i *ngIf="!value" class="ri-checkbox-blank-line"></i>
                <i *ngIf="value" class="ri-checkbox-line selected"></i>
              </div>
            </div>
            <div *ngSwitchCase="'canEdit'">
              <div class="selection pointer" (click)="updateMe(row)">
                <i *ngIf="!value" class="ri-checkbox-blank-line"></i>
                <i *ngIf="value" class="ri-checkbox-line selected"></i>
              </div>
            </div>
            <div *ngSwitchCase="'canDelete'">
              <div class="selection pointer" (click)="updateMe(row)">
                <i *ngIf="!value" class="ri-checkbox-blank-line"></i>
                <i *ngIf="value" class="ri-checkbox-line selected"></i>
              </div>
            </div> -->
            <div *ngSwitchDefault>
              {{ value }}
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ng-container>

    <ngx-datatable-column
      *ngIf="enableActionButtons"
      [width]="120"
      [sortable]="false"
      [draggable]="false"
      [resizeable]="false"
      [canAutoResize]="false"
    >
      <ng-template ngx-datatable-cell-template let-row="row">
        <ng-container *ngIf="enableSelectionActionButtons">
          <button
            type="button"
            *ngIf="isSelected(row)"
            class="float-end btn btn-sm btn-block btn-outline-danger"
            (click)="
              $event.preventDefault();
              $event.stopPropagation();
              updateSelectedItem(row)
            "
          >
            {{ deselectActionButtonText | translate }}
          </button>
          <button
            type="button"
            *ngIf="!isSelected(row)"
            class="float-end btn btn-sm btn-block btn-outline-primary"
            (click)="
              $event.preventDefault();
              $event.stopPropagation();
              updateSelectedItem(row)
            "
          >
            {{ selectActionButtonText | translate }}
          </button>
        </ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="enableAddActionButton"
      [width]="120"
      [sortable]="false"
      [draggable]="false"
      [resizeable]="false"
      [canAutoResize]="false"
    >
      <ng-template ngx-datatable-cell-template let-row="row">
        <ng-container *ngIf="enableAddActionButton">
          <button
            type="button"
            (click)="
              $event.preventDefault();
              $event.stopPropagation();
              onAddButtonClick(row)
            "
            class="float-end btn btn-sm btn-block btn-outline-primary"
          >
            {{ addActionButtonText | translate }}
          </button>
        </ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="enableChannelActions"
      [name]="'CONTROL' | translate"
      [width]="150"
      [sortable]="false"
      [draggable]="false"
      [resizeable]="false"
      [canAutoResize]="false"
    >
      <ng-template ngx-datatable-cell-template let-row="row">
        <div *ngIf="isActionInProgress(row) && row.controlActionMessage">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ row.controlActionMessage | translate }}
        </div>
        <div *ngIf="!row.controlActionMessage">
          <div class="btn-group">
            <!-- <button
              *ngIf="hasDevice(row)"
              type="button"
              class="btn btn-sm btn-outline-warning"
              ngbTooltip="{{ 'CLEAR_DEVICE_CACHE' | translate }}"
              [disabled]="!isOnline(row) || isActionInProgress(row)"
              (click)="
                $event.preventDefault();
                $event.stopPropagation();
                channelActionClick(row, 'CLEAR_DEVICE_CACHE')
              "
            >
              <i class="ri-brush-3-line"></i>
            </button> -->
            <button
              *ngIf="!hasDevice(row)"
              type="button"
              class="btn btn-sm btn-outline-success"
              ngbTooltip="{{ 'ADD_SCREEN' | translate }}"
              [disabled]="isActionInProgress(row)"
              (click)="
                $event.preventDefault();
                $event.stopPropagation();
                channelActionClick(row, 'ADD_SCREEN')
              "
            >
              <i class="ri-tv-line"></i>
            </button>

            <button
              *ngIf="row.devices.length > 0"
              type="button"
              class="btn btn-sm btn-outline-warning"
              [ngbPopover]="popDeviceCommands"
              animated="true"
              placement="auto"
              container="body"
              autoClose="outside"
            >
              <i class="ri-settings-3-line"></i>
            </button>

            <ng-template #popDeviceCommands>
              <app-device-commands
                [device]="row.devices[0]"
                layout="expanded"
                [options]="{
                  controls: {
                    reboot: {
                      disable: !isOnline(row) || isActionInProgress(row)
                    },
                    reloadApplet: {
                      disable: isActionInProgress(row)
                    },
                    updateApplet: {
                      disable:
                        isActionInProgress(row) ||
                        row.devices[0].signageOSDeviceTiming?.appletVersion ===
                          row.devices[0].signageOSDeviceTiming
                            ?.activeAppletVersion
                    },
                    republishContent: {
                      disable: isActionInProgress(row)
                    },
                    turnOnScreen: {
                      disable: isActionInProgress(row)
                    },
                    turnOffScreen: {
                      disable: isActionInProgress(row)
                    },
                    clearCache: {
                      disable: !isOnline(row) || isActionInProgress(row)
                    }
                  }
                }"
                [channelId]="row.id"
                [screenStatus]="getDeviceScreenStatus(row)"
              ></app-device-commands>
            </ng-template>

            <button
              type="button"
              class="btn btn-sm btn-outline-danger"
              ngbTooltip="{{ 'DELETE_CHANNEL' | translate }}"
              [disabled]="isActionInProgress(row)"
              (click)="
                $event.preventDefault();
                $event.stopPropagation();
                channelActionClick(row, 'DELETE_CHANNEL')
              "
            >
              <i class="ri-delete-bin-line"></i>
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer *ngIf="isUsingCustomFooter">
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="pageOffset"
      >
        <datatable-pager
          *ngIf="!virtualScroll"
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="curPage"
          [size]="pageSize"
          [count]="count"
          [hidden]="!(count / pageSize > 1)"
          (change)="onPageChange($event)"
        ></datatable-pager>
        <div class="ms-2 d-flex justify-content-start">
          <span class="me-1" *ngIf="showCheckbox">
            {{ 'SELECTED' | translate }}: {{ selectedCount }} /
          </span>
          {{ 'TOTAL' | translate }}: {{ count }}
        </div>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<ng-template #popContent>
  <div>{{ 'APP_VERSION_NOT_SYNC' | translate }}</div>
  <div class="flex-row-reverse d-flex">
    <button
      class="btn btn-sm btn-primary"
      (click)="
        $event.preventDefault();
        $event.stopPropagation();
        channelActionClick(null, 'APPLET_UPDATE_VERSION')
      "
    >
      {{ 'UPDATE' | translate }}
    </button>
  </div>
</ng-template>
