<div class="container my-3">
  <ngb-accordion
    [destroyOnHide]="false"
    #channelAccordion="ngbAccordion"
    class="accordion-custom"
  >
    <ngb-panel [id]="accordionActiveId">
      <ng-template ngbPanelTitle>
        <!-- <i
          (click)="resetPosition()"
          class="mx-1 mdi mdi-restore"
          [title]="'RESET_POSITION' | translate"
        ></i> -->
        <h5>
          {{ featureProperties.channelsCount }}
          {{
            (featureProperties.channelsCount > 1 ? 'CHANNELS' : 'CHANNEL')
              | translate
          }}
        </h5>
      </ng-template>
      <ng-template ngbPanelContent>
        <table class="table table-sm align-text-bottom">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let device of devicesInCluster; let i = index">
              <td
                class="status-indicator"
                [style.background-color]="device?.status?.Color"
                ngbTooltip="{{ device?.status?.StatusLabel | translate }}"
              ></td>
              <td class="ms-2">
                {{ device.name }}
              </td>
              <td
                (click)="deviceDetailsToggle(device)"
                class="fs-6 px-2 pointer"
              >
                <i
                  [ngClass]="
                    isSelected(device) !== -1
                      ? 'ri-information-fill border border-white rounded-circle'
                      : 'ri-information-line'
                  "
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <div class="d-flex justify-content-evenly w-100">
    <div
      class="d-flex align-items-center my-3 mx-2"
      [class.col-12]="!featureProperties.offline"
      *ngIf="featureProperties.online"
    >
      <span
        class="status-indicator"
        [style.background-color]="statusColors.online"
      ></span>
      <div class="ms-2">
        {{ featureProperties.online }} {{ 'ONLINE' | translate }}
      </div>
    </div>

    <div
      class="d-flex align-items-center my-3 mx-2"
      [class.col-12]="!featureProperties.online"
      *ngIf="featureProperties.offline"
    >
      <span
        class="status-indicator"
        [style.background-color]="statusColors.offline"
      ></span>
      <div class="ms-2">
        {{ featureProperties.offline }} {{ 'OFFLINE' | translate }}
      </div>
    </div>
  </div>
</div>
