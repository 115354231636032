import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@desquare/factories';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScreenshotComponent } from './screenshot/screenshot.component';
import { DesquareCommonModule } from '@desquare/components/common/src/desquare-common.module';
import { AngularSplitModule } from 'angular-split';

@NgModule({
  declarations: [ScreenshotComponent],
  imports: [
    DesquareCommonModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxWebstorageModule.forRoot({
      prefix: 'designage2-watchtower',
      separator: '-',
      caseSensitive: false,
    }),
    NgbModule,
    AngularSplitModule,
  ],
  exports: [
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    ScreenshotComponent,
    AngularSplitModule,
  ],
  providers: [DatePipe],
})
export class SharedModule {}
