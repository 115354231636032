import { Component, Input } from '@angular/core';
import { ToasterService } from '@desquare/services';

@Component({
  selector: 'copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent {
  @Input() cbcontent!: string;
  @Input() button = false;

  constructor(private toasterService: ToasterService) {}
  copyToaster(value: string) {
    this.toasterService.success(`Copied ${value} to clipboard`);
  }
}
