<app-form-dialog
  headerText="CREATE_FOLDER"
  [valid]="isFormValid"
  [pristine]="isFormPristine"
  [values]="createFolderInput"
  dismissButtonText="CANCEL"
  closeButtonText="SAVE"
>
  <form [formGroup]="folderForm">
    <div class="mb-3">
      <label class="form-label" for="name">{{ 'NAME' | translate }}*</label>
      <input
        type="text"
        class="form-control form-control-dark"
        formControlName="name"
      />
    </div>
    <div
      class="alert alert-danger"
      *ngIf="
        folderForm &&
        !folderForm.controls['name'].pristine &&
        folderForm.controls['name'].hasError('required')
      "
    >
      {{ 'NAME_IS_REQUIRED' | translate }}
    </div>
  </form>
</app-form-dialog>
