<div
  *ngIf="showFilter || columnSelector"
  class="d-flex align-items-center justify-content-between m-2 ms-0"
>
  <div class="flex-grow-1">
    <form
      *ngIf="showFilter"
      class="position-relative search-field"
      [class.w-100]="filterValue !== ''"
    >
      <input
        name="search"
        type="text"
        (keyup)="applyFilter($event)"
        class="form-control form-control-dark search-input"
        placeholder="{{ 'SEARCH' | translate }}"
        [(ngModel)]="filterValue"
      />
      <button
        *ngIf="filterValue"
        (click)="clearFilter()"
        class="btn-close btn-close-white position-absolute filter-close-button"
        aria-label="Close"
      ></button>
    </form>
  </div>

  <div class="d-flex align-items-center">
    <div ngbDropdown class="me-3" *ngIf="showGroupBy()">
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="groupByColumn() === null ? 'btn-outline-light' : 'btn-light'"
        id="dropdownBasic1"
        ngbDropdownToggle
      >
        {{ 'GROUP_BY' | translate }}
        <ng-container *ngIf="groupByColumn() != null">
          <span class="ms-3 badge rounded-pill bg-primary">
            {{ groupByColumn()?.name | translate }}
          </span>
          <span
            class="ms-1 badge bg-secondary"
            (mousedown)="
              groupByColumn.set(null);
              buildGroupedDataSource();
              $event.preventDefault()
            "
            >X</span
          >
        </ng-container>
      </button>
      <div
        class="form-control form-control-dark shadowed-box"
        ngbDropdownMenu
        aria-labelledby="groupBy"
      >
        <ng-container *ngFor="let col of columns">
          <div
            class="my-1 cursor-pointer"
            *ngIf="col.groupable"
            ngbDropdownItem
            (mousedown)="groupByColumn.set(col); buildGroupedDataSource()"
          >
            {{ col.name | translate }}
          </div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="columnSelector" ngbDropdown autoClose="outside">
      <!-- <mat-icon
        ngbDropdownToggle
        role="button"
        class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
        aria-hidden="true"
        data-mat-icon-type="font"
        >more_vert</mat-icon
      > -->
      <button
        class="btn btn-sm btn-outline-light"
        ngbDropdownToggle
        role="button"
      >
        {{ 'COLUMN_SELECTOR' | translate }}
      </button>
      <div
        class="form-control form-control-dark shadowed-box"
        ngbDropdownMenu
        aria-labelledby="ColumnSelector"
      >
        <!-- <h5 class="ms-3 me-1">
          {{ 'COLUMN_SELECTOR' | translate }}
        </h5>
        <hr /> -->
        <ng-container
          *ngFor="let column of columns; let i = index; trackBy: trackByColumn"
        >
          <div *ngIf="column.visible != 'mandatory'" ngbDropdownItem>
            <mat-checkbox
              color="primary"
              [(ngModel)]="column.visible"
              (change)="setVisibleColumns(true)"
            >
            </mat-checkbox>
            <span>
              {{ column.name | translate }}
            </span>
          </div>
        </ng-container>
        <hr />
      </div>
    </div>
  </div>
</div>

<!-- Loading bar -->
<div class="position-relative">
  <div *ngIf="loading" class="position-absolute progress" @showHideLoader>
    <div
      class="progress-bar-striped progress-bar-animated progress-bar-table"
      role="progressbar"
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</div>

<!-- Table -->
<div class="designage-data-table-wrapper">
  <mat-table
    #table
    [id]="tableElementId"
    class="designage-data-table"
    matSort
    (matSortChange)="sortChanged($event)"
    [matSortDisableClear]="matSortDisableClear"
    [dataSource]="dataSource"
    [trackBy]="trackBy"
    cdkDropList
    [cdkDropListData]="dataSource.data"
    (cdkDropListDropped)="dropTable($event)"
    [cdkDropListConnectedTo]="connectedTableList"
  >
    <!-- Select All Checkbox Column -->
    <ng-container *ngIf="showMultiSelect" matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          color="primary"
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
        <mat-checkbox
          color="primary"
          (change)="$event ? toggleSelection(row) : null"
          [checked]="isRowSelected(row)"
          [aria-label]="checkboxLabel(row.name)"
        >
        </mat-checkbox>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
    </ng-container>

    <!-- Delete Row Column -->
    <ng-container *ngIf="showDeleteRow" matColumnDef="deleteRow">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div
          *ngIf="!readOnly"
          class="cursor-pointer fs-5"
          aria-label="{{ 'DELETE_ROW' | translate }}"
          ngbTooltip="{{ 'DELETE_ROW' | translate }}"
          (click)="deleteRow(row)"
        >
          <i class="ri-close-circle-line"></i>
        </div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
    </ng-container>

    <!-- 
      columns generated by [columns] input
    -->

    <ng-container
      *ngFor="let column of columns; let i = index; trackBy: trackByColumn"
      [matColumnDef]="column.fieldName"
      ><!-- TODO: matColumnDef= column.fieldName + _ i ? -->
      <mat-header-cell
        *matHeaderCellDef
        [mat-sort-header]="column.disableSorting ? null : column.disableSorting"
        class="fs-6"
        [style]="column.style ? column.style : ''"
        [style.flex]="column.flex ? column.flex : '1'"
      >
        {{ column.name | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let rowElement"
        [style]="column.style ? column.style : ''"
        [style.flex]="column.flex ? column.flex : '1'"
      >
        <!-- Check if data is boolean. If Boolean create checkboxes. -->
        <mat-checkbox
          *ngIf="column.type === 'boolean'"
          (click)="$event.stopPropagation()"
          (change)="emitCheckboxChange(rowElement, $event)"
          [(ngModel)]="rowElement[column.fieldName]"
          [aria-label]="checkboxLabel(rowElement[column.fieldName])"
          [disabled]="readOnly"
          color="secondary"
        >
        </mat-checkbox>

        <!-- generic status indicator -->
        <ng-container *ngIf="column.type === 'status-indicator'">
          <table-status-indicator
            [status]="rowElement[column.fieldName]"
          ></table-status-indicator>
        </ng-container>

        <!-- device status indicator (get status from service, field contains deviceId) -->
        <ng-container *ngIf="column.type === 'device-status-indicator'">
          <table-status-indicator
            [status]="getDeviceStatusSignal(rowElement[column.fieldName])()"
          ></table-status-indicator>
        </ng-container>

        <!-- Check if data is string -->
        <ng-container *ngIf="column.type === 'string'">
          <ng-container [ngSwitch]="column.decorator">
            <!-- OS Icon string -->
            <span
              *ngSwitchCase="'osType'"
              class="d-flex align-items-center text-truncate"
              [innerHTML]="
                getFieldValue(rowElement, column.fieldName) | tableOsType
              "
            ></span>

            <!-- Generic string -->
            <span
              *ngSwitchDefault
              class="text-truncate"
              [ngbTooltip]="getFieldValue(rowElement, column.fieldName)"
            >
              {{ getFieldValue(rowElement, column.fieldName) }}
            </span>
          </ng-container>
        </ng-container>

        <!-- Component -->
        <ng-container *ngIf="column.type === 'template'">
          <ng-container
            [ngTemplateOutlet]="getCustomTemplate(column.templateRef)"
            [ngTemplateOutletContext]="{
              data: rowElement
            }"
          >
          </ng-container>
        </ng-container>

        <ng-container
          *ngIf="
            column.type === 'date' &&
            getFieldValue(rowElement, column.fieldName)
          "
        >
          <ng-container [ngSwitch]="column.decorator">
            <!-- UTC Time string -->
            <span
              *ngSwitchCase="'utcTime'"
              class="text-truncate"
              [ngbTooltip]="
                getFieldValue(rowElement, column.fieldName) | date : 'full'
              "
              [innerHTML]="
                getFieldValue(rowElement, column.fieldName) | date : 'medium'
              "
            ></span>

            <!-- time elapsed -->
            <span
              *ngSwitchCase="'timeAgo'"
              class="text-truncate"
              [innerHTML]="
                getFieldValue(rowElement, column.fieldName) | amTimeAgo
              "
            ></span>

            <span
              *ngSwitchDefault
              class="text-truncate"
              [ngbTooltip]="getFieldValue(rowElement, column.fieldName)"
            >
              {{ getFieldValue(rowElement, column.fieldName) }}
            </span>
          </ng-container>
        </ng-container>

        <!-- Check if data is object -->
        <ng-container *ngIf="column.type === 'object'">
          {{ getFieldValue(rowElement, column.fieldName) }}
        </ng-container>

        <!-- Check if data is Device Array -->
        <table-device-array
          *ngIf="column.type === 'device-array'"
          [devicesArray]="getFieldValue(rowElement, column.fieldName)"
        ></table-device-array>
        <table-generic-array
          *ngIf="column.type === 'generic-array'"
          [inputArray]="getFieldValue(rowElement, column.fieldName)"
          [params]="column"
        ></table-generic-array>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="visibleColumns; sticky: true"
    ></mat-header-row>

    <mat-row
      *matRowDef="let row; columns: visibleColumns"
      cdkDrag
      [cdkDragData]="row"
      [cdkDragDisabled]="dragDisabledRows(row)"
      (cdkDragStarted)="dragStart($event)"
      (click)="onRowClick(row, $event)"
      [class.row-selected]="
        selection.isSelected(row.id) && !rowActiveInSlidingPanel
      "
      [class.row-in-sliding-panel]="rowActiveInSlidingPanel === row.id"
    ></mat-row>

    <mat-footer-row
      [hidden]="!showFooter"
      *matFooterRowDef="visibleColumns; sticky: true"
    >
    </mat-footer-row>

    <!-- Group header cells-->
    <ng-container matColumnDef="groupReducer">
      <mat-cell
        colspan="1"
        *matCellDef="let group"
        style="flex: 0; padding-left: 6.5rem; min-width: 8rem"
      >
        <div>
          <mat-icon *ngIf="!group.reduced">expand_less</mat-icon>
          <mat-icon *ngIf="group.reduced">expand_more</mat-icon>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="groupHeader">
      <mat-cell
        colspan="999"
        *matCellDef="let group"
        style="border-left: unset !important"
      >
        <h4>
          {{ 'GROUPED_BY' | translate }}: {{ group.groupName | titlecase }}
        </h4>
      </mat-cell>
    </ng-container>

    <!-- Group line -->
    <mat-row
      class="clickable group-row"
      *matRowDef="
        let row;
        columns: ['groupReducer', 'groupHeader'];
        when: isGroup
      "
      (click)="reduceGroup(row)"
    ></mat-row>
  </mat-table>
</div>
