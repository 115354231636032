import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  ViewChild,
  computed,
  signal,
} from '@angular/core';
import { IDeviceCommandsComponentOptions } from '@desquare/components/common/src/device-commands/device-commands.component';
import { environment } from '@desquare/environments';
import { DeviceDataSelectorProps, DeviceStatusInfo } from '@desquare/models';
import { DeviceDataService, UiDataService } from '@desquare/services';
import { DeviceData } from '@desquare/types';
import { DateTime, Interval } from 'ts-luxon';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { getNetworkAdaptersUtil } from '@desquare/utils';
import {
  ChannelsForChannelListFragment,
  Maybe,
  Playlist,
  PlaylistStatus,
  PlaylistVersionBonsaiFragment,
} from '@designage/gql';

@Component({
  selector: 'app-device-thumbnail',
  templateUrl: './device-thumbnail.component.html',
  styleUrls: ['./device-thumbnail.component.scss'],
})
export class DeviceThumbnailComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() device!: DeviceData;

  @ViewChild('screenshotEl', { static: false })
  screenshotElement!: ElementRef;

  isNotInstalled = false;
  statusColor!: string;
  useDefaultScreenshot = false;
  timestamp!: Date;
  selectedDevice: DeviceData[] = [];
  screenshotDelay = 3000;
  screenshotPlaying = false;
  screenshotLive!: ReturnType<typeof setInterval>;
  screenshotLiveTimer!: ReturnType<typeof setTimeout>;
  screenshotRetry!: ReturnType<typeof setTimeout>;
  loader = false;
  selectedForDetailsPanel$!: BehaviorSubject<DeviceData[]>;
  selectedDeviceData$!: BehaviorSubject<DeviceDataSelectorProps[]>;
  networkAdapterIP!: string | undefined | null;
  networkAdapterType!: string | undefined | null;
  networkWifiStrength: number | undefined | null;
  componentReady = false;
  connectedLinks = signal<{
    loading: boolean;
    playlists: PlaylistVersionBonsaiFragment[];
    channel: Maybe<ChannelsForChannelListFragment>;
  }>({
    loading: true,
    playlists: [],
    channel: undefined,
  });

  showConnectedLinks = computed(() => this.uiDataService.isCms);

  constructor(
    private deviceDataService: DeviceDataService,
    private uiDataService: UiDataService,
    private cdr: ChangeDetectorRef
  ) {}
  ngAfterViewInit() {
    this.componentReady = true;
    this.cdr.detectChanges();
    // console.log('device thumbnail', this.device);
  }

  get screenshotUri(): string {
    if (!this.useDefaultScreenshot && this.device?.screenshotUrl) {
      const screenshotUri = this.deviceDataService.getDeviceScreenshot(
        this.device.id
      );
      if (!this.isOffline) {
        const screenshotTimestamp = screenshotUri.split('=').at(-1);
        if (screenshotTimestamp) {
          this.timestamp = new Date(+screenshotTimestamp);
        }
      } else {
        this.timestamp =
          this.device.deviceInfo?.currentTime?.currentDate ||
          this.device.updatedAt;
      }
      return screenshotUri;
    } else {
      return environment.assets.missingScreenshotPlaceholder;
    }
  }

  screenshotError() {
    this.useDefaultScreenshot = true;
    setTimeout(async () => {
      console.log('re-trying to get missing screenshot');
      this.useDefaultScreenshot = false;
    }, 30000);
  }

  get latestPing() {
    return this.device?.latestPing?.timestamp || '';
  }

  ngOnInit(): void {
    this.initVariables();
    this.selectedDeviceData$ = this.uiDataService.getSelectedDeviceData();
    this.selectedForDetailsPanel$ =
      this.uiDataService.getSelectedForDetailsPanel();
    setTimeout(() => {
      // console.log('this.device', this.device.name);
      // console.log(
      //   'this.device.time',
      //   DateTime.fromISO(this.device.deviceInfo?.currentTime?.currentDate)
      // );
      // console.log(
      //   'Duration',
      //   Interval.fromDateTimes(
      //     DateTime.fromISO(this.device.deviceInfo?.currentTime?.currentDate),
      //     DateTime.now()
      //   ).length('hours')
      // );
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.screenshotLive);
  }

  initVariables() {
    this.isNotInstalled = !this.device;
    this.getNetworkAdapter();
  }

  get isOffline() {
    return this.status === DeviceStatusInfo.Offline;
  }

  get isSelected(): number {
    let selectedDevices = this.selectedForDetailsPanel$.getValue();
    let selected = selectedDevices.findIndex((d) => d.id === this.device.id);

    return selected;
  }

  get getStatusColor() {
    // const hours = moment().diff(moment(this.timestamp), 'hours');
    const hours = Interval.fromDateTimes(
      DateTime.fromISO(this.device.deviceInfo?.currentTime?.currentDate),
      DateTime.now()
    ).length('hours');
    let classString = 'bg-online';
    if (this.status === DeviceStatusInfo.Offline) {
      hours < 1
        ? (classString = 'bg-offline-warning')
        : (classString = 'bg-offline');
      return classString;
    }
    return classString;
  }

  get status(): DeviceStatusInfo {
    return this.device?.status || DeviceStatusInfo.CtrlOffline;
  }

  get platformLogo(): string | undefined {
    const applicationType = this.device.deviceInfo?.applicationType;
    if (applicationType && applicationType !== 'default') {
      return `assets/images/${applicationType}.png`;
    } else {
      return undefined;
    }
  }

  setTimestamp() {
    if (this.isOffline) {
      this.timestamp = this.device.updatedAt;
    } else {
      const screenshotTimestamp = this.screenshotUri.split('=').at(-1);
      if (screenshotTimestamp) {
        this.timestamp = new Date(+screenshotTimestamp);
      }
    }
  }

  forceRefreshContent() {
    this.deviceDataService.forceRefreshDeviceContent(this.device.id);
  }
  updateScreenshot() {
    this.loader = true;
    this.deviceDataService.askForScreenshot(this.device.id);
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }

  startStopLiveScreenshots() {
    if (!this.screenshotPlaying) {
      this.screenshotPlaying = true;

      console.log('started screenshotting!');
      this.screenshotLive = setInterval(() => {
        this.askScreenshot();
      }, this.screenshotDelay);
      this.screenshotLiveTimer = setTimeout(() => {
        clearInterval(this.screenshotLive);
        this.screenshotPlaying = false;
        console.log('stoped screenshotting!');
      }, 60000);
    } else {
      clearInterval(this.screenshotLive);
      clearTimeout(this.screenshotLiveTimer);
      this.screenshotPlaying = false;
    }
  }

  askScreenshot() {
    this.deviceDataService.askForScreenshot(this.device.id);
  }

  channelDetailsToggle() {
    this.uiDataService.updateSelectedDevices(this.device);
  }

  deviceCommandsOptions(): IDeviceCommandsComponentOptions {
    return {
      controls: {
        reboot: {
          disable: this.isOffline || this.isNotInstalled,
        },
        clearCache: {
          disable: this.isOffline || this.isNotInstalled,
        },
        updateApplet: {
          disable:
            this.device.signageOSDeviceTiming?.appletVersion ===
            this.device.signageOSDeviceTiming?.activeAppletVersion,
        },
      },
    };
  }

  getNetworkAdapter() {
    // Parse for network Interfaces
    if (this.device?.deviceInfo && this.device?.deviceInfo?.networkAdapters) {
      // If SMIL 2.2.7 or later
      const { activeAdapters, activeAdapter } = getNetworkAdaptersUtil(
        this.device.deviceInfo
      );
      this.networkAdapterType = activeAdapter?.type;
      this.networkWifiStrength = activeAdapter?.wifiStrength;
      this.networkAdapterIP = activeAdapter?.localAddress;
    }

    // If SMIL pre 2.2.7
    const networkInfo = this.device.deviceInfo?.networkInfo;
    if (networkInfo) {
      this.networkAdapterType = networkInfo.activeInterface;
      this.networkAdapterIP = networkInfo.localAddress;
    }
  }

  async getConnectedLinks() {
    if (this.device.channelId) {
      const channel = await this.deviceDataService.getConnectedChannel(
        this.device.channelId
      );
      const playlists =
        (channel?.playlists.filter(
          (x) => x.status === PlaylistStatus.Active
        ) as PlaylistVersionBonsaiFragment[]) || [];

      this.connectedLinks.set({
        loading: false,
        channel,
        playlists,
      });
    }
  }

  navigateToChannel() {
    // TODO: create ui link to this.device.channel?.name
    if (this.device.channelId) {
      this.deviceDataService.currentUserService.navigateToChannel(
        this.device.channelId
      );
    }
  }

  navigateToPlaylist(id: string) {
    // TODO: create UI list from this.device.channel?.playlists
    this.deviceDataService.currentUserService.navigateToPlaylist(id);
  }

  getInnerObjects(jsonPath: string): string | undefined {
    return _.get(this.device, jsonPath);
  }
}
