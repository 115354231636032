import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  computed,
  signal,
} from '@angular/core';
import { DeviceStatusCode, DeviceStatusInfo } from '@desquare/models';
import {
  DeviceDataService,
  UiDataService,
  CurrentUserService,
  ToasterService,
} from '@desquare/services';
import moment from 'moment';
import { IScreenshotUrl } from '@desquare/interfaces';
import { DeviceData } from '@desquare/types';
import { environment } from '@desquare/environments';
import * as _ from 'lodash';
import { IDeviceCommandsComponentOptions } from '@desquare/components/common/src/device-commands/device-commands.component';
import {
  ChannelsForChannelListFragment,
  Maybe,
  PlaylistStatus,
  PlaylistVersionBonsaiFragment,
} from '@designage/gql';

@Component({
  selector: 'app-device-detailed',
  templateUrl: './device-detailed.component.html',
  styleUrls: ['./device-detailed.component.scss'],
})
export class DeviceDetailedComponent implements OnInit {
  // TODO: input should be device id, device should come from dataService with a getter
  @Input() deviceId!: string;
  @Input() device!: DeviceData;

  @ViewChild('screenshotEl', { static: true })
  screenshotElement!: ElementRef;

  isSuperAdmin!: boolean;
  isNotInstalled = false;
  latestPing = '';
  humanizedPing = '';
  selectedChannel: DeviceData[] = [];
  screenshotUrl!: IScreenshotUrl;
  timestamp!: Date;
  realTime: Date = new Date(this.device?.deviceInfo?.currentTime?.currentDate);
  realTimeInterval!: ReturnType<typeof setInterval>;
  useDefaultScreenshot = false;
  loader = false;
  connectedLinks = signal<{
    loading: boolean;
    playlists: PlaylistVersionBonsaiFragment[];
    channel: Maybe<ChannelsForChannelListFragment>;
  }>({
    loading: true,
    playlists: [],
    channel: undefined,
  });

  showConnectedLinks = computed(() => this.uiDataService.isCms);

  constructor(
    private deviceDataService: DeviceDataService,
    private uiDataService: UiDataService,
    private currentUserService: CurrentUserService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.initVariables();
    // this.startRealTimeDeviceClock();
  }

  initVariables() {
    this.isSuperAdmin = this.currentUserService.isSuperAdmin;
    this.latestPing = this.device?.latestPing?.timestamp;
    this.timestamp =
      this.device?.deviceInfo?.currentTime?.currentDate ||
      this.device?.updatedAt;
    // this.realTime = this.device?.deviceInfo?.currentTime?.currentDate;
    // this.getNetworkAdapter();
  }
  getStatusEnum(statusEnum: number, lastUpdate?: Date) {
    if (statusEnum === 4 && moment().diff(moment(this.timestamp), 'm') < 30) {
      console.log('moment', moment().diff(moment(this.timestamp), 'm'));
      // console.log('lastUpdate', this.timestamp);
      return DeviceStatusCode[6];
    }
    return DeviceStatusCode[statusEnum];
  }

  get getStatusColor() {
    // this.timestamp =
    //   this.device?.deviceInfo?.currentTime?.currentDate || this.device?.updatedAt;
    const hours = moment().diff(moment(this.timestamp), 'hours');
    let classString = 'bg-online';
    if (this.status === DeviceStatusInfo.Offline) {
      hours < 1
        ? (classString = 'bg-offline-warning')
        : (classString = 'bg-offline');
      return classString;
    }
    return classString;
  }

  get status(): DeviceStatusInfo {
    return this.device?.status || DeviceStatusInfo.CtrlOffline;
  }

  get isOffline() {
    return this.status === DeviceStatusInfo.Offline;
  }

  get screenshotUri(): string {
    if (!this.useDefaultScreenshot && this.device?.screenshotUrl) {
      const screenshotUri = this.deviceDataService.getDeviceScreenshot(
        this.device?.id
      );
      if (!this.isOffline) {
        const screenshotTimestamp = screenshotUri.split('=').at(-1);
        if (screenshotTimestamp) {
          this.timestamp = new Date(+screenshotTimestamp);
        }
      } else {
        this.timestamp =
          this.device?.deviceInfo?.currentTime?.currentDate ||
          this.device?.updatedAt;
      }
      return screenshotUri;
    } else {
      return environment.assets.missingScreenshotPlaceholder;
    }
  }

  retryScreenshot() {
    setTimeout(() => {
      // console.log('re-trying to get screenshot');
      this.screenshotUri;
    }, 10000);
  }

  screenshotError() {
    this.useDefaultScreenshot = true;
  }

  get platformLogo(): string | undefined {
    const applicationType = this.device?.deviceInfo?.applicationType;
    if (applicationType && applicationType !== 'default') {
      return `assets/images/${applicationType}.png`;
    } else {
      return undefined;
    }
  }

  removeCard() {
    this.uiDataService.updateSelectedDevices(this.device);
  }

  forceRefreshContent() {
    if (confirm('Clear Device Cache will reboot the device. Are you sure?')) {
      this.deviceDataService.forceRefreshDeviceContent(this.device?.id);
    }
  }

  startRealTimeDeviceClock() {
    clearInterval(this.realTimeInterval);
    this.realTimeInterval = setInterval(() => {
      this.realTime = new Date(this.realTime.getTime() + 1000);
    }, 1000);
  }

  stopRealTimeDeviceClock() {
    clearInterval(this.realTimeInterval);
  }

  updateScreenshot() {
    this.loader = true;
    this.deviceDataService.askForScreenshot(this.device?.id);
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }

  copyToaster(value: string) {
    this.toasterService.success(`Copied ${value} to clipboard`);
  }

  deviceCommandsOptions(): IDeviceCommandsComponentOptions {
    return {
      controls: {
        reboot: {
          disable: this.isOffline || this.isNotInstalled,
        },
        clearCache: {
          disable: this.isOffline || this.isNotInstalled,
        },
        updateApplet: {
          disable:
            this.device?.signageOSDeviceTiming?.appletVersion ===
            this.device?.signageOSDeviceTiming?.activeAppletVersion,
        },
      },
    };
  }

  async getConnectedLinks() {
    if (this.device.channelId) {
      const channel = await this.deviceDataService.getConnectedChannel(
        this.device.channelId
      );
      const playlists =
        (channel?.playlists.filter(
          (x) => x.status === PlaylistStatus.Active
        ) as PlaylistVersionBonsaiFragment[]) || [];

      this.connectedLinks.set({
        loading: false,
        channel,
        playlists,
      });
    }
  }
  navigateToChannel() {
    // TODO: create ui link to this.device.channel?.name
    if (this.device.channelId) {
      this.deviceDataService.currentUserService.navigateToChannel(
        this.device.channelId
      );
    }
  }

  navigateToPlaylist(id: string) {
    // TODO: create UI list from this.device.channel?.playlists
    this.deviceDataService.currentUserService.navigateToPlaylist(id);
  }
}
