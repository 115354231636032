import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileListComponent } from './profile-list/profile-list.component';
import { SharedModule } from '../shared/shared.module';
import { ProfileSelectionDialogComponent } from './profile-selection-dialog/profile-selection-dialog.component';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DesquareCommonModule } from '@desquare/components/common/src/desquare-common.module';

@NgModule({
  declarations: [ProfileListComponent, ProfileSelectionDialogComponent],
  imports: [
    DesquareCommonModule,
    CommonModule,
    ProfileRoutingModule,
    SharedModule,
  ],
  exports: [ProfileSelectionDialogComponent],
  providers: [NgbDropdown],
})
export class ProfileModule {}
