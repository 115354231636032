import { Injectable } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveUiService {
  constructor(private breakpoint: BreakpointObserver) {}

  isMobileDevice(): boolean {
    return this.breakpoint.isMatched(Breakpoints.XSmall);
  }
  isTabletScreen(): boolean {
    return (
      this.breakpoint.isMatched(Breakpoints.Tablet) ||
      this.breakpoint.isMatched(Breakpoints.WebPortrait)
    );
  }
  isBrowserRes(): boolean {
    return this.breakpoint.isMatched(Breakpoints.WebLandscape);
  }

  isHandheldScreenSizes$(): Observable<BreakpointState> {
    return this.breakpoint.observe([Breakpoints.Handset, Breakpoints.Tablet]);
  }
  sm(): boolean {
    return this.breakpoint.isMatched('(min-width: 600px)');
  }
  md(): boolean {
    return this.breakpoint.isMatched('(min-width: 768px)');
  }
  lg(): boolean {
    return this.breakpoint.isMatched('(min-width: 960px)');
  }
  xl(): boolean {
    return this.breakpoint.isMatched('(min-width: 1280px)');
  }
  xxl(): boolean {
    return this.breakpoint.isMatched('(min-width: 1920px)');
  }
}
