<!-- <app-loader [message]="loaderMessage" *ngIf="loading"></app-loader> -->

<h5 class="mb-0 ms-4" *ngIf="!devices?.length && !loading">
  {{ 'NO_DEVICE_AVAILABLE' | translate }}
</h5>

<div>
  <div
    [ngClass]="responsiveUiService.isMobileDevice() ? 'full-width' : zoom"
    class="devices-wrapper"
  >
    <ng-container *ngIf="loading; else devicesGrid">
      <div class="card" *ngFor="let skeleton of skeletonCards">
        <ngx-skeleton-loader
          animation="progress-dark"
          appearance="circle"
          [theme]="{
            'width': 'calc(100% - 0.6rem)',
            'height': '6rem',
            
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          class="mt-4"
          animation="progress-dark"
          [theme]="{
            'width': 'calc(100% - 0.6rem)',
            'margin': '0.25rem 0.3rem',
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          animation="progress-dark"
          [theme]="{
            'width': 'calc(80% - 0.6rem)',
            'margin': '0.25rem 0.3rem',
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          animation="progress-dark"
          [theme]="{
            'width': 'calc(85% - 0.6rem)',
            'margin': '0.25rem 0.3rem',
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          animation="progress-dark"
          [theme]="{
            'width': 'calc(70% - 0.6rem)',
            'margin': '0.25rem 0.3rem',
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          animation="progress-dark"
          [theme]="{
            'width': 'calc(90% - 0.6rem)',
            'margin': '0.25rem 0.3rem',
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          animation="progress-dark"
          [theme]="{
            'width': 'calc(80% - 0.6rem)',
            'margin': '0.25rem 0.3rem',
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </ng-container>
    <ng-template #devicesGrid>
      <app-device-thumbnail
        *ngFor="let device of devices; let i = index; trackBy: trackByDeviceId"
        id="thumbHost_{{ device.id }}"
        [device]="device"
      ></app-device-thumbnail>
    </ng-template>
  </div>
</div>
