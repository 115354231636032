import { HttpHeaders, HttpParams } from '@angular/common/http';

export class Options {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe = 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };

  reportProgress?: boolean;
  responseType = 'json';
  withCredentials?: boolean;

  constructor(init?: Partial<Options>) {
    Object.assign(this, init);
  }
}
