<!-- minDate: {{ minDateTime }}, minTime: {{ minTimeSgn() }} -->
<div class="d-flex">
  <designage-datepicker
    [disabled]="!enableDate"
    [(date)]="date"
    [maxDate]="maxDate"
    [minDate]="minDate"
    (dateChange)="onDateChange()"
  >
  </designage-datepicker>
  <designage-timepicker
    class="ms-2"
    *ngIf="enableTime"
    [minTime]="minTimeSgn()"
    [(time)]="time"
    [showSecond]="showSecond"
    (click)="$event.stopPropagation()"
    (timeChange)="onTimeChange()"
  ></designage-timepicker>
</div>
