import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

// Reference: https://ng-bootstrap.github.io/#/components/datepicker/api
@Component({
  selector: 'designage-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent {
  @Input() disabled!: boolean;
  @Input()
  set date(value: string) {
    this._date = value ? value : 'YYYY-MM-DD';
    this.startDate = value ? this.getDate(value) : this.getDate('0000-00-00');
  }
  get date() {
    return this._date;
  }
  @Input()
  set minDate(value: string) {
    this._minDate = value ? this.getDate(value) : this.getDate('0000-00-00');
  }
  @Input()
  set maxDate(value: string) {
    this._maxDate = value ? this.getDate(value) : this.getDate('0000-00-00');
  }
  @Output() dateChange = new EventEmitter<string>();

  _date!: string;
  _minDate!: NgbDateStruct;
  _maxDate!: NgbDateStruct;
  startDate!: NgbDateStruct;

  constructor() {}

  onDateSelection(value: NgbDate) {
    this.date = this.getDateString(value);
    this.dateChange.emit(this.date);
  }

  getDate(value: string): NgbDateStruct {
    // YYYY-MM-DD
    const dateElements = value.split('-');
    return {
      year: parseInt(dateElements[0], 10),
      month: parseInt(dateElements[1], 10),
      day: parseInt(dateElements[2], 10),
    };
  }

  getDateString(value: NgbDate): string {
    return `${value.year}-${String(value.month).padStart(2, '0')}-${String(
      value.day
    ).padStart(2, '0')}`;
  }
}
