import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogoutComponent } from './logout/logout.component';
import { MapComponent } from '@desquare/components/wt-component/src/map/map.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from '@desquare/services';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'auth0-callback',
    loadChildren: () =>
      import('./auth0-callback/auth0-callback.module').then(
        (m) => m.Auth0CallbackModule
      ),
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canLoad: [AuthGuard],
  },
  {
    path: 'error/third-party-cookie',
    component: LogoutComponent,
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
})
export class AppRoutingModule {}
