import { ICoordinates } from './ICoordinates';
import { MapEventEntityTypes } from '../enums/map-event-entity-types.enum';
import { IBaseEventArgs } from './IBaseEventArgs';

export interface IMapDragEndArgs extends IBaseEventArgs {
  entityId: string;
  entityType: MapEventEntityTypes;
  coords: ICoordinates;
}

export interface IMapDragArgs extends IBaseEventArgs {
  entityId: string;
  entityType: MapEventEntityTypes;
  coords: ICoordinates;
}

export interface IMapReadyArgs extends IBaseEventArgs {
  entityId: string;
  isReady: boolean;
}

export interface IMapDragEndMessageEvent extends MessageEvent {
  data: IMapDragEndArgs;
}

export interface IMapDragMessageEvent extends MessageEvent {
  data: IMapDragArgs;
}

export interface IMapReadyMessageEvent extends MessageEvent {
  data: IMapReadyArgs;
}
