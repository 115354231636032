<div class="modal-container">
  <div class="modal-header">
    <h3>{{ headerText | translate }}</h3>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="dismissOverride ? dismissOverride() : modal.dismiss()"
    ></button>
  </div>

  <ng-content #bodyWrapper></ng-content>
</div>
