<div class="input-group">
  <input
    ngbDatepicker
    class="form-control form-control-dark date-display"
    type="button"
    #d="ngbDatepicker"
    name="dp"
    placeholder="YYYY-MM-DD"
    [disabled]="disabled"
    [value]="date"
    [minDate]="_minDate"
    [maxDate]="_maxDate"
    [startDate]="startDate"
    placement="bottom"
    (click)="d.toggle()"
    (dateSelect)="onDateSelection($event)"
  />
</div>
