import { Component, Input, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/confirm-dialog/confirm-dialog.component';
import {
  CurrentUserService,
  PlaylistViewService,
  PlaylistEditorService,
} from '@desquare/services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'designage-playlist-manage-dialog',
  templateUrl: './playlist-manage-dialog.component.html',
  styleUrls: ['./playlist-manage-dialog.component.scss'],
})
export class PlaylistManageDialogComponent implements OnInit {
  @Input() playlistId!: string;

  dismissFunction = () =>
    this.playlistViewService.hasUnsavedPlaylistChanges
      ? this.openUnsavedConfirmationDialog()
      : this.modal.dismiss();

  constructor(
    private modalService: NgbModal,
    private playlistViewService: PlaylistViewService,
    private playlistEditorService: PlaylistEditorService,
    private currentUserService: CurrentUserService,
    private modal: NgbActiveModal
  ) {}

  ngOnInit(): void {}

  async openUnsavedConfirmationDialog() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'sm',
    });

    modalRef.componentInstance.headerText = 'Leave the page?';
    modalRef.componentInstance.bodyText =
      'There are unsaved changes, are you sure?';

    return await modalRef.result.then(
      (value) => {
        this.playlistViewService.hasUnsavedPlaylistChanges = false;

        // this prevents the unsaved changes info banner to show (backup system of playlist)
        // it is alright to remove the backup since the user at this point on purposely left
        // the playlist manage page
        this.removePendingPlaylist();

        this.modal.dismiss();
      },
      () => {
        // nothing happens
      }
    );
  }

  removePendingPlaylist() {
    this.playlistEditorService.deletePendingPlaylist({
      profileId: this.currentUserService.currentProfile?.id,
      playlistId: this.playlistId,
    });
  }
}
